import React from 'react';
import { toLower } from 'lodash';
import { Button, Table } from '@sumup/circuit-ui';
import { IconEye } from 'assets/icons';
import {
  getCurrentDate,
  formatDateTimeToShow
} from 'services/DateTimeFormatService';

export const getConfigurationFiles = reader =>
  fetch(`/assets_management_api/devices/${reader}/configurations`);

export const getDownloadFile = (id, version, reader, country, setError) =>
  fetch(`/assets_management_api/configurations/${id}`, {})
    .then(response => (response.ok ? response : false))
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      const { dd, mm, yyyy } = getCurrentDate();
      const readerName = toLower(reader.replaceAll(' ', '-'));
      const fileName = `${readerName}-${country}-${dd}-${mm}-${yyyy}-configuration-${version}`;
      const fileUrl = window.URL.createObjectURL(blob);
      link.href = fileUrl;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(fileUrl);
    })
    .catch(err => setError(err));

export const publishConfigurationFile = id => {
  const options = {
    method: 'PUT'
  };
  return fetch(`/assets_management_api/configurations/${id}/publish`, options);
};

export const deleteFile = id => {
  const options = {
    method: 'DELETE'
  };

  return fetch(`/assets_management_api/configurations/${id}`, options);
};

const getFlagEmoji = countryCode => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

const renderPublishedButton = (id, isPublished, publishConfigurationModal) => {
  if (isPublished === undefined || isPublished === null) {
    return '';
  }
  if (isPublished) {
    return (
      <IconEye
        style={{
          alignSelf: 'center',
          maxHeight: '48px',
          opacity: '1',
          color: 'green'
        }}
      />
    );
  }
  return (
    <IconEye
      style={{
        alignSelf: 'center',
        maxHeight: '48px',
        opacity: '.1'
      }}
      onClick={() => publishConfigurationModal(id)}
    />
  );
};

export const mapConfigurations = (
  row,
  reader,
  translate,
  showConfirmationModal,
  publishConfigurationModal,
  downloadError
) => [
  {
    children: renderPublishedButton(
      row.id,
      row.published,
      publishConfigurationModal
    )
  },
  { children: `${getFlagEmoji(row.country)} ${row.country}` },
  { children: translate(`cardReadersConfiguration.${row.tx_type}`) },
  { children: row.version },
  { children: formatDateTimeToShow(row.inserted_at) },
  {
    children: (
      <Button
        onClick={() =>
          getDownloadFile(
            row.id,
            row.version,
            reader,
            row.country,
            downloadError
          )
        }
      >
        Download
      </Button>
    )
  },
  {
    children: (
      <Button onClick={() => showConfirmationModal(row.id)}>Delete</Button>
    )
  }
];

export const mapCountriesAvailable = data => {
  const countryCodes = [];
  return data
    .filter(
      item =>
        !countryCodes.includes(item.country) &&
        countryCodes.push(item.country) &&
        item
    )
    .map(item => ({
      label: item.country,
      value: item.country
    }))
    .sort((prevItem, nextItem) => prevItem.label.localeCompare(nextItem.label));
};

export const shouldRenderTable = (
  data,
  callback,
  translate,
  reader,
  downloadError,
  showConfirmationModal,
  publishConfigurationModal
) => {
  return data ? (
    <Table
      noShadow
      headers={[
        {
          children: translate(
            'cardReadersConfiguration.configsTableHeaders.published'
          )
        },
        {
          children: translate(
            'cardReadersConfiguration.configsTableHeaders.country'
          )
        },
        {
          children: translate(
            'cardReadersConfiguration.configsTableHeaders.interface'
          )
        },
        {
          children: translate(
            'cardReadersConfiguration.configsTableHeaders.version'
          )
        },
        {
          children: translate(
            'cardReadersConfiguration.configsTableHeaders.created_at'
          )
        },
        {
          children: translate(
            'cardReadersConfiguration.configsTableHeaders.download'
          )
        },
        {
          children: translate(
            'cardReadersConfiguration.configsTableHeaders.delete'
          )
        }
      ]}
      rows={data.map(row =>
        mapConfigurations(
          row,
          reader,
          translate,
          showConfirmationModal,
          publishConfigurationModal,
          downloadError
        )
      )}
    />
  ) : (
    callback()
  );
};

export const filterByCountry = (countryCode, entries) =>
  entries.filter(item => item.country === countryCode);

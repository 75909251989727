import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { navigate } from '@reach/router';
import { ROUTES_PATH } from 'constants/routes';
import { Card, Body } from '@sumup/circuit-ui';
import BlackButton from 'components/BlackButton';
import { UploadCarrierSuggestionRules } from 'assets/icons';

const StyledCard = styled(Card)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 720px;
    max-width: 100%;
    margin: 0 ${theme.spacings.peta} ${theme.spacings.peta} 0;
    padding: ${theme.spacings.zetta} 0 ${theme.spacings.tera} 0;
  `};
`;

const CardContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 490px;
    text-align: center;
    margin-top: ${theme.spacings.mega};
  `};
`;

const CardButton = styled(BlackButton)`
  ${({ theme }) => css`
    width: 248px;
    max-width: 100%;
    padding: ${theme.spacings.kilo} ${theme.spacings.giga};
    margin-top: ${theme.spacings.mega};
  `};
`;

const SuccessfulRequestCard = ({ t: translate, ...props }) => (
  <StyledCard {...props}>
    <UploadCarrierSuggestionRules />

    <CardContent>
      <Body noMargin variant="highlight" size="one">
        {translate(`checkInputtedDataPage.requestSuccessful.title`)}
      </Body>

      <Body noMargin size="one">
        {translate(`checkInputtedDataPage.requestSuccessful.text`)}
      </Body>

      <CardButton
        variant="primary"
        size="mega"
        data-testid="successful-request-card-button"
        onClick={() =>
          navigate(ROUTES_PATH.CARRIER_SUGGESTION, {
            state: {
              currentPageKey: 'carrier-suggestion'
            }
          })
        }
      >
        {translate('checkInputtedDataPage.requestSuccessful.button')}
      </CardButton>
    </CardContent>
  </StyledCard>
);

SuccessfulRequestCard.propTypes = {
  t: PropTypes.func.isRequired
};

export default SuccessfulRequestCard;

import { toLower } from 'lodash';
import { ERROR_CODES } from 'constants/applicationTypesErrorCodes';
import soracomCountries from './soracomCountries';
import brazillianStates from './brazillianStates';

export const getCountryOrStates = shouldGetStates =>
  shouldGetStates
    ? {
        id: 'regionName',
        options: brazillianStates,
        placeholder: 'state.placeholder'
      }
    : {
        id: 'country',
        options: soracomCountries,
        placeholder: 'country.placeholder'
      };

export const validateField = (e, field, setValue) =>
  field.hasError === false && e.target.value === ''
    ? setValue({ value: '', hasError: true })
    : setValue({ value: e.target.value, hasError: false });

export const getErrorCodes = applicationType => {
  const applicationName = toLower(applicationType);
  const applicationTypesWithErrorCodes = Object.keys(ERROR_CODES).map(toLower);
  const hasErrorCodes = applicationTypesWithErrorCodes.includes(
    applicationName
  );

  return !hasErrorCodes ? null : ERROR_CODES[applicationName];
};

import React, { useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import BlackButton from 'components/BlackButton';

const HiddenInput = styled.input`
  display: none;
`;

const ValidationHint = styled.span`
  margin-top: 4px;
  display: block;
  line-height: 20px;
  font-size: 14px;
  color: #de331d;
`;

const UploadFileButton = ({
  action,
  inputId,
  inputAccept,
  buttonTestId,
  buttonLabel,
  buttonWidth,
  isLoading,
  isPrimary,
  multipleFiles,
  icon,
  validationHint,
  t: translate,
  ...props
}) => {
  const fileInput = useRef(null);

  const handleButtonClick = () => fileInput.current.click();

  const handleInputChanged = event => {
    if (event.target.files.length > 0) {
      action(event.target.files);
    }
    fileInput.current.value = null;
  };

  return (
    <Fragment>
      <HiddenInput
        type="file"
        id={inputId}
        multiple={multipleFiles ? 'multiple' : false}
        accept={inputAccept}
        onChange={handleInputChanged}
        data-testid={inputId}
        ref={fileInput}
      />

      <BlackButton
        {...props}
        buttonWidth={buttonWidth}
        data-testid={buttonTestId}
        isLoading={isLoading}
        loadingLabel="Loading..."
        isPrimary={isPrimary}
        onClick={handleButtonClick}
      >
        {icon && icon} {translate(`${buttonLabel}`)}
      </BlackButton>

      {validationHint && <ValidationHint>{validationHint}</ValidationHint>}
    </Fragment>
  );
};

UploadFileButton.propTypes = {
  action: PropTypes.func.isRequired,
  inputId: PropTypes.string,
  inputAccept: PropTypes.string,
  buttonTestId: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonWidth: PropTypes.string,
  isLoading: PropTypes.bool,
  isPrimary: PropTypes.bool,
  multipleFiles: PropTypes.bool,
  icon: PropTypes.node,
  validationHint: PropTypes.string,
  t: PropTypes.func
};

UploadFileButton.defaultProps = {
  inputId: 'upload-file-input',
  inputAccept: '*',
  buttonTestId: 'upload-file-button',
  buttonLabel: 'helpers.uploadFileButton',
  buttonWidth: 'auto',
  isLoading: false,
  isPrimary: true,
  multipleFiles: false,
  icon: false,
  validationHint: ''
};

export default UploadFileButton;

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toLower } from 'lodash/fp';
import SearchResult from 'contexts/SearchResult';
import ResponseModal from '../ResponseModal/ResponseModal';
import { requestLineStateChange } from '../../LineStateService';

const StateChangeRequestModal = ({
  iccid,
  lineState,
  userEmail,
  onCancel,
  afterRequest,
  translate,
  ...props
}) => {
  const {
    lastReactivationRequest,
    lastBlockRequest,
    lastUnblockRequest
  } = useContext(SearchResult);
  const [loading, setLoading] = useState(false);

  const getLastRequest = () => {
    switch (toLower(lineState.state)) {
      case 'cancelled':
      case 'suspended':
        return (
          lastReactivationRequest && {
            email: lastReactivationRequest.email,
            datetime: lastReactivationRequest.datetime
          }
        );
      case 'active':
        return (
          lastBlockRequest && {
            email: lastBlockRequest.email,
            datetime: lastBlockRequest.datetime
          }
        );
      case 'blocked':
        return (
          lastUnblockRequest && {
            email: lastUnblockRequest.email,
            datetime: lastUnblockRequest.datetime
          }
        );
      default:
        return undefined;
    }
  };

  const hasLastRequest = Boolean(getLastRequest());

  const [requestStatus, setRequestStatus] = useState(
    hasLastRequest ? 'alreadyRequested' : 'confirmRequest'
  );
  const [requestData, setRequestData] = useState(getLastRequest() || {});

  const errorStatusesMap = {
    400: 'alreadyRequested',
    422: 'supplierError',
    500: 'failedRequest'
  };

  const requestType = {
    cancelled: 'Reactivation',
    suspended: 'Reactivation',
    active: 'Block',
    blocked: 'Unblock'
  };

  const request = () => {
    setLoading(true);

    requestLineStateChange(
      iccid,
      requestType[toLower(lineState.state)],
      userEmail
    )
      .then(response => {
        if (response.ok) {
          // eslint-disable-next-line camelcase
          const { email, inserted_at } = response.json();
          setRequestData({
            email,
            datetime: inserted_at
          });
          setRequestStatus('successfulRequest');
        } else {
          setRequestStatus(
            errorStatusesMap[response.status] || errorStatusesMap[500]
          );
        }
      })
      .catch(() => setRequestStatus('failedRequest'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setRequestStatus(hasLastRequest ? 'alreadyRequested' : 'confirmRequest');
    setRequestData(getLastRequest() || {});
  }, [iccid]);

  const requestOrCancel = () =>
    requestStatus === 'successfulRequest' ? onCancel() : request();

  return (
    <ResponseModal
      {...props}
      status={requestStatus}
      requestData={requestData}
      buttonLoading={loading}
      action={() => (hasLastRequest ? onCancel() : requestOrCancel())}
      requestType={requestType[toLower(lineState.state)]}
      close={() => onCancel()}
      translate={translate}
    />
  );
};

StateChangeRequestModal.propTypes = {
  iccid: PropTypes.string.isRequired,
  lineState: PropTypes.object.isRequired,
  userEmail: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  afterRequest: PropTypes.func,
  translate: PropTypes.func.isRequired
};

export default StateChangeRequestModal;

import React from 'react';
import StatusComponent from 'components/StatusComponent';
import { getDate, getTime } from 'services/DateTimeFormatService';

export const formatRows = rows =>
  rows.map(cells => [
    getDate(cells.timestamp),
    getTime(cells.timestamp),
    cells.carrier,
    {
      children: (
        <StatusComponent
          label={cells.status}
          colorName={cells.status === 'online' ? 'success' : 'neutral'}
        />
      )
    }
  ]);

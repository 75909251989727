import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useSpring, animated } from 'react-spring';

const ANIMATION_VALUES = {
  opacityStart: 1,
  opacityEnd: 0.2,
  transitionDuration: 350
};

const CollapsibleContainer = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.n200};
    width: 100%;
  `};
`;

const Trigger = styled.div`
  cursor: pointer;
`;

const CollapsibleContent = ({
  children,
  triggerToOpen,
  triggerToClose,
  expandUp,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => setIsOpen(!isOpen);

  const contentProps = useSpring({
    opacity: isOpen
      ? ANIMATION_VALUES.opacityStart
      : ANIMATION_VALUES.opacityEnd,
    transition: ANIMATION_VALUES.transitionDuration
  });

  const animatedContent = (
    <animated.div style={contentProps}>{children}</animated.div>
  );

  return (
    <CollapsibleContainer {...props}>
      {isOpen && expandUp && animatedContent}

      <Trigger data-testid="trigger" onClick={() => toggleContent()}>
        {isOpen ? triggerToClose : triggerToOpen}
      </Trigger>

      {isOpen && !expandUp && animatedContent}
    </CollapsibleContainer>
  );
};

CollapsibleContent.propTypes = {
  children: PropTypes.node.isRequired,
  triggerToOpen: PropTypes.node.isRequired,
  triggerToClose: PropTypes.node,
  expandUp: PropTypes.bool
};

CollapsibleContent.defaultProps = {
  expandUp: false
};

export default CollapsibleContent;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Card, Pagination } from '@sumup/circuit-ui';
import { DownloadIcon, Error as ErrorIcon, OpenNewWindow } from 'assets/icons';
import Loading from 'components/Loading';
import DefaultSection from 'components/DefaultSection';
import ErrorMessage from 'components/ErrorMessage';
import { formatDateTimeToShow } from 'services/DateTimeFormatService';
import { calculatePaginationItens } from '../../../../services/PaginationService';

const ItemCard = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  border-width: 1px;
  border-radius: 2px;
  padding: 24px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const SubtleText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.n700};
  `};
`;

const JenkinsLink = styled.a`
  font-size: 0.75em;
  color: blue;
`;

const Link = styled.a`
  color: #1a1a1a;
  display: inline-flex;

  & svg {
    align-self: center;
    margin-left: 0.25em;
  }

  &:hover,
  &:active {
    color: #000;
  }
`;

const StyledPagination = styled(Pagination)`
  justify-content: end;

  & button,
  & button:hover {
    color: black;
    background-color: transparent;
    border-color: transparent;
  }
  & button:focus {
    box-shadow: 0 0 0 4px #ddd;
  }
  & select:focus {
    box-shadow: 0 0 0 2px #ddd;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
`;

const OpenNewWindowIcon = styled(OpenNewWindow)`
  width: 0.75em;
  height: 0.75em;
  margin-left: 0.1em;

  & path {
    fill: blue;
  }
`;

const ErrorSectionContainer = styled.div`
  width: 100%;
  margin: auto;

  & > div {
    max-width: 100%;
  }
  & div > svg {
    width: 66px;
    height: 68px;
  }
`;

const FirmwareResources = ({ t: translate, reader, version }) => {
  const [loadingResources, setLoadingResources] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [paginationContent, setPaginationContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadError, setDownloadError] = useState(false);

  const getResourcesList = () => {
    setLoadingResources(true);

    fetch(
      `/assets_management_api/devices/${reader}/specs/firmware/releases/${version}/resources`
    )
      .then(response => (response.ok ? response.json() : setLoadingError(true)))
      .then(response =>
        calculatePaginationItens(response, setPaginationContent)
      )
      .catch(() => setLoadingError(true))
      .finally(() => setLoadingResources(false));
  };

  const shouldShowPagination = paginationContent.length > 1;

  useEffect(() => {
    const controller = new AbortController();
    getResourcesList();

    return () => controller.abort();
  }, []);

  const content = paginationContent[currentPage];

  return loadingResources ? (
    <Loading />
  ) : (
    <>
      <>
        {loadingError ? (
          <ErrorSectionContainer>
            <DefaultSection
              sectionImage={<ErrorIcon />}
              sectionTitle={translate('genericError.title')}
              sectionText={translate('genericError.text')}
              titleSize="one"
            />
          </ErrorSectionContainer>
        ) : (
          <>
            {content &&
              content.map((resource, index) => (
                <ItemCard key={index}>
                  <div>
                    <p>
                      <SubtleText>Resource type:</SubtleText>{' '}
                      <BoldText>{resource.type}</BoldText>{' '}
                      <JenkinsLink href={resource.jenkins_url} target="_blank">
                        Jenkins <OpenNewWindowIcon />
                      </JenkinsLink>
                    </p>
                    <p>
                      <SubtleText>Added: </SubtleText>{' '}
                      {formatDateTimeToShow(resource.inserted_at)}
                    </p>
                  </div>

                  <div>
                    <p>
                      <SubtleText>Signature:</SubtleText>
                      <Link href={resource.signature_url}>
                        {resource.signature_filename} <DownloadIcon />
                      </Link>
                    </p>

                    <p>
                      <SubtleText>Resource:</SubtleText>
                      <Link href={resource.url}>
                        {resource.filename} <DownloadIcon />
                      </Link>
                    </p>
                  </div>
                </ItemCard>
              ))}
          </>
        )}
      </>

      <NavigationContainer>
        {shouldShowPagination && (
          <StyledPagination
            data-testid="pagination"
            label="Pagination"
            previousLabel="Previous page"
            nextLabel="Next page"
            onChange={page => setCurrentPage(page)}
            currentPage={currentPage}
            totalPages={paginationContent.length - 1}
          />
        )}

        {downloadError && (
          <ErrorMessage
            t={translate}
            buttonLabel="Ok"
            action={() => setDownloadError(false)}
          />
        )}
      </NavigationContainer>
    </>
  );
};

FirmwareResources.propTypes = {
  t: PropTypes.func.isRequired,
  reader: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired
};

export default FirmwareResources;

import React, { useState, useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { navigate } from '@reach/router';
import { ROUTES_PATH } from 'constants/routes';
import { Card, Body } from '@sumup/circuit-ui';
import {
  UploadCarrierSuggestionRules,
  DownloadCarrierSuggestionRules,
  AnatelCoverage
} from 'assets/icons';
import PageHeader from 'components/PageHeader';
import PageContainer from 'components/PageContainer';
import BlackButton from 'components/BlackButton';
import UploadFileButton from 'components/UploadFileButton';
import ErrorMessage from 'components/ErrorMessage';
import SuccessfulMessage from 'components/SuccessfulMessage';
import UserData from 'contexts/UserData';
import {
  getCarrierSuggestionRules,
  generateCSV,
  parseInputFile,
  validateFileStructure,
  formatDataToSend,
  requestUploadCoverage
} from './CarrierSuggestionService';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const CardsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    padding: ${theme.spacings.giga} 0 ${theme.spacings.tera}
      ${theme.spacings.tera};
  `};
`;

const StyledCard = styled(Card)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 328px;
    margin: 0 ${theme.spacings.giga} ${theme.spacings.peta} 0;
    padding: ${theme.spacings.zetta} 0 ${theme.spacings.tera} 0;
  `};
`;

const CardContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 280px;
    text-align: center;
    margin-top: ${theme.spacings.mega};
  `};
`;

const ButtonStyles = css`
  margin-top: 1em;
  max-width: '150px';
`;
const StyledButton = styled(BlackButton)(ButtonStyles);
const StyledUploadButton = styled(UploadFileButton)(ButtonStyles);

const CarrierSuggestion = ({ t: translate }) => {
  const { token } = useContext(UserData);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [downloadError, setDownloadError] = useState(false);
  const [loadingUploadCoverage, setLoadingUploadCoverage] = useState(false);
  const [parsedCoverageData, setParsedCoverageData] = useState([]);
  const [uploadCoverageError, setUploadCoverageError] = useState(false);
  const [uploadCoverageSuccessful, setUploadCoverageSuccessful] = useState(
    false
  );
  const [uploadNetworkError, setUploadNetworkError] = useState(false);

  const handleDownload = () => {
    setLoadingDownload(true);

    getCarrierSuggestionRules(token)
      .then(response =>
        !response.data
          ? setDownloadError(true)
          : generateCSV(response.data).download
      )
      .catch(() => setDownloadError(true))
      .finally(() => setLoadingDownload(false));
  };

  const handleRequestUploadCoverage = () => {
    setLoadingUploadCoverage(true);

    requestUploadCoverage(formatDataToSend(parsedCoverageData), token)
      .then(response =>
        response.ok
          ? setUploadCoverageSuccessful(true)
          : setUploadNetworkError(true)
      )
      .catch(() => setUploadNetworkError(true))
      .finally(() => setLoadingUploadCoverage(false));
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isEmpty(parsedCoverageData)) {
      return validateFileStructure(
        parsedCoverageData,
        handleRequestUploadCoverage,
        setUploadCoverageError
      );
    }
  }, [parsedCoverageData]);

  const parseFile = file =>
    parseInputFile(file[0], setLoadingUploadCoverage, data =>
      setParsedCoverageData(data)
    );

  return (
    <PageContainer>
      <PageHeader noPadding titleKey={'carrierSuggestion.pageTitle'} />

      <MainContainer>
        <CardsContainer>
          <StyledCard>
            <UploadCarrierSuggestionRules />

            <CardContent>
              <Body noMargin variant="highlight" size="one">
                {translate(`carrierSuggestion.uploadNewRules.title`)}
              </Body>

              <Body noMargin size="two">
                {translate(`carrierSuggestion.uploadNewRules.text`)}
              </Body>

              <StyledButton
                variant="primary"
                data-testid="upload-new-rules"
                primarysize="mega"
                onClick={() =>
                  navigate(ROUTES_PATH.UPLOAD_CARRIER_SUGGESTION, {
                    state: {
                      currentPageKey: 'carrier-suggestion'
                    }
                  })
                }
              >
                {translate(
                  `carrierSuggestion.uploadNewRules.uploadButton.label`
                )}
              </StyledButton>
            </CardContent>
          </StyledCard>

          <StyledCard>
            <DownloadCarrierSuggestionRules />

            <CardContent>
              <Body noMargin variant="highlight" size="one">
                {translate(`carrierSuggestion.downloadCurrentRules.title`)}
              </Body>

              <Body noMargin size="two">
                {translate(`carrierSuggestion.downloadCurrentRules.text`)}
              </Body>

              <StyledButton
                variant="primary"
                data-testid="download-current-rules"
                primarysize="mega"
                isLoading={loadingDownload}
                loadingLabel="Loading..."
                onClick={() => handleDownload()}
              >
                {translate(
                  `carrierSuggestion.downloadCurrentRules.downloadButton.label`
                )}
              </StyledButton>
            </CardContent>
          </StyledCard>

          <StyledCard>
            <AnatelCoverage />

            <CardContent>
              <Body noMargin variant="highlight" size="one">
                {translate(`carrierSuggestion.uploadCoverage.title`)}
              </Body>

              <Body noMargin size="two">
                {translate(`carrierSuggestion.uploadCoverage.text`)}
              </Body>

              <StyledUploadButton
                action={parseFile}
                inputId="upload-anatel-coverage"
                inputAccept=".csv"
                buttonTestId="upload-coverage-button"
                buttonLabel={
                  'carrierSuggestion.uploadCoverage.uploadButton.label'
                }
                isLoading={loadingUploadCoverage}
                isPrimary
              />
            </CardContent>
          </StyledCard>
        </CardsContainer>
      </MainContainer>

      {downloadError && (
        <ErrorMessage
          action={() => {
            setDownloadError(false);
            handleDownload();
          }}
        />
      )}

      {(uploadCoverageError || uploadNetworkError) && (
        <ErrorMessage
          message={
            uploadNetworkError
              ? translate('pageSections.errorMessage.defaultMessage')
              : translate('carrierSuggestion.uploadCoverage.errorMessage.title')
          }
          aditionalText={
            uploadNetworkError
              ? false
              : translate('carrierSuggestion.uploadCoverage.errorMessage.text')
          }
          action={() => {
            setUploadCoverageError(false);
            setUploadNetworkError(false);
          }}
          buttonLabel="Ok"
        />
      )}

      {uploadCoverageSuccessful && (
        <SuccessfulMessage
          message={translate(
            'carrierSuggestion.uploadCoverage.successfulMessage.title'
          )}
          aditionalText={translate(
            'carrierSuggestion.uploadCoverage.successfulMessage.text'
          )}
          buttonLabel="Ok"
          action={() => setUploadCoverageSuccessful(false)}
        />
      )}
    </PageContainer>
  );
};

CarrierSuggestion.propTypes = {
  t: PropTypes.func.isRequired
};

export default CarrierSuggestion;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Card, SubHeadline, Body } from '@sumup/circuit-ui';
import { SignalTower } from 'assets/icons';

const CARD_MIN_WIDTH = '400px';
const CARD_MAX_WIDTH = '100%';

const Container = styled(Card)`
  ${({ theme }) => css`
    &:nth-of-type(odd) {
      margin-right: ${theme.spacings.giga};
    }
    width: ${CARD_MIN_WIDTH};
    max-width: ${CARD_MAX_WIDTH};
    padding: 20px;
    justify-content: flex-start;
  `};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Label = styled(SubHeadline)`
  ${({ theme }) => css`
    color: ${theme.colors.n500};
  `};
`;

const SignalTowerIcon = styled(SignalTower)`
  ${({ theme }) => css`
    display: inline-block
    width: ${theme.spacings.mega};
    height: ${theme.spacings.kilo};
  `};
`;

const ConnectionInfoCard = ({
  cardType,
  lastConnectionDate,
  lastConnectionType,
  lastConnectionApn,
  lastTransactionSimcard,
  lastTransactionWifi,
  translate
}) => {
  const isConnectionCard = cardType === 'connection';

  const getData = item =>
    item || translate('resultsInfo.cardReaderConnection.notAvailable');

  const isSimCard = type => ['3g', 'chip', 'gprs'].includes(type);

  const getType = type => {
    if (type) {
      return isSimCard(type) ? 'SIM Card' : 'Wi-fi';
    }
    return translate('resultsInfo.cardReaderConnection.notAvailable');
  };

  const getApn = lastApn =>
    lastApn || translate('resultsInfo.cardReaderConnection.notAvailable');

  const lastConnection = 'resultsInfo.cardReaderConnection.lastConnectionCard';
  const lastTransaction =
    'resultsInfo.cardReaderConnection.lastTransactionCard';
  const connectionTypes = 'resultsInfo.cardReaderConnection.connectionTypes';

  const cardContent = {
    cardTitle: isConnectionCard
      ? `${lastConnection}.title`
      : `${lastTransaction}.title`,
    column1: {
      label: isConnectionCard
        ? `${lastConnection}.date`
        : `${connectionTypes}.simCard`,
      data: isConnectionCard
        ? getData(lastConnectionDate)
        : getData(lastTransactionSimcard)
    },
    column2: {
      label: isConnectionCard
        ? `${lastConnection}.type`
        : `${connectionTypes}.wiFi`,
      data: isConnectionCard
        ? getType(lastConnectionType)
        : getData(lastTransactionWifi)
    },
    column3: {
      label: `${lastConnection}.apn`,
      data: getApn(lastConnectionApn)
    }
  };

  return (
    <Container>
      <SubHeadline noMargin>{translate(cardContent.cardTitle)}</SubHeadline>

      <InfoContainer>
        <div>
          <Label noMargin>{translate(cardContent.column1.label)}</Label>
          <Body noMargin size="two">
            {cardContent.column1.data}
          </Body>
        </div>

        <div>
          <Label noMargin>{translate(cardContent.column2.label)}</Label>
          <Body noMargin size="two">
            {cardContent.column2.data}
            {isSimCard(lastConnectionType) && <SignalTowerIcon />}
          </Body>
        </div>
      </InfoContainer>

      {isConnectionCard && (
        <InfoContainer>
          <div>
            <Label noMargin>{translate(cardContent.column3.label)}</Label>
            <Body noMargin size="two">
              {cardContent.column3.data}
            </Body>
          </div>
        </InfoContainer>
      )}
    </Container>
  );
};

ConnectionInfoCard.propTypes = {
  cardType: PropTypes.oneOf(['connection', 'transaction']),
  lastConnectionDate: PropTypes.string,
  lastConnectionType: PropTypes.oneOf(['3g', 'chip', 'gprs', 'wifi', '']),
  lastConnectionApn: PropTypes.string,
  lastTransactionSimcard: PropTypes.string,
  lastTransactionWifi: PropTypes.string,
  translate: PropTypes.func.isRequired
};

ConnectionInfoCard.defaultProps = {
  cardType: 'connection'
};

export default ConnectionInfoCard;

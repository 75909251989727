import {
  datetimeToGMTTimestamp,
  getDate
} from 'services/DateTimeFormatService';

export const getReactivationRequests = (supplier, state, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  };
  return fetch(
    `/api/sim-cards/reactivations?state=${state}&supplier=${supplier}`,
    options
  );
};

export const tableHeaders = {
  headers: [{ children: 'ICCID' }, { children: 'Request Date' }]
};

export const toEntryRow = (iccid, datetime) => [
  { children: iccid },
  { children: getDate(datetimeToGMTTimestamp(datetime)) }
];

export const formatDataToCopy = data =>
  data && data.reduce((acc, entry) => `${entry.iccid}\n${acc}`, '');

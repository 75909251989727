import React, { Fragment, useContext } from 'react';
import { toLower } from 'lodash';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Tabs, TabPanel, Body } from '@sumup/circuit-ui';
import { IconArrowDown, MissingFeature } from 'assets/icons';
import CollapsibleContent from 'components/CollapsibleContent';
import DefaultSection from 'components/DefaultSection';
import SimCardResets from 'features/SimCardResets';
import CardReaderConnection from 'features/CardReaderConnection';
import SimcardStatusHistory from 'features/SimcardStatusHistory';
import SearchResult from 'contexts/SearchResult';

const HEADING_MAX_WIDTH = '450px';

const SECTION_MAX_WIDTH = '650px';

const ArrowUp = styled(IconArrowDown)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacings.byte};
    transform: rotate(180deg);
  `};
`;

const ArrowDown = styled(IconArrowDown)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacings.byte};
  `};
`;

const Container = styled(TabPanel)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.white};
    outline: none;
  `};
`;

const CollapsibleTrigger = styled(Body)`
  ${({ theme }) => css`
    padding: ${theme.spacings.giga} ${theme.spacings.peta};
  `};
`;

const CardReaderDetails = ({
  iccid,
  sessions,
  supplierUrl,
  translate,
  noSupplier,
  ...props
}) => {
  const searchResult = useContext(SearchResult);

  const { lineSupplier } = searchResult;

  const SUPPLIERS_WITH_SESSION = ['soracom', 'arqia', 'linksolutions'];

  const SUPPLIER_HAS_SESSION_FEATURE = SUPPLIERS_WITH_SESSION.includes(
    toLower(lineSupplier) || false
  );

  const SHOW_STATUS_HISTORY = () =>
    SUPPLIER_HAS_SESSION_FEATURE ? (
      <SimcardStatusHistory
        sessions={sessions}
        supplierName={lineSupplier}
        translate={translate}
      />
    ) : (
      <Fragment>
        <DefaultSection
          sectionImage={<MissingFeature />}
          sectionTitle={
            noSupplier
              ? translate('resultsInfo.noSIMCardInfo.title')
              : translate('resultsInfo.statusHistory.missingFeature.title')
          }
          sectionText={
            noSupplier
              ? translate('resultsInfo.noSIMCardInfo.text')
              : translate('resultsInfo.statusHistory.missingFeature.text')
          }
          sectionMaxWidth={SECTION_MAX_WIDTH}
          headingMaxWidth={HEADING_MAX_WIDTH}
          titleSize="peta"
        />
        {!noSupplier && (
          <Button variant="primary" target="_blank" href={supplierUrl}>
            {translate(`resultsInfo.statusHistory.missingFeature.button`)}
          </Button>
        )}
      </Fragment>
    );

  const SUPPLIER_WITH_NO_RESETS = [];

  const SHOW_RESETS_FEATURE = () =>
    SUPPLIER_WITH_NO_RESETS.includes(toLower(lineSupplier)) || noSupplier ? (
      <DefaultSection
        sectionImage={<MissingFeature />}
        sectionTitle={
          noSupplier
            ? translate('resultsInfo.noSIMCardInfo.title')
            : translate('resultsInfo.simcardReset.missingFeature.title')
        }
        sectionText={
          noSupplier
            ? translate('resultsInfo.noSIMCardInfo.text')
            : translate('resultsInfo.simcardReset.missingFeature.text')
        }
        sectionMaxWidth={SECTION_MAX_WIDTH}
        headingMaxWidth={HEADING_MAX_WIDTH}
        titleSize="peta"
      />
    ) : (
      <SimCardResets
        iccid={iccid}
        supplierName={lineSupplier}
        applicationType={searchResult.applicationType}
      />
    );

  const tabNavigation = [
    {
      id: 'cardReaderConnection',
      tab: translate('resultsInfo.cardReaderConnection.tabTitle'),
      panel: (
        <Container>
          <CardReaderConnection translate={translate} />
        </Container>
      )
    },

    {
      id: 'statusHistory',
      tab: translate('resultsInfo.statusHistory.tabTitle'),
      panel: <Container>{SHOW_STATUS_HISTORY()}</Container>
    },

    {
      id: 'simcardReset',
      tab: translate('resultsInfo.simcardReset.tabTitle'),
      panel: <Container>{SHOW_RESETS_FEATURE()}</Container>
    }
  ];

  return (
    <CollapsibleContent
      {...props}
      expandUp
      data-testid="more-details-option"
      triggerToOpen={
        <CollapsibleTrigger noMargin>
          {translate('resultsInfo.infoDetails.moreDetails')} <ArrowDown />
        </CollapsibleTrigger>
      }
      triggerToClose={
        <CollapsibleTrigger noMargin>
          {translate('resultsInfo.infoDetails.lessDetails')} <ArrowUp />
        </CollapsibleTrigger>
      }
    >
      <Tabs items={tabNavigation} data-testid="tabNavigation" />
    </CollapsibleContent>
  );
};

CardReaderDetails.propTypes = {
  iccid: PropTypes.string,
  sessions: PropTypes.shape({
    data: PropTypes.array,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  supplierUrl: PropTypes.string,
  translate: PropTypes.func.isRequired,
  noSupplier: PropTypes.bool
};

CardReaderDetails.defaultProps = {
  noSupplier: false
};

export default CardReaderDetails;

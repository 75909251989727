import React from 'react';
import { Badge } from '@sumup/circuit-ui';
import {
  datetimeToGMTTimestamp,
  getDate,
  getTime
} from 'services/DateTimeFormatService';

export const formatTimestamp = ({ inserted_at }) => {
  const timestamp = datetimeToGMTTimestamp(inserted_at);
  return `${getDate(timestamp)} - ${getTime(timestamp)}`;
};

export const formatLocation = ({ country, region_name, city }) => {
  if (city && region_name) {
    return `${city} - ${region_name}`;
  }
  if (city) {
    return `${city} - ${country}`;
  }

  return null;
};

export const formatRequester = ({ requester_email }) => requester_email;

export const formatComment = ({ reason_code, comment }) => {
  if (reason_code) {
    return (
      <div>
        <Badge color={Badge.NEUTRAL}>{reason_code}</Badge> {comment}
      </div>
    );
  }

  return comment;
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline, Label, Select } from '@sumup/circuit-ui';
import { AttachFile } from 'assets/icons';
import UploadFileButton from 'components/UploadFileButton';
import BlackButton from 'components/BlackButton';
import {
  FileChackMark,
  getResourceTypesConfig,
  getLocalesConfig,
  handleSubmit,
  validateFile,
  validateSelect,
  renderCloseButton,
  renderErrorMessage,
  renderSuccessMessage
} from './AddResourceModalService';

const ModalTitle = styled(Headline)`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.mega};
    margin-bottom: ${theme.spacings.byte};
  `};
`;

const ModalContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;
    top: -${theme.spacings.mega};
    padding: 1.5em;
  `};
`;

const AttachFileIcon = styled(AttachFile)`
  margin-right: 0.5em;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin-top: 2.5em;

  & > button {
    margin-left: 0.75em;
    padding: 0.5em 2.5em;
  }
`;

const FieldLabel = styled(Label)`
  width: 65%;
  margin: 0.5em 0;

  &:last-of-type {
    margin-bottom: 2em;
  }

  & select {
    padding: 0.5em;
  }

  & span:nth-of-type(2) {
    color: #de331d;
  }
`;

const FileName = styled.span`
  margin: 0 0 0.5em 0.5em;
  font-size: 0.8em;
`;

const AddResourceModal = ({
  action,
  reader,
  version,
  resourceTypes,
  locales,
  close,
  translate,
  ...props
}) => {
  const [uploadLoading, setUploadLoading] = useState(false);
  const [successfulUpload, setSuccessfulUpload] = useState(false);
  const [failedUpload, setFailedUpload] = useState(false);
  const [uploadErrors, setUploadErrors] = useState('');
  const [typeValue, setTypeValue] = useState({ value: '', hasError: false });
  const [localesValue, setLocalesValue] = useState({
    value: '',
    hasError: false
  });
  const [fileValue, setFileValue] = useState({ value: '', hasError: false });
  const [fileName, setFileName] = useState('');
  const [signatureFileValue, setSignatureFileValue] = useState({
    value: '',
    hasError: false
  });
  const [signatureFileName, setSignatureFileName] = useState('');

  const resourceTypesConfig = getResourceTypesConfig(resourceTypes, typeValue);

  const localesConfig = getLocalesConfig(locales, localesValue);

  const formValidationErrors = () => {
    const fields = [
      { field: typeValue, action: setTypeValue },
      { field: localesValue, action: setLocalesValue },
      { field: fileValue, action: setFileValue },
      { field: signatureFileValue, action: setSignatureFileValue }
    ];

    return fields.filter(item => item.field.value === '');
  };

  const submit = () =>
    handleSubmit(
      formValidationErrors,
      reader,
      version,
      typeValue.value,
      localesValue.value,
      fileValue.value,
      signatureFileValue.value,
      setUploadLoading,
      setUploadErrors,
      setFailedUpload,
      setSuccessfulUpload
    );

  return (
    <ModalContent {...props}>
      {failedUpload && renderErrorMessage(translate, uploadErrors, close)}

      {successfulUpload && renderSuccessMessage()}

      {!failedUpload && !successfulUpload && (
        <>
          <ModalTitle noMargin as="h3" size="four">
            Add resource
          </ModalTitle>

          <FieldLabel htmlFor={resourceTypesConfig.id}>
            <Select
              noMargin
              size="kilo"
              hideLabel
              id={resourceTypesConfig.id}
              name={resourceTypesConfig.id}
              options={resourceTypesConfig.options}
              label="Select the resource type"
              data-testid="resource-types-select"
              placeholder={resourceTypesConfig.placeholder}
              value={typeValue.value}
              invalid={typeValue.hasError}
              onChange={event => validateSelect(event, typeValue, setTypeValue)}
              validationHint={
                typeValue.hasError ? 'This field is mandatory' : ''
              }
            />
          </FieldLabel>

          <FieldLabel htmlFor={localesConfig.id}>
            <Select
              noMargin
              size="kilo"
              hideLabel
              id={localesConfig.id}
              name={localesConfig.id}
              options={localesConfig.options}
              label="Select the locale"
              data-testid="locales-select"
              placeholder={localesConfig.placeholder}
              value={localesValue.value}
              invalid={localesValue.hasError}
              onChange={event =>
                validateSelect(event, localesValue, setLocalesValue)
              }
              validationHint={
                localesValue.hasError ? 'This field is mandatory' : ''
              }
            />
          </FieldLabel>

          <UploadFileButton
            action={files => {
              setFileName(files[0].name);
              return validateFile(files[0], fileValue, setFileValue);
            }}
            validationHint={fileValue.hasError ? 'This field is mandatory' : ''}
            inputId="add-resource-input"
            buttonWidth="65%"
            style={{ margin: '.5em 0', padding: '.5em 0' }}
            buttonTestId="add-resource-button"
            buttonLabel="Upload resource file"
            icon={<AttachFileIcon />}
            isPrimary={false}
            size="kilo"
            t={translate}
          />
          {fileName && (
            <FileName>
              <FileChackMark />
              {fileName}
            </FileName>
          )}

          <UploadFileButton
            action={files => {
              setSignatureFileName(files[0].name);
              return validateFile(
                files[0],
                signatureFileValue,
                setSignatureFileValue
              );
            }}
            validationHint={
              signatureFileValue.hasError ? 'This field is mandatory' : ''
            }
            inputId="add-signature-input"
            buttonWidth="65%"
            style={{ margin: '.5em 0', padding: '.5em 0' }}
            buttonTestId="add-signature-button"
            buttonLabel="Upload signature file"
            icon={<AttachFileIcon />}
            isPrimary={false}
            size="kilo"
            t={translate}
          />
          {signatureFileName && (
            <FileName>
              <FileChackMark />
              {signatureFileName}
            </FileName>
          )}

          <ButtonsContainer>
            {renderCloseButton(close, 'Cancel')}

            <BlackButton
              onClick={() => submit()}
              buttonTestId="add-resource-button"
              isLoading={uploadLoading}
              loadingLabel="Loading"
              size="kilo"
              isPrimary
            >
              Add resource
            </BlackButton>
          </ButtonsContainer>
        </>
      )}
    </ModalContent>
  );
};

AddResourceModal.propTypes = {
  action: PropTypes.func.isRequired,
  reader: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  resourceTypes: PropTypes.array.isRequired,
  locales: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default AddResourceModal;

import { groupBy, isEmpty, isEqual, toLower } from 'lodash';
import { parse } from 'papaparse';

const ERROR_MESSAGES = {
  EMPTY_UF: 'UF field can not be empty',
  EMPTY_CITY: 'City field can not be empty',
  DUPLICATED_CITY: 'Duplicated city',
  INVALID_CARRIER: 'Invalid carrier(s)'
};

export const parseInputFile = (file, loading, callback) => {
  loading(true);

  parse(file[0], {
    complete: results => {
      callback(results.data);
      loading(false);
    }
  });
};

export const validateFileStructure = (file, proceed, setInvalid) => {
  const expectedHeaders = ['UF', 'City', 'Carrier 1', 'Carrier 2', 'Carrier 3'];
  const inputHeaders = file.shift();

  if (isEmpty(inputHeaders)) {
    setInvalid(true);
  } else {
    isEqual(inputHeaders, expectedHeaders) ? proceed() : setInvalid(true);
  }
};

const validCarriers = ['claro', 'tim', 'vivo', ''];

export const hasValidEntryCarriers = (carrier1, carrier2, carrier3) => {
  let isValid = true;

  if (!isEmpty(carrier2) && !isEmpty(carrier3)) {
    isValid = carrier2 !== carrier3;
  }
  return (
    isValid &&
    !isEmpty(carrier1) &&
    validCarriers.includes(toLower(carrier1)) &&
    validCarriers.includes(toLower(carrier2)) &&
    validCarriers.includes(toLower(carrier3)) &&
    carrier1 !== carrier2 &&
    carrier1 !== carrier3
  );
};

export const parseObjectsList = entries =>
  entries.map(item => ({
    uf: item[0],
    city: item[1],
    carrier1: item[2],
    carrier2: item[3],
    carrier3: item[4],
    line: entries.indexOf(item) + 2
  }));

export const validateEmptyFields = entries => {
  const validEntries = [];
  const invalidEntries = [];

  parseObjectsList(entries).forEach(item => {
    if (!isEmpty(item.uf.trim()) && !isEmpty(item.city.trim())) {
      return validEntries.push(item);
    }
    item.errors = isEmpty(item.uf.trim())
      ? [ERROR_MESSAGES.EMPTY_UF]
      : [ERROR_MESSAGES.EMPTY_CITY];

    return invalidEntries.push(item);
  });

  return {
    validEntries,
    invalidEntries
  };
};

export const validateDuplicateCities = entries => {
  const duplicatesList = [];
  const validEntries = [];

  const { validEntries: valid, invalidEntries } = validateEmptyFields(entries);
  const groupedByStates = groupBy(valid, item => item.uf);

  Object.keys(groupedByStates).forEach(uf => {
    const cities = groupBy(groupedByStates[uf], elem => toLower(elem.city));

    Object.keys(cities).forEach(city => {
      const cityContent = groupedByStates[uf].filter(entry =>
        isEqual(toLower(entry.city), toLower(city))
      );

      if (cityContent.length > 1) {
        return cityContent.forEach(elem => {
          elem.errors = [ERROR_MESSAGES.DUPLICATED_CITY];
          return duplicatesList.push(elem);
        });
      }
      return validEntries.push(...cityContent);
    });
  });

  return {
    validEntries,
    invalidEntries: [...invalidEntries, ...duplicatesList]
  };
};

export const validateData = parsedFileData => {
  const invalid = [];
  const { validEntries, invalidEntries } = validateDuplicateCities(
    parsedFileData
  );

  const validEntriesList = validEntries.filter(row => {
    const validEntry = hasValidEntryCarriers(
      row.carrier1,
      row.carrier2,
      row.carrier3
    );

    if (!validEntry) {
      row.errors
        ? row.errors.push(ERROR_MESSAGES.INVALID_CARRIER)
        : (row.errors = [ERROR_MESSAGES.INVALID_CARRIER]);
      invalid.push(row);
    }
    return validEntry;
  });

  return {
    valid: validEntriesList,
    invalid: [...invalidEntries, ...invalid]
  };
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getOr } from 'lodash/fp';
import { navigate } from '@reach/router';
import { ROUTES_PATH } from 'constants/routes';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Pagination } from '@sumup/circuit-ui';
import { Error as ErrorIcon } from 'assets/icons';
import BlackButton from 'components/BlackButton';
import DefaultSection from 'components/DefaultSection';
import Loading from 'components/Loading';
import MainContent from 'components/MainContent';
import ReleaseCard from '../../../ReleaseCard';
import ReaderImageCard from '../../../ReaderImageCard';
import AddTextFileRelease from '../AddTextFileRelease';
import { uploadRelease, getReleasesList } from './ListReleasesPageService';

const Container = styled.div`
  display: flex;
  margin-top: 1.5em;
`;

const ContentContainer = styled.div`
  flex-grow: 2;
`;

const ErrorSectionContainer = styled.div`
  width: 100%;
  margin: auto;

  & > div {
    max-width: 100%;
  }
  & div > svg {
    width: 66px;
    height: 68px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2em;
`;

const NavigationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
`;

const GoBackButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    max-width: 160px;
    margin: 0 0 ${theme.spacings.peta} 0;
  `};
`;

const StyledPagination = styled(Pagination)`
  justify-content: end;

  & button,
  & button:hover {
    color: black;
    background-color: transparent;
    border-color: transparent;
  }
  & button:focus {
    box-shadow: 0 0 0 4px #ddd;
  }
  & select:focus {
    box-shadow: 0 0 0 2px #ddd;
  }
`;

const ListReleasesPage = ({ t: translate, location, gotToReleasePage }) => {
  const [loadingReleases, setLoadingReleases] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [paginationContent, setPaginationContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [listReleases, setListReleases] = useState(true);

  const readerPath = getOr(false, 'path', location.state);
  const shouldShowPagination = paginationContent.length > 1;

  useEffect(() => {
    const controller = new AbortController();
    getReleasesList(
      readerPath,
      setLoadingReleases,
      setLoadingError,
      setPaginationContent
    );

    return () => controller.abort();
  }, []);

  const shouldRenderReleases = () => {
    const releases = paginationContent[currentPage];

    return loadingError ? (
      <ErrorSectionContainer>
        <DefaultSection
          sectionImage={<ErrorIcon />}
          sectionTitle={translate('genericError.title')}
          sectionText={translate('genericError.text')}
          titleSize="one"
        />
      </ErrorSectionContainer>
    ) : (
      <>
        {releases &&
          releases.map((release, index) => (
            <ReleaseCard
              key={index}
              type="textFiles"
              release={release}
              action={() => gotToReleasePage(release)}
            />
          ))}
      </>
    );
  };

  const renderUploadReleaseButton = () =>
    listReleases && (
      <ButtonsContainer>
        <BlackButton
          onClick={() => setListReleases(false)}
          buttonTestId="upload-files-button"
          buttonWidth="245px"
          isPrimary
          multipleFiles
          t={translate}
        >
          Upload new release
        </BlackButton>
      </ButtonsContainer>
    );

  const goBackAction = () =>
    listReleases
      ? navigate(ROUTES_PATH.CARD_READERS_CONFIGURATION)
      : setListReleases(true);

  return loadingReleases ? (
    <Loading />
  ) : (
    <>
      <MainContent style={{ marginTop: !listReleases ? '2em' : '0' }}>
        {renderUploadReleaseButton()}

        <Container>
          <ReaderImageCard location={location} />

          <ContentContainer>
            {listReleases ? (
              shouldRenderReleases()
            ) : (
              <AddTextFileRelease
                translate={translate}
                action={data => uploadRelease(readerPath, data)}
                goBack={() => goBackAction()}
              />
            )}
          </ContentContainer>
        </Container>
      </MainContent>

      <NavigationContainer>
        <GoBackButton onClick={() => goBackAction()}>
          {translate('cardReadersConfiguration.goBack')}
        </GoBackButton>

        {listReleases && shouldShowPagination && (
          <StyledPagination
            data-testid="pagination"
            label="Pagination"
            previousLabel="Previous page"
            nextLabel="Next page"
            onChange={page => setCurrentPage(page)}
            currentPage={currentPage}
            totalPages={paginationContent.length - 1}
          />
        )}
      </NavigationContainer>
    </>
  );
};

ListReleasesPage.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  gotToReleasePage: PropTypes.func.isRequired
};

export default ListReleasesPage;

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ImageSimCard, ImageNotFound } from 'assets/icons';
import { navigate } from '@reach/router';
import { ROUTES_PATH } from 'constants/routes';
import DefaultSection from 'components/DefaultSection';
import PageHeader from 'components/PageHeader';
import PageContainer from 'components/PageContainer';
import SearchResult from 'contexts/SearchResult';
import UserData from 'contexts/UserData';
import SearchForm from './components/SearchForm';
import SearchResultCard from './components/SearchResultCard';

import {
  getCardReader,
  cardReaderToSearchResult,
  getSessions
} from './SerialNumberSearchService';

const MainContent = styled.div`
  ${({ hasResult }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${hasResult ? `flex-start` : `center`};
    flex: 3;
  `};
`;

const SearchResultContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: ${theme.spacings.zetta};
  `};
`;

const SerialNumberSearch = ({ t: translate, initialSerialNumber }) => {
  const { token, email: userEmail } = useContext(UserData);
  const [searching, setSearching] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [sessions, setSessions] = useState(null);

  const clearSearch = () => {
    setSearchResult(null);
    navigate(ROUTES_PATH.SERIAL_NUMBER_SEARCH.ROOT, {
      state: { currentPageKey: 'reader-connectivity' }
    });
  };

  const setNotFound = () => setSearchResult({ serialNumber: null });

  const hasResult = searchResult && searchResult.serialNumber;

  const SUCCESS_HTTP_RESPONSE = 200;

  const searchFilter = 'SN';

  const querySessions = serialNumber =>
    getSessions(serialNumber, token)
      .then(response => response.json())
      .then(data =>
        setSessions({
          data,
          status: SUCCESS_HTTP_RESPONSE
        })
      )
      .catch(response => setSessions({ data: [], status: response.status }));

  const searchSerialNumber = ({ serialNumber, email, filter }) => {
    setSearching(true);

    getCardReader(serialNumber, email, filter)
      .then(response => {
        querySessions(serialNumber);

        setSearchResult(cardReaderToSearchResult(response));
        setSearching(false);
      })
      .catch(() => setNotFound())
      .finally(() => setSearching(false));
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (initialSerialNumber) {
      searchSerialNumber({
        serialNumber: initialSerialNumber,
        email: userEmail,
        filter: searchFilter
      });
      return setSearching(true);
    }
  }, [initialSerialNumber, token]);

  const handleSubmit = ({ serialNumber }) => {
    navigate(`${ROUTES_PATH.SERIAL_NUMBER_SEARCH.SEARCH}/${serialNumber}`, {
      state: { currentPageKey: 'reader-connectivity' }
    });
    searchSerialNumber({
      serialNumber,
      email: userEmail,
      filter: searchFilter
    });
  };

  const welcomeTitle = translate('welcomeSection.title');

  const welcomeText = translate('welcomeSection.text');

  const resultNotFoundTitle = translate('resultNotFound.serialNumber.title');

  const resultNotFoundText = translate('resultNotFound.serialNumber.text');

  const imageMaxWidth = '300px';

  const sectionMaxWidth = '525px';

  return (
    <PageContainer>
      <PageHeader>
        <SearchForm
          searching={searching}
          onSubmit={handleSubmit}
          onReset={clearSearch}
          initialSerialNumber={initialSerialNumber}
        />
      </PageHeader>

      <MainContent hasResult={hasResult}>
        <SearchResult.Provider value={searchResult}>
          {isEmpty(searchResult) ? (
            <DefaultSection
              sectionImage={<ImageSimCard />}
              sectionTitle={welcomeTitle}
              sectionText={welcomeText}
              imageMaxWidth={imageMaxWidth}
            />
          ) : (
            <SearchResultContainer>
              {isEmpty(searchResult.serialNumber) ? (
                <DefaultSection
                  sectionImage={<ImageNotFound />}
                  sectionTitle={resultNotFoundTitle}
                  sectionText={resultNotFoundText}
                  sectionMaxWidth={sectionMaxWidth}
                  imageMaxWidth={imageMaxWidth}
                />
              ) : (
                <SearchResultCard
                  searchResult={searchResult}
                  sessionsResponse={sessions}
                />
              )}
            </SearchResultContainer>
          )}
        </SearchResult.Provider>
      </MainContent>
    </PageContainer>
  );
};

SerialNumberSearch.propTypes = {
  t: PropTypes.func.isRequired,
  initialSerialNumber: PropTypes.string
};

export default SerialNumberSearch;

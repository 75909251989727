import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ButtonGroup, Headline, Body } from '@sumup/circuit-ui';
import { ConfirmationImage } from 'assets/icons';

const ModalTitle = styled(Headline)`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.mega};
    margin-bottom: ${theme.spacings.byte};
  `};
`;

const ModalContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -${theme.spacings.mega};
  `};
`;

const ConfirmationModal = ({
  action,
  close,
  translate,
  title,
  text,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <ModalContent {...props}>
      <ConfirmationImage />

      <ModalTitle noMargin as="h3" size="four">
        {`${translate(
          title || 'checkInputtedDataPage.confirmationModal.title'
        )}`}
      </ModalTitle>

      <Body noMargin>
        {`${translate(text || 'checkInputtedDataPage.confirmationModal.text')}`}
      </Body>

      <ButtonGroup
        style={{ marginTop: '1em' }}
        align="center"
        actions={{
          secondary: {
            children: translate(
              'checkInputtedDataPage.confirmationModal.cancelButton'
            ),
            onClick: close,
            disabled: loading,
            'data-testid': 'cancel-button'
          },
          primary: {
            children: translate(
              'checkInputtedDataPage.confirmationModal.confirmButton'
            ),
            onClick: () => {
              setLoading(true);
              return action();
            },
            isLoading: loading,
            loadingLabel: 'Loading...',
            'data-testid': 'confirm-button'
          }
        }}
      />
    </ModalContent>
  );
};

ConfirmationModal.propTypes = {
  action: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default ConfirmationModal;

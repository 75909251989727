import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Body, Hr } from '@sumup/circuit-ui';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import MainContent from 'components/MainContent';
import ReadersList from './components/ReadersList';

const SpaceBetweenHorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const CardReadersList = ({ t: translate }) => (
  <PageContainer>
    <PageHeader noPadding titleKey={'cardReadersConfiguration.pageTitle'} />

    <MainContent>
      <SpaceBetweenHorizontalContainer>
        <Body noMargin>{translate('cardReadersConfiguration.text')}</Body>
      </SpaceBetweenHorizontalContainer>

      <Hr />
      <ReadersList />
    </MainContent>
  </PageContainer>
);

CardReadersList.propTypes = {
  t: PropTypes.func.isRequired
};

export default CardReadersList;

import { calculatePaginationItens } from 'services/PaginationService';

export const uploadRelease = (reader, data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return fetch(
    `/assets_management_api/devices/${reader}/specs/text_file/releases`,
    options
  );
};

export const getReleasesList = (
  readerPath,
  setLoading,
  setError,
  setPagination
) => {
  setLoading(true);

  fetch(`/assets_management_api/devices/${readerPath}/specs/text_file/releases`)
    .then(response => (response.ok ? response.json() : setError(true)))
    .then(response => calculatePaginationItens(response.data, setPagination))
    .catch(() => setError(true))
    .finally(() => setLoading(false));
};

import React from 'react';
import { BlueCheck } from 'assets/icons';

const renderAvailabilityFilterOption = (title, text) => (
  <div
    style={{
      textAlign: 'left',
      display: 'flex',
      padding: '1em 0'
    }}
  >
    <div style={{ flexGrow: '1', minWidth: '1.5em' }}>
      <BlueCheck />
    </div>

    <div style={{ flexGrow: '9' }}>
      <h1 style={{ fontWeight: 'bold', fontSize: '1.25em' }}>{title}</h1>
      <p style={{ fontSize: '14px', color: '#666' }}>{text}</p>
    </div>
  </div>
);

export const availabilityFiltersOptions = [
  {
    value: 'allow',
    children: renderAvailabilityFilterOption(
      'Allow',
      'Make it available only to a subset of countries, merchant codes, or terminal serial numbers.'
    )
  },
  {
    value: 'deny',
    children: renderAvailabilityFilterOption(
      'Deny',
      'Remove it from a subset of countries, merchant codes, or terminal serial numbers.'
    )
  }
];

export const toggleAvailabilityFiltersValue = (currentValues, newValue) => {
  const items = currentValues || [];
  if (items.length > 0) {
    items.filter((elem, index) =>
      elem === newValue ? items.splice(index, 1) : items.push(newValue)
    );
  } else {
    items.push(newValue);
  }
  return items;
};

const validateRequired = (
  version,
  setVersion,
  releaseNotes,
  setReleaseNotes
) => {
  version.value === '' && setVersion({ value: '', hasError: true });
  releaseNotes.value === '' && setReleaseNotes({ value: '', hasError: true });

  return version.value !== '' && releaseNotes.value !== '';
};

export const handleSubmit = (
  action,
  version,
  setVersion,
  releaseNotes,
  setReleaseNotes,
  setLoading,
  setData,
  setErrors,
  setSuccessful,
  setFailed,
  isUpdate
) => {
  if (
    isUpdate ||
    validateRequired(version, setVersion, releaseNotes, setReleaseNotes)
  ) {
    setLoading(true);
    const data = setData();

    return action(data)
      .then(response => response.json())

      .then(response => {
        response.errors && setErrors(response.errors);
        return response;
      })

      .then(response =>
        response.errors ? setFailed(true) : setSuccessful(true)
      )
      .catch(() => setFailed(true))
      .finally(() => setLoading(false));
  }
};

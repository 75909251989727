import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { isEmpty } from 'lodash/fp';
import {
  datetimeToGMTTimestamp,
  getTimezone
} from 'services/DateTimeFormatService';

import {
  formatComment,
  formatLocation,
  formatRequester,
  formatTimestamp
} from './ResetsTableService';

const FIRST_ITEM = 0;

const TABLE_CELL_MIN_WIDTH = '200px';

const TableContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;

    th,
    td {
      min-width: ${TABLE_CELL_MIN_WIDTH};
      padding: ${theme.spacings.giga};
      vertical-align: middle;
    }
    td {
      color: ${theme.colors.n700};
      white-space: normal;
    }
  `};
`;

const toResetRow = emptyCellLabel => simCardReset => [
  { children: formatTimestamp(simCardReset) },
  { children: formatLocation(simCardReset) || emptyCellLabel },
  { children: formatRequester(simCardReset) || emptyCellLabel },
  { children: formatComment(simCardReset) || emptyCellLabel }
];

const ResetsTable = ({ simCardResets, t }) => {
  const emptyCellLabel = t(
    'resultsInfo.simcardReset.resetsTable.rows.emptyCell'
  );

  const currentTimezone = isEmpty(simCardResets)
    ? null
    : getTimezone(
        datetimeToGMTTimestamp(simCardResets[FIRST_ITEM].inserted_at)
      );

  const timezoneLabel = currentTimezone ? ` (${currentTimezone})` : '';

  return (
    <TableContainer>
      <Table
        noShadow
        headers={[
          {
            children: `${t(
              'resultsInfo.simcardReset.resetsTable.headers.lastReset'
            )}${timezoneLabel}`
          },
          {
            children: t('resultsInfo.simcardReset.resetsTable.headers.location')
          },
          {
            children: t(
              'resultsInfo.simcardReset.resetsTable.headers.requester'
            )
          },
          {
            children: t('resultsInfo.simcardReset.resetsTable.headers.comment')
          }
        ]}
        rows={simCardResets.map(toResetRow(emptyCellLabel))}
      />
    </TableContainer>
  );
};

ResetsTable.propTypes = {
  simCardResets: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
};

export default ResetsTable;

import { toLower } from 'lodash/fp';
import {
  datetimeToGMTTimestamp,
  getDate,
  getTime
} from 'services/DateTimeFormatService';

export const formatDate = date => {
  const timestamp = datetimeToGMTTimestamp(date);
  return `${getDate(timestamp)} - ${getTime(timestamp)}`;
};

export const formatData = (lastConnection, lastTransaction) => ({
  connection: {
    lastDate:
      lastConnection && lastConnection.date
        ? formatDate(lastConnection.date)
        : '',
    lastType:
      lastConnection && lastConnection.mode ? toLower(lastConnection.mode) : ''
  },
  transaction: {
    lastSimCardTx:
      lastTransaction && lastTransaction.chipDate
        ? formatDate(lastTransaction.chipDate)
        : '',
    lastWifiTx:
      lastTransaction && lastTransaction.wifiDate
        ? formatDate(lastTransaction.wifiDate)
        : ''
  }
});

export const isCloudwalk = applicationType =>
  toLower(applicationType) === 'cloudwalk';

export const euSuppliers = ['soracom', 'vodafone'];

import React from 'react';
import { camelCase } from 'lodash/fp';
import { ArqiaIcon, LinkSolutionsIcon, TelefonicaIcon } from 'assets/icons';
import {
  getDate,
  datetimeToGMTTimestamp
} from 'services/DateTimeFormatService';

export const DEFAULT_EMPTY_INFO = '–';

export const FIRST_ITEM = 0;

export const CARD_SUPPLIERS_MAP = {
  Arqia: {
    icon: <ArqiaIcon />,
    link: 'http://arqia.saitro.com/'
  },

  LinkSolutions: {
    icon: <LinkSolutionsIcon />,
    link: 'https://lsm.linksolutions.com.br/#/'
  },

  Telefonica: {
    icon: <TelefonicaIcon />,
    link: 'https://kiteplatform-vivo-br.telefonica.com/'
  }
};

export const getLineStateColor = state => {
  const colorsList = {
    standby: 'n500',
    blocked: 'y500',
    suspended: 'o300',
    cancelled: 'r500',
    active: 'b500',
    ready: 'b500',
    unknown: 'n500',
    notAvailable: 'n300'
  };
  const stateList = Object.keys(colorsList);

  return stateList.includes(state) ? colorsList[state] : colorsList.unknown;
};

export const formatLineStateData = state => {
  const camelCasedState = camelCase(state.name);

  const formatedDate = state.stateChangedAt
    ? getDate(datetimeToGMTTimestamp(state.stateChangedAt))
    : '';

  return {
    state: camelCasedState,
    color: getLineStateColor(camelCasedState),
    updatedAt: formatedDate
  };
};

export const getLineStateData = state =>
  state !== null && state.name ? formatLineStateData(state) : false;

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { isEmpty, capitalize } from 'lodash';
import { Card, Body } from '@sumup/circuit-ui';
import { Error, Success, UploadCarrierSuggestionRules } from 'assets/icons';
import UserData from 'contexts/UserData';
import PageHeader from 'components/PageHeader';
import PageContainer from 'components/PageContainer';
import ErrorMessage from 'components/ErrorMessage';
import UploadFileButton from 'components/UploadFileButton';

import {
  uploadSimCards,
  parseInputFile,
  validateFileStructure,
  formatDataToSend,
  fileExpectedFields
} from './UploadSimCardsService';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const CardsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    padding: ${theme.spacings.giga} 0 ${theme.spacings.tera}
      ${theme.spacings.tera};
  `};
`;

const StyledCard = styled(Card)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 328px;
    height: 368px;
    margin: 0 ${theme.spacings.giga} ${theme.spacings.peta} 0;
    padding: ${theme.spacings.zetta} 0 ${theme.spacings.tera} 0;
  `};
`;

const CardContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 280px;
    text-align: center;
    margin-top: ${theme.spacings.mega};
  `};
`;

const CardBodyContainer = styled.div`
  ${({ theme }) => css`
    li {
      display: flex;
      align-items: center;
      margin-bottom: ${theme.spacings.byte};
      & svg {
        margin-right: ${theme.spacings.byte};
      }
    }
  `}
`;

const StyledUploadButton = styled(UploadFileButton)`
  margin-top: 0.75em;
  width: 155px;
  max-width: auto;
`;

const ErrorListCard = styled(StyledCard)`
  ${({ theme }) => css`
    height: auto;
    max-width: 385px;
    padding-left: ${theme.spacings.tera};
    padding-right: ${theme.spacings.tera};
  `};
`;

const ErrorList = styled(Body)`
  margin-top: 1em;
  margin-left: 2em;
  border-top: 1px solid #ccc;
  padding-top: 1em;
  span {
    font-weight: bold;
    margin-left: 0.25em;
  }
`;

const ResultHeading = styled.li`
  font-weight: bold;
`;

const IconStyles = css`
  width: 1.5em;
  height: 1.5em;
`;

const SuccessIcon = styled(Success)(IconStyles);
const ErrorIcon = styled(Error)(IconStyles);

const UploadSimCards = ({ t: translate }) => {
  const { token } = useContext(UserData);
  const [loadingInput, setLoadingInput] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadedData, setUploadedData] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);
  const [invalidFile, setInvalidFile] = useState(false);

  const sortPerFileRow = arr => arr.sort((a, b) => a.file_row - b.file_row);

  const handleUploadRequest = (file, errors) => {
    setLoadingInput(true);

    uploadSimCards(file, token)
      .then(response =>
        !response.ok && response.status !== 200
          ? setUploadError(true)
          : response.json()
      )
      .then(response => {
        // eslint-disable-next-line no-unused-expressions
        !isEmpty(response.validating_errors)
          ? setValidationErrors(
              sortPerFileRow([...errors, ...response.validating_errors])
            )
          : setValidationErrors(errors);

        return setUploadedData(response);
      })
      .then(() => setUploadSuccess(true))
      .catch(() => setUploadError(true))
      .finally(() => setLoadingInput(false));
  };

  const validateRequiredEntries = data => {
    const invalidEntries = [];
    const formatedData = formatDataToSend(data);

    formatedData.forEach(row =>
      fileExpectedFields.forEach(field => {
        // eslint-disable-next-line no-param-reassign
        if (isEmpty(row[field])) {
          // eslint-disable-next-line no-param-reassign
          row.invalid = true;
          invalidEntries.push({
            field,
            file_row: row.file_row,
            message: `The field ${capitalize(field)} cannot be empty.`
          });
        }
      })
    );
    const validEntries = formatedData.filter(row => !row.invalid);

    return handleUploadRequest(validEntries, invalidEntries);
  };

  const validateFile = file => {
    const validatedFile = validateFileStructure(file);

    return validatedFile
      ? validateRequiredEntries(validatedFile)
      : setInvalidFile(true);
  };

  const parseFile = file => {
    setUploadSuccess(false);
    return parseInputFile(file, setLoadingInput, data => validateFile(data));
  };

  const renderSuccessCard = (data, errors) => (
    <ErrorListCard>
      <CardBodyContainer>
        <ul>
          <ResultHeading>
            {`${data.total_created + data.total_updated} entries successfully
            synced:`}
          </ResultHeading>
          <li>
            <SuccessIcon /> {`${data.total_created} entries created`}
          </li>
          <li>
            <SuccessIcon /> {`${data.total_updated} entries updated`}
          </li>
          {errors.length > 0 && (
            <li>
              <ErrorIcon />
              {`${errors.length} entries with the following problems:`}
            </li>
          )}
        </ul>
        {!isEmpty(errors) && (
          <ErrorList noMargin as="ul" size="two">
            {errors.map(error => (
              <li key={`${error.file_row}-${error.field}`}>
                Line <span>{`${error.file_row}`}</span>: {`${error.message}`}
              </li>
            ))}
          </ErrorList>
        )}
      </CardBodyContainer>
    </ErrorListCard>
  );

  return (
    <PageContainer>
      <PageHeader noPadding titleKey={'uploadSimCards.pageTitle'} />

      <MainContainer>
        <CardsContainer>
          <StyledCard>
            <UploadCarrierSuggestionRules />

            <CardContent>
              <Body noMargin variant="highlight" size="one">
                {translate(`uploadSimCards.uploadCard.title`)}
              </Body>

              <Body noMargin size="two">
                {translate(`uploadSimCards.uploadCard.text`)}
              </Body>

              <StyledUploadButton
                action={parseFile}
                inputId="upload-sim-card-button"
                inputAccept=".csv"
                buttonTestId="upload-card-button"
                buttonLabel={'uploadSimCards.uploadCard.button.label'}
                isLoading={loadingInput}
                isPrimary
              />
            </CardContent>
          </StyledCard>

          {uploadSuccess && renderSuccessCard(uploadedData, validationErrors)}
        </CardsContainer>
      </MainContainer>

      {(uploadError || invalidFile) && (
        <ErrorMessage
          message={
            invalidFile
              ? translate('uploadSimCards.errorMessage.title')
              : translate('pageSections.errorMessage.defaultMessage')
          }
          aditionalText={
            invalidFile ? translate('uploadSimCards.errorMessage.text') : ''
          }
          action={() => {
            setUploadError(false);
            setInvalidFile(false);
          }}
          buttonLabel="Ok"
        />
      )}
    </PageContainer>
  );
};

UploadSimCards.propTypes = {
  t: PropTypes.func.isRequired
};

UploadSimCards.defaultProps = {};

export default UploadSimCards;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Card, Body } from '@sumup/circuit-ui';
import BlackButton from 'components/BlackButton';

export const containersBaseStyles = `
  display: flex;
  width: 100%;
  height: 100%;
`;

const CardContainer = styled(Card)`
  ${({ theme }) => css`
    ${containersBaseStyles}
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: ${theme.spacings.bit};
    min-height: 200px;
    max-width: 350px;
    margin: 0 ${theme.spacings.peta} ${theme.spacings.peta} 0;
    padding: ${theme.spacings.giga} ${theme.spacings.giga}
      ${theme.spacings.mega} ${theme.spacings.giga};
  `};
`;

const CardContent = styled.div`
  ${containersBaseStyles}
  flex-direction: column;
`;

const Title = styled(Body)`
  ${({ theme }) => css`
    color: ${theme.colors.n900};
  `};
`;

const ButtonBar = styled.div`
  ${containersBaseStyles}
  justify-content: center;
  margin-top: 1em;
`;

const ReportCard = ({
  buttonLabel,
  isLoading,
  cardTitle,
  cardSubtitle,
  children,
  action,
  t: translate,
  ...props
}) => (
  <CardContainer {...props}>
    <CardContent>
      <Title noMargin bold size="one">
        {cardTitle}
      </Title>

      {cardSubtitle && (
        <Title noMargin size="one">
          {cardSubtitle}
        </Title>
      )}

      {children && children}
    </CardContent>

    <ButtonBar>
      <BlackButton
        variant="primary"
        data-testid="report-card-button"
        primarysize="mega"
        isLoading={isLoading}
        loadingLabel="Loading..."
        onClick={() => action()}
      >
        {translate(`simDeactivationReports.button.${buttonLabel}.label`)}
      </BlackButton>
    </ButtonBar>
  </CardContainer>
);

ReportCard.propTypes = {
  buttonLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  cardTitle: PropTypes.string.isRequired,
  cardSubtitle: PropTypes.string,
  children: PropTypes.node,
  action: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

ReportCard.defaultProps = {
  buttonLabel: 'downloadFile',
  isLoading: false
};

export default ReportCard;

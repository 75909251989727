import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { toLower } from 'lodash';
import { ButtonGroup, Table, Body } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';
import { Success, Error } from 'assets/icons';
import { formatDateTimeToShow } from 'services/DateTimeFormatService';

const TableContainer = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacings.giga};
    width: 75%;
    display: block;
    & tbody {
      font-size: ${theme.typography.body.two.fontSize};
    }
  `};
`;

const ModalHeading = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.25em;
    margin-bottom: 0.5em;

    & svg {
      margin-right: ${theme.spacings.byte};
      width: 1.5em;
    }
  `};
`;

const IconStyles = css`
  max-width: 2em;
`;

const SuccessIcon = styled(Success)(IconStyles);
const ErrorIcon = styled(Error)(IconStyles);

const modalOptions = (status, requestType) => {
  switch (status) {
    case 'confirmRequest':
      return {
        icon: false,
        title: `simCardStateChangeRequest.${toLower(
          requestType
        )}.confirmRequest.title`,
        subtitle: 'simCardStateChangeRequest.defaultSubtitle',
        buttons: {
          primary: {
            label: `simCardStateChangeRequest.${toLower(
              requestType
            )}.confirmRequest.button`
          },
          secondary: {
            label: 'simCardStateChangeRequest.cancelLabel'
          }
        }
      };
    case 'successfulRequest':
      return {
        icon: <SuccessIcon />,
        title: `simCardStateChangeRequest.${toLower(
          requestType
        )}.successfulRequest.title`,
        subtitle: 'simCardStateChangeRequest.defaultSubtitle',
        hasTable: true,
        buttons: {
          primary: {
            label: `simCardStateChangeRequest.${toLower(
              requestType
            )}.successfulRequest.button`
          }
        }
      };
    case 'supplierError':
      return {
        icon: <ErrorIcon />,
        title: `simCardStateChangeRequest.${toLower(
          requestType
        )}.supplierError.title`,
        subtitle: `simCardStateChangeRequest.${toLower(
          requestType
        )}.supplierError.subtitle`,
        buttons: {
          primary: {
            label: `simCardStateChangeRequest.${toLower(
              requestType
            )}.supplierError.button`
          }
        }
      };
    case 'failedRequest':
      return {
        icon: <ErrorIcon />,
        title: 'simCardStateChangeRequest.failedRequest.title',
        subtitle: 'simCardStateChangeRequest.failedRequest.subtitle',
        buttons: {
          primary: {
            label: 'simCardStateChangeRequest.failedRequest.button'
          },
          secondary: {
            label: 'simCardStateChangeRequest.cancelLabel'
          }
        }
      };
    case 'alreadyRequested':
      return {
        icon: false,
        title: 'simCardStateChangeRequest.alreadyRequested.title',
        subtitle: 'simCardStateChangeRequest.defaultSubtitle',
        hasTable: true,
        buttons: {
          primary: {
            label: 'simCardStateChangeRequest.alreadyRequested.button'
          }
        }
      };
    default:
      return null;
  }
};

const ResponseModal = ({
  status,
  requestData,
  action,
  requestType,
  close,
  buttonLoading,
  translate,
  ...props
}) => {
  const [modalConfig, setModalConfig] = useState(
    modalOptions(status, requestType)
  );

  useEffect(() => {
    setModalConfig(modalOptions(status, requestType));
  }, [status]);

  const tableHeaders = [
    {
      children: translate(
        'simCardStateChangeRequest.tableHeadings.whoRequested'
      )
    },
    {
      children: translate('simCardStateChangeRequest.tableHeadings.dateAndTime')
    }
  ];

  const tableCells = [
    {
      cells: [
        requestData.email,
        requestData.datetime && formatDateTimeToShow(requestData.datetime)
      ]
    }
  ];

  const renderTable = () => (
    <TableContainer>
      <Table headers={tableHeaders} rows={tableCells} />
    </TableContainer>
  );

  const hasSecondarySubtitle = ['successfulRequest', 'alreadyRequested'];

  const hasTable = Boolean(
    modalConfig.hasTable && requestData.email && requestData.datetime
  );

  const primaryButtonConfig = {
    children: translate(
      modalOptions(status, requestType).buttons.primary.label
    ),
    onClick: status === 'successfulRequest' ? close : action,
    isLoading: buttonLoading,
    loadingLabel: 'Loading...',
    closeButtonLabel: 'Close modal',
    'data-testid': 'primary-button'
  };
  const secondaryButtonConfig = {
    children: modalOptions(status, requestType).buttons.secondary
      ? translate(modalOptions(status, requestType).buttons.secondary.label)
      : '',
    onClick: close,
    closeButtonLabel: 'Close modal',
    disabled: buttonLoading,
    'data-testid': 'secondary-button'
  };

  const buttonGroupActions = modalOptions(status, requestType).buttons.secondary
    ? { secondary: secondaryButtonConfig, primary: primaryButtonConfig }
    : { primary: primaryButtonConfig };

  return (
    <div id={status} {...props}>
      <ModalHeading>
        {modalConfig.icon && modalConfig.icon}
        {translate(modalConfig.title)}
      </ModalHeading>

      <Body noMargin>
        {`${translate(modalConfig.subtitle)} ${
          hasSecondarySubtitle.includes(status)
            ? translate('simCardStateChangeRequest.secondarySubtitle')
            : ''
        }`}
      </Body>

      {hasTable && renderTable()}

      <ButtonGroup
        align="right"
        style={{ marginTop: '1em' }}
        actions={buttonGroupActions}
      />
    </div>
  );
};

ResponseModal.propTypes = {
  status: PropTypes.oneOf([
    'confirmRequest',
    'successfulRequest',
    'failedRequest',
    'supplierError',
    'alreadyRequested'
  ]),
  requestData: PropTypes.object,
  action: PropTypes.func.isRequired,
  requestType: PropTypes.oneOf(['Reactivation', 'Block', 'Unblock']),
  close: PropTypes.func.isRequired,
  buttonLoading: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

ResponseModal.defaultProps = {
  status: 'confirmRequest',
  buttonLoading: false
};

export default ResponseModal;

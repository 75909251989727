import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { toLower } from 'lodash/fp';
import { Body } from '@sumup/circuit-ui';
import { Bluetooth } from 'assets/icons';
import SearchResult from 'contexts/SearchResult';
import ConnectionInfoCard from './components/ConnectionInfoCard';
import {
  formatData,
  isCloudwalk,
  euSuppliers
} from './CardReaderConnectionService';

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacings.peta};
    align-items: start;
    width: 100%;
  `};
`;

const CardsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: ${theme.spacings.mega} 0 ${theme.spacings.tera} 0;
    width: auto;
  `};
`;

const AlignedText = styled(Body)`
  display: flex;
  align-items: baseline;
`;

const BlueText = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.b500};
    font-weight: bold;
    margin-right: ${theme.spacings.bit};
  `};
`;

const BluetoothIcon = styled(Bluetooth)`
  ${({ theme }) => css`
    height: 1em;
    margin: 0 ${theme.spacings.bit} 0 0;
  `};
`;

const CardReaderConnection = ({ t: translate }) => {
  const {
    simCard,
    applicationType,
    lastConnection,
    lastTransaction
  } = useContext(SearchResult);

  const supplier = simCard.line.supplier.name || null;

  const formatedData = formatData(lastConnection, lastTransaction);

  return (
    <Container>
      <Body noMargin size="one">
        {translate('resultsInfo.cardReaderConnection.text')}
      </Body>

      <CardsContainer>
        <ConnectionInfoCard
          lastConnectionDate={formatedData.connection.lastDate}
          lastConnectionType={formatedData.connection.lastType}
          lastConnectionApn={lastConnection && lastConnection.apn}
          translate={translate}
        />

        {!isCloudwalk(applicationType) && (
          <ConnectionInfoCard
            cardType="transaction"
            lastTransactionSimcard={formatedData.transaction.lastSimCardTx}
            lastTransactionWifi={formatedData.transaction.lastWifiTx}
            translate={translate}
          />
        )}
      </CardsContainer>

      {supplier && euSuppliers.includes(toLower(supplier)) && (
        <AlignedText noMargin size="two">
          <BlueText>
            <BluetoothIcon />
            {` ${translate('resultsInfo.cardReaderConnection.important')}:`}
          </BlueText>
          {translate('resultsInfo.cardReaderConnection.bluetoothInfo')}
        </AlignedText>
      )}
    </Container>
  );
};

CardReaderConnection.propTypes = {
  t: PropTypes.func.isRequired
};

export default CardReaderConnection;

import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import { GoogleLogin } from '@react-oauth/google';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Redirect } from '@reach/router';
import { Card, Body, Headline, NotificationInline } from '@sumup/circuit-ui';
import { IconLogo } from 'assets/icons';
import { logUserIn, isUserLogged } from 'services/AuthenticationService';
import { ROUTES_PATH } from 'constants/routes';
import Loading from 'components/Loading';

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
`;

const Logo = styled(IconLogo)`
  ${({ theme }) => css`
    display: inline-block;
    fill: ${theme.colors.white};
    margin-bottom: 20px;
  `};
`;

const LoginCard = styled(Card)`
  width: 360px;
`;

const Login = ({ googleClientQuery, t: translate, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [errorMessage, setError] = useState('');

  useEffect(() => {
    async function checkUserLoggedIn() {
      setLoggedIn(await isUserLogged());
      setLoading(false);
    }
    checkUserLoggedIn();
  }, []);

  const previousRoute = window.sessionStorage.getItem('previousRoute');

  const handleLoginSuccess = async userData => {
    const { success } = await logUserIn(userData);
    if (success) {
      if (!isEmpty(previousRoute)) {
        props.navigate(previousRoute);
      } else {
        props.navigate(ROUTES_PATH.HOME);
      }
    } else {
      setError(`login.errors.access_denied`);
    }
  };

  const handleLoginFailure = error => setError(`login.errors.${error}`);

  const LoginScreen = () => (
    <Container {...props}>
      <Logo />

      <LoginCard>
        <Headline data-testid="login_title" size="four" noMargin>
          {translate('login.title')}
        </Headline>

        <Body noMargin>{translate('login.subtitle')}</Body>
        {!loading && (
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={handleLoginFailure}
          />
        )}

        {errorMessage && (
          <NotificationInline variant="alert">
            {translate(errorMessage)}
          </NotificationInline>
        )}
      </LoginCard>
    </Container>
  );

  return loading ? (
    <Loading />
  ) : loggedIn ? (
    <Redirect to={ROUTES_PATH.HOME} noThrow />
  ) : (
    <LoginScreen />
  );
};

Login.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func
};

export default Login;

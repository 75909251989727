import React from 'react';
import PropTypes from 'prop-types';
import { Button, useModal } from '@sumup/circuit-ui';
import StateChangeRequestModal from '../StateChangeRequestModal';

const StateChangeButton = ({
  children,
  iccid,
  lineState,
  userEmail,
  stateData,
  translate,
  ...props
}) => {
  const { setModal, removeModal } = useModal();

  const requestModal = () => {
    return (
      lineState &&
      setModal({
        children: (
          <StateChangeRequestModal
            iccid={iccid}
            lineState={lineState}
            userEmail={userEmail}
            onCancel={() => removeModal(requestModal())}
            translate={translate}
          />
        ),
        variant: 'immersive',
        closeButtonLabel: 'Close modal'
      })
    );
  };

  return (
    <Button
      onClick={() => {
        requestModal();
        return stateData;
      }}
      size="kilo"
      data-testid="line-state-button"
    >
      {children}
    </Button>
  );
};

StateChangeButton.propTypes = {
  children: PropTypes.node.isRequired,
  iccid: PropTypes.string.isRequired,
  lineState: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  stateData: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
};

export default StateChangeButton;

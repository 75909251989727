import { toLower } from 'lodash/fp';

export const stateColorsList = {
  active: 'primary',
  ready: 'primary',
  standby: 'neutral',
  suspended: 'orange',
  blocked: 'warning',
  cancelled: 'danger',
  notavailable: 'light',
  unknown: 'neutral'
};

export const cardColorList = {
  active: 'p500',
  ready: 'p500',
  standby: 'n300',
  suspended: 'o300',
  blocked: 'o300',
  cancelled: 'alert',
  notAvailable: 'n300',
  unknown: 'n300'
};

export const getLineState = iccid =>
  fetch(`/api/sim-cards/${iccid}/line-state`);

export const stateList = Object.keys(stateColorsList);

export const getStateData = state => {
  return stateList.includes(toLower(state))
    ? {
        color: stateColorsList[toLower(state)],
        cardColor: cardColorList[toLower(state)],
        label: state
      }
    : {
        color: stateColorsList.unknown,
        cardColor: cardColorList.unknown,
        label: 'unknown'
      };
};

const suppliersWithReactivation = ['arqia', 'linksolutions', 'telefonica'];
const suppliersWithCancelledReactivation = ['arqia'];
const suppliersWithBlock = ['soracom'];

export const shouldRenderStateChange = (lineState, lineSupplier, supplier) => {
  const suspendedReactivation =
    lineState.state === 'Suspended' &&
    suppliersWithReactivation.includes(toLower(lineSupplier));

  const cancelledReactivation =
    lineState.state === 'Cancelled' &&
    suppliersWithCancelledReactivation.includes(toLower(supplier));

  const activeBlock =
    lineState.state === 'Active' &&
    suppliersWithBlock.includes(toLower(lineSupplier));

  const blockedUnblock =
    lineState.state === 'Blocked' &&
    suppliersWithBlock.includes(toLower(lineSupplier));

  return (
    suspendedReactivation ||
    cancelledReactivation ||
    activeBlock ||
    blockedUnblock
  );
};

export const requestLineStateChange = async (iccid, request, email, token) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ email, request }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  };

  return fetch(`/api/sim-cards/${iccid}/state-change`, options);
};

import React, { useState, useContext } from 'react';
import { toLower } from 'lodash';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Card } from '@sumup/circuit-ui';
import { Link } from 'assets/icons';
import IconButton from 'components/IconButton';
import CopyButton from 'components/CopyButton';
import SearchResult from 'contexts/SearchResult';
import ResultHeading from '../ResultHeading';
import InfoItemsList from '../InfoItemsList';
import CardReaderDetails from '../CardReaderDetails';
import { mainImageSelector } from '../../SerialNumberSearchService';
import { CARD_SUPPLIERS_MAP } from './SearchResultCardService';

const CardResult = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 1000px;
  max-width: 100vw;
`;

const CardReaderContainer = styled.div`
  ${({ theme, statusColor }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    position: relative;
    border-top-left-radius: ${theme.spacings.bit};
    border-left: ${theme.spacings.byte} solid ${theme.colors[statusColor]};
    width: 100%;
    height: 100%;
    min-height: 230px;
    padding: 0px ${theme.spacings.giga};
  `};
`;

const ResultImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 235px;
  width: 30%;
  img {
    height: 235px;
    max-height: 100%;
  }
`;

const ResultContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-top: ${theme.spacings.peta};
    padding-bottom: ${theme.spacings.tera};
  `};
`;

const ButtonsContainer = styled.div`
  display: flex;
  & > button,
  & > div {
    margin-right: 0.5em;
  }
`;

const LinkIcon = styled(Link)`
  ${({ theme }) => css`
    margin-right: ${theme.spacings.bit};
    width: ${theme.spacings.mega};
    height: ${theme.spacings.mega};
  `};
`;

const DEFAULT_BORDER_COLOR = 'n500';

const CARD_SUPPLIERS_WITH_DETAILS = [
  'soracom',
  'linksolutions',
  'arqia',
  'vodafone',
  'telefonica'
];

const SearchResultCard = ({ sessionsResponse, t: translate }) => {
  const searchResult = useContext(SearchResult);
  const [stateColor, setStateColor] = useState('DEFAULT_BORDER_COLOR');

  const { supplier, lineSupplier } = searchResult;

  const SUPPLIER_DATA = CARD_SUPPLIERS_MAP[lineSupplier];

  const SUPPLIER_URL = SUPPLIER_DATA ? SUPPLIER_DATA.link : '#';

  return (
    <CardResult>
      <CardReaderContainer statusColor={stateColor || DEFAULT_BORDER_COLOR}>
        <ResultImage>{mainImageSelector(searchResult)}</ResultImage>

        <ResultContent>
          <ResultHeading translate={translate} getStateColor={setStateColor} />

          <InfoItemsList translate={translate} />

          <ButtonsContainer>
            <CopyButton
              bold
              data={window.location.href}
              icon={<LinkIcon />}
              label={translate('statusMessages.copyData.label.readerLink')}
              translate={translate}
            />

            {SUPPLIER_DATA && SUPPLIER_DATA.icon && (
              <IconButton
                icon={SUPPLIER_DATA.icon}
                href={SUPPLIER_DATA.link}
                isExternalLink
              />
            )}
          </ButtonsContainer>
        </ResultContent>
      </CardReaderContainer>

      {(searchResult.lastConnection ||
        CARD_SUPPLIERS_WITH_DETAILS.includes(toLower(lineSupplier))) && (
        <CardReaderDetails
          iccid={searchResult.iccid}
          sessions={sessionsResponse}
          supplierUrl={SUPPLIER_URL}
          translate={translate}
          noSupplier={!supplier}
        />
      )}
    </CardResult>
  );
};

SearchResultCard.propTypes = {
  sessionsResponse: PropTypes.shape({
    data: PropTypes.array,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  t: PropTypes.func.isRequired
};

export default SearchResultCard;

import React from 'react';
import PropTypes from 'prop-types';
import { getOr } from 'lodash/fp';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { indexOf, capitalize } from 'lodash';
import { Badge, Card } from '@sumup/circuit-ui';
import {
  ArrowCircle as ArrowCircleIcon,
  GreenCheck as GreenCheckIcon
} from 'assets/icons';
import { formatDateTimeToShow } from 'services/DateTimeFormatService';

const PUBLISHED_BORDER_COLOR = 'g700';
const UNPUBLISHED_BORDER_COLOR = 'n300';

const ReleaseCardItem = styled(Card)`
  ${({ theme, cardColor }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0.5em auto;
    border-width: 1px;
    border-top-left-radius: ${theme.borderRadius.mega};
    border-bottom-left-radius: ${theme.borderRadius.mega};
    border-left: ${theme.spacings.byte} solid ${theme.colors[cardColor]};
    padding: 0;
  `};
`;

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 130px;
    padding: 1.5em ${theme.spacings.giga};
  `};
`;

const CardTextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
`;

const RowsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const CardTitle = styled.div`
  display: flex;
  margin-bottom: 0.5em;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const SubtleText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.n700};
  `};
`;

const StatusBadge = styled(Badge)`
  margin-left: 1em;
`;

const TextColumns = styled.div`
  margin-right: 1em;
`;

const SeeMoreButton = styled.span`
  opacity: 1;
  transition: 0.35s ease-out 100ms;

  & :hover {
    cursor: pointer;
    opacity: 0.5;
    transition: 0.35s ease-in 100ms;
  }
`;

const SeeMoreIcon = styled(ArrowCircleIcon)`
  width: 47px;
  height: 47px;
`;

const ReleaseCard = ({ type, release, action, ...props }) => {
  const diff_stats = getOr(null, 'diff_stats', release);
  const filters = getOr(null, 'enabled_filters', release);
  const inserted_at = getOr(null, 'inserted_at', release);
  const internal_name = getOr(null, 'internal_name', release);
  const mandatory = getOr(null, 'mandatory', release);
  const published = getOr(null, 'published', release);
  const unskippable = getOr(null, 'unskippable', release);
  const updated_at = getOr(null, 'updated_at', release);
  const version = getOr(null, 'version', release);

  const renderAvailabilityFilters = () => (
    <p>
      <SubtleText>Availability filters: </SubtleText>
      {filters.map(filter =>
        filters.length > 1 && indexOf(filters, filter) !== filters.length - 1
          ? `${capitalize(filter)}, `
          : `${capitalize(filter)}`
      )}
    </p>
  );

  const renderMandatory = () => (
    <p>
      <SubtleText>Mandatory:</SubtleText> {capitalize(mandatory ? 'Yes' : 'No')}
    </p>
  );

  const renderUpdatedMode = () => (
    <p>
      <SubtleText>Updated mode:</SubtleText> {formatDateTimeToShow(updated_at)}
    </p>
  );

  const renderCreated = () => (
    <p>
      <SubtleText>Created:</SubtleText> {formatDateTimeToShow(inserted_at)}
    </p>
  );

  const renderTextFilesReleaseData = () => (
    <>
      <TextColumns>
        {renderAvailabilityFilters()}
        {renderMandatory()}
      </TextColumns>

      <TextColumns>
        {renderUpdatedMode()}
        {renderCreated()}
      </TextColumns>
    </>
  );

  const renderFirmwareReleaseData = () => (
    <>
      <TextColumns>
        <p>
          <SubtleText>Unskippable:</SubtleText> {capitalize(unskippable)}
        </p>
        {renderAvailabilityFilters()}
        {renderMandatory()}
      </TextColumns>

      <TextColumns>
        {renderUpdatedMode()}
        <p>
          <SubtleText>Diffs added/needed:</SubtleText>{' '}
          {`${diff_stats.added}/${diff_stats.needed}`}{' '}
          {diff_stats.added === diff_stats.needed && <GreenCheckIcon />}
        </p>
        {renderCreated()}
      </TextColumns>
    </>
  );

  return (
    <ReleaseCardItem
      cardColor={published ? PUBLISHED_BORDER_COLOR : UNPUBLISHED_BORDER_COLOR}
      data-testid="release_card"
      {...props}
    >
      <Container>
        <RowsContainer>
          <CardTextContent>
            <CardTitle>
              <p>
                <BoldText>Version {version}</BoldText>{' '}
                <SubtleText>({capitalize(internal_name)})</SubtleText>
              </p>

              {published ? (
                <StatusBadge variant="confirm">Published</StatusBadge>
              ) : (
                <StatusBadge>Unpublished</StatusBadge>
              )}
            </CardTitle>

            <RowsContainer>
              {type === 'textFiles'
                ? renderTextFilesReleaseData()
                : renderFirmwareReleaseData()}
            </RowsContainer>
          </CardTextContent>

          <SeeMoreButton onClick={() => action()}>
            <SeeMoreIcon />
          </SeeMoreButton>
        </RowsContainer>
      </Container>
    </ReleaseCardItem>
  );
};

ReleaseCard.propTypes = {
  type: PropTypes.oneOf(['textFiles', 'firmware']),
  release: PropTypes.object,
  action: PropTypes.func
};

ReleaseCard.defaultProps = {
  type: 'textFiles',
  action: () => false
};

export default ReleaseCard;

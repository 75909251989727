import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline, Body } from '@sumup/circuit-ui';

const SECTION_MAX_WIDTH = '550px';

const IMAGE_DEFAULT_WIDTH = '200px';

const DefaultSectionContainer = styled.div`
  ${({ sectionMaxWidth }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: ${sectionMaxWidth};
    text-align: center;
  `};
`;

const SectionImageContainer = styled.div`
  ${({ theme, imageMaxWidth }) => css`
    margin: ${theme.spacings.tera};
    width: calc(100% - (2 * ${theme.spacings.tera}));
    max-width: ${imageMaxWidth};
    height: auto;

    svg {
      width: 100%;
      height: auto;
    }
  `};
`;

const SectionHeading = styled(Headline)`
  ${({ headingMaxWidth }) => css`
    max-width: ${headingMaxWidth};
  `};
`;

const SectionText = styled(Body)`
  margin: 0.5em 0.5em 0 0.5em;
`;

const DefaultSection = ({
  sectionImage,
  sectionTitle,
  sectionText,
  sectionMaxWidth,
  headingMaxWidth,
  imageMaxWidth,
  children
}) => (
  <DefaultSectionContainer sectionMaxWidth={sectionMaxWidth}>
    <SectionImageContainer imageMaxWidth={imageMaxWidth}>
      {sectionImage}
    </SectionImageContainer>

    <SectionHeading
      noMargin
      as="h1"
      size="one"
      headingMaxWidth={headingMaxWidth}
    >
      {sectionTitle}
    </SectionHeading>

    {sectionText && (
      <SectionText noMargin size="one">
        {sectionText}
      </SectionText>
    )}
    {children && children}
  </DefaultSectionContainer>
);

DefaultSection.defaultProps = {
  sectionMaxWidth: SECTION_MAX_WIDTH,
  headingMaxWidth: 'auto',
  imageMaxWidth: IMAGE_DEFAULT_WIDTH
};

DefaultSection.propTypes = {
  sectionImage: PropTypes.element.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  sectionText: PropTypes.string,
  sectionMaxWidth: PropTypes.string,
  headingMaxWidth: PropTypes.string,
  imageMaxWidth: PropTypes.string
};

export default DefaultSection;

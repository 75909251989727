import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Tooltip as CuiTooltip } from '@sumup/circuit-ui';
import { IconTooltip } from 'assets/icons';

const TooltipContainer = styled.span`
  ${({ theme }) => css`
    position: relative;
    font-weight: normal;
    line-height: 0;
    color: ${theme.colors.n900};

    svg {
      margin-left: ${theme.spacings.bit};
    }
    & > div {
      visibility: visible;
      opacity: 1;
      min-width: 200px;
      text-transform: none;
      white-space: normal;
    }
  `}
`;

const CircuitTooltip = styled(CuiTooltip)`
  ${({ showTooltip }) => css`
    display: ${showTooltip ? 'inline-block' : 'none'};
  `};
`;

const Tooltip = ({ children, trigger, ...props }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <TooltipContainer
      {...props}
      onMouseOver={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onFocus={() => setShowTooltip(true)}
      onBlur={() => setShowTooltip(false)}
    >
      <CircuitTooltip
        data-testid="tooltip"
        align={Tooltip.RIGHT}
        position={Tooltip.BOTTOM}
        showTooltip={showTooltip}
      >
        {children}
      </CircuitTooltip>
      {trigger || <IconTooltip data-testid="tooltip-trigger" />}
    </TooltipContainer>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.node
};

export default Tooltip;

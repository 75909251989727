import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getOr } from 'lodash/fp';
import { navigate } from '@reach/router';
import { ROUTES_PATH } from 'constants/routes';
import ListReleasesPage from './components/ListReleasesPage';
import ReleasePage from './components/ReleasePage';

const TextFiles = ({ t: translate, location, active }) => {
  const [showReleasePage, setShowReleasePage] = useState(false);
  const [release, setRelease] = useState();

  const readerName = getOr(false, 'name', location.state);
  const readerPath = getOr(false, 'path', location.state);

  useEffect(() => setShowReleasePage(false), [active]);

  useEffect(() => {
    if (!readerName && !readerPath) {
      navigate(ROUTES_PATH.CARD_READERS_CONFIGURATION);
    }
  }, []);

  const navigateReleasePage = givenRelease => {
    setRelease(givenRelease);
    return setShowReleasePage(true);
  };

  return showReleasePage ? (
    <ReleasePage
      t={translate}
      readerPath={readerPath}
      release={release}
      location={location}
      goBack={() => setShowReleasePage(false)}
    />
  ) : (
    <ListReleasesPage
      t={translate}
      location={location}
      gotToReleasePage={navigateReleasePage}
    />
  );
};

TextFiles.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  active: PropTypes.bool
};

TextFiles.defaultProps = {
  active: true
};

export default TextFiles;

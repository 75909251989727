import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Card, Body } from '@sumup/circuit-ui';
import UploadFileButton from 'components/UploadFileButton';
import { LogisticsTruck } from 'assets/icons';

export const containersBaseStyles = `
  display: flex;
  width: 100%;
  height: 100%;
`;

const CardContainer = styled(Card)`
  ${({ theme }) => css`
    ${containersBaseStyles}
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: ${theme.spacings.bit};
    min-height: 200px;
    max-width: 350px;
    margin: 0 ${theme.spacings.peta} ${theme.spacings.peta} 0;
    padding: ${theme.spacings.tera} ${theme.spacings.giga}
      ${theme.spacings.tera} ${theme.spacings.giga};
  `};
`;

const CardContent = styled.div`
  ${containersBaseStyles}
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Body)`
  ${({ theme, styles }) => css`
    color: ${theme.colors[styles.textColor]};
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    text-align: center;

    & svg {
      margin-right: 0.5em;
    }
  `};
`;

const ButtonBar = styled.div`
  ${containersBaseStyles}
  justify-content: center;
  margin-top: 0.5em;
`;

const StyledUploadButton = styled(UploadFileButton)`
  margin-top: 0.75em;
  max-width: auto;
`;

const LogisticsTruckIcon = styled(LogisticsTruck)`
  display: block;
  width: '20px';
  height: '20px';
`;

const UploadCard = ({
  config,
  isLoading,
  cardTitle,
  cardSubtitle,
  action,
  t: translate,
  ...props
}) => (
  <CardContainer {...props}>
    <CardContent>
      <Title noMargin variant="highlight" size="one" styles={config.styles}>
        {!!config.icon && config.icon} {cardTitle}
      </Title>

      {cardSubtitle && (
        <Title noMargin size="one" styles={config.styles}>
          {cardSubtitle}
        </Title>
      )}

      <ButtonBar>
        <StyledUploadButton
          action={action}
          inputId="file-input"
          inputAccept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          buttonTestId="upload-card-button"
          buttonLabel={`simDeactivationReports.button.${config.button.label}.label`}
          isLoading={isLoading}
          isPrimary={config.button.primary}
        />
      </ButtonBar>
    </CardContent>
  </CardContainer>
);

UploadCard.propTypes = {
  config: PropTypes.shape({
    icon: PropTypes.node,
    button: PropTypes.shape({
      label: PropTypes.string.isRequired,
      primary: PropTypes.bool
    }),
    styles: PropTypes.shape({
      textColor: PropTypes.string,
      borderColor: PropTypes.string
    })
  }),
  isLoading: PropTypes.bool,
  cardTitle: PropTypes.string.isRequired,
  cardSubtitle: PropTypes.string,
  action: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

UploadCard.defaultProps = {
  config: {
    icon: <LogisticsTruckIcon />,
    button: {
      label: 'uploadFile',
      primary: true
    },
    styles: {
      textColor: 'n900',
      borderColor: 'transparent'
    }
  },
  isLoading: false
};

export default UploadCard;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getOr, isEmpty } from 'lodash/fp';
import { navigate } from '@reach/router';
import { ROUTES_PATH } from 'constants/routes';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Body, Select, Pagination, useModal } from '@sumup/circuit-ui';
import { Error as ErrorIcon } from 'assets/icons';
import MainContent from 'components/MainContent';
import Loading from 'components/Loading';
import DefaultSection from 'components/DefaultSection';
import UploadFileButton from 'components/UploadFileButton';
import UploadConfigurations from './components/UploadConfigurations';
import ReaderImageCard from '../ReaderImageCard';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import ConfirmationModal from '../UploadCarrierSuggestion/components/ConfirmationModal';
import { calculatePaginationItens } from '../../services/PaginationService';

import {
  getConfigurationFiles,
  mapCountriesAvailable,
  shouldRenderTable,
  filterByCountry,
  deleteFile,
  publishConfigurationFile
} from './EMVConfigurationsService';

const Container = styled.div`
  display: flex;
  margin-top: 1.5em;
`;

const ContentContainer = styled.div`
  flex-grow: 2;
`;

const FilterContainer = styled.span`
  display: flex;
  align-items: center;
  max-width: 450px;
  & p {
    margin-right: 0.5em;
  }
`;

const TableContainer = styled.div`
  & tr:hover {
    opacity: 0.65;
    transition: 0.35s ease-out;
  }
`;

const GoBackButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    max-width: 160px;
    margin: 0 0 ${theme.spacings.peta} 0;
  `};
`;

const StyledSelect = styled(Select)`
  & select:focus {
    box-shadow: 0 0 0 2px #ddd;
  }
  & span {
    visibility: hidden;
    margin-top: -2em;
    display: block;
  }
`;

const StyledPagination = styled(Pagination)`
  justify-content: end;

  & button,
  & button:hover {
    color: black;
    background-color: transparent;
    border-color: transparent;
  }
  & button:focus {
    box-shadow: 0 0 0 4px #ddd;
  }
  & select:focus {
    box-shadow: 0 0 0 2px #ddd;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
`;

const ErrorSectionContainer = styled.div`
  width: 100%;
  margin: auto;

  & > div {
    max-width: 100%;
  }
  & div > svg {
    width: 66px;
    height: 68px;
  }
`;

const SpaceBetweenHorizontalContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 2em;
`;

const EMVConfigurations = ({ t: translate, location }) => {
  const [loadingConfigurations, setLoadingConfigurations] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [configurations, setConfigurations] = useState([]);
  const [countriesFilter, setCountriesFilter] = useState([]);
  const [filterValue, setFilterValue] = useState(false);
  const [paginationContent, setPaginationContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadError, setDownloadError] = useState(false);
  const { setModal, removeModal } = useModal();

  const [uploadFiles, setUploadFiles] = useState([]);
  const [listConfigurations, setListConfigurations] = useState(true);

  const readerName = getOr(false, 'name', location.state);
  const readerPath = getOr(false, 'path', location.state);
  const allResultsOption = { label: 'All', value: 'ALL' };

  const FormModal = styled.div`
    ${({ theme }) => css`
      padding: ${theme.spacings.giga};
    `};
  `;

  const getConfigurations = () => {
    setLoadingConfigurations(true);

    getConfigurationFiles(readerPath)
      .then(response => (response.ok ? response.json() : setLoadingError(true)))
      .then(response => {
        setConfigurations(response);
        setCountriesFilter(mapCountriesAvailable(response));
        calculatePaginationItens(response, setPaginationContent);
      })
      .catch(() => setLoadingError(true))
      .finally(() => setLoadingConfigurations(false));
  };

  const confirmationModal = id => (
    <FormModal id="response-modal">
      <ConfirmationModal
        action={() => {
          deleteFile(id).then(response => {
            if (response.ok) {
              removeModal(confirmationModal());
              getConfigurations();
            }
            return response;
          });
        }}
        close={() => removeModal(confirmationModal())}
        translate={translate}
        text="cardReadersConfiguration.deleteModalText"
      />
    </FormModal>
  );

  const showConfirmationModal = id =>
    setModal({
      children: confirmationModal(id),
      variant: 'immersive',
      closeButtonLabel: 'Close modal'
    });

  const publishConfigurationModal = id => (
    <FormModal id="response-modal">
      <ConfirmationModal
        action={() => {
          publishConfigurationFile(id).then(response => {
            if (response.ok) {
              removeModal(publishConfigurationModal());
              getConfigurations();
            }
            return response;
          });
        }}
        close={() => removeModal(publishConfigurationModal())}
        translate={translate}
        text={'cardReadersConfiguration.publishModalText'}
      />
    </FormModal>
  );

  const showPublishConfigurationModal = (id, isPublished) =>
    !isPublished &&
    setModal({
      children: publishConfigurationModal(id),
      variant: 'immersive',
      closeButtonLabel: 'Close modal'
    });

  const renderConfigurationsTable = () => {
    if (paginationContent) {
      return shouldRenderTable(
        paginationContent[currentPage],
        getConfigurations,
        translate,
        readerName,
        setDownloadError,
        showConfirmationModal,
        showPublishConfigurationModal
      );
    }
    return false;
  };

  const shouldShowPagination = paginationContent.length > 1;

  const filterResults = filter => {
    // eslint-disable-next-line no-unused-expressions
    filter === allResultsOption.value
      ? calculatePaginationItens(configurations, setPaginationContent)
      : calculatePaginationItens(
          filterByCountry(filter, configurations),
          setPaginationContent
        );

    setFilterValue(filter);
    return setCurrentPage(1);
  };

  useEffect(() => {
    const controller = new AbortController();
    getConfigurations();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    filterResults(filterValue);
  }, [filterValue]);

  useEffect(() => {
    if (!readerName && !readerPath) {
      navigate(ROUTES_PATH.CARD_READERS_CONFIGURATION);
    }
  }, []);

  return (
    <>
      {loadingConfigurations ? (
        <Loading />
      ) : (
        <>
          <MainContent>
            {isEmpty(uploadFiles) && (
              <SpaceBetweenHorizontalContainer>
                <FilterContainer>
                  <Body noMargin>
                    {translate('cardReadersConfiguration.filter')}
                  </Body>
                  <StyledSelect
                    noMargin
                    label="Countries"
                    value={filterValue}
                    selected={filterValue}
                    options={[allResultsOption, ...countriesFilter]}
                    disabled={countriesFilter.length < 2 && !filterValue}
                    placeholder={translate(
                      'cardReadersConfiguration.configsTableHeaders.country'
                    )}
                    onChange={event => filterResults(event.target.value)}
                    style={{ minWidth: '161px' }}
                  />
                </FilterContainer>

                <UploadFileButton
                  action={items => {
                    setUploadFiles([...items]);
                    return setListConfigurations(false);
                  }}
                  buttonTestId="upload-files-button"
                  buttonLabel="Upload configurations"
                  buttonWidth="245px"
                  isPrimary
                  multipleFiles
                  t={translate}
                />
              </SpaceBetweenHorizontalContainer>
            )}

            <Container>
              <ReaderImageCard location={location} />

              {!isEmpty(uploadFiles) && (
                <UploadConfigurations
                  files={uploadFiles}
                  goBack={() => {
                    setListConfigurations(true);
                    return setUploadFiles([]);
                  }}
                  t={translate}
                />
              )}

              {listConfigurations && (
                <ContentContainer>
                  {loadingError ? (
                    <ErrorSectionContainer>
                      <DefaultSection
                        sectionImage={<ErrorIcon />}
                        sectionTitle={translate('genericError.title')}
                        sectionText={translate('genericError.text')}
                        titleSize="one"
                      />
                    </ErrorSectionContainer>
                  ) : (
                    <TableContainer>
                      {renderConfigurationsTable()}
                    </TableContainer>
                  )}
                </ContentContainer>
              )}
            </Container>
          </MainContent>

          <NavigationContainer>
            {!isEmpty(uploadFiles) ? (
              <GoBackButton
                onClick={() => {
                  setListConfigurations(true);
                  setPaginationContent([]);
                  setCountriesFilter([]);
                  setUploadFiles([]);

                  return getConfigurations();
                }}
              >
                {translate('cardReadersConfiguration.goBack')}
              </GoBackButton>
            ) : (
              <GoBackButton
                onClick={() => navigate(ROUTES_PATH.CARD_READERS_CONFIGURATION)}
              >
                {translate('cardReadersConfiguration.goBack')}
              </GoBackButton>
            )}

            {listConfigurations && shouldShowPagination && (
              <StyledPagination
                data-testid="pagination"
                label="Countries"
                previousLabel="Previous page"
                nextLabel="Next page"
                onChange={page => setCurrentPage(page)}
                currentPage={currentPage}
                totalPages={paginationContent.length - 1}
              />
            )}

            {downloadError && (
              <ErrorMessage
                t={translate}
                buttonLabel="Ok"
                action={() => setDownloadError(false)}
              />
            )}
          </NavigationContainer>
        </>
      )}
    </>
  );
};

EMVConfigurations.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

export default EMVConfigurations;

import { isEmpty } from 'lodash/fp';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

import dayjs from 'dayjs';

export const USER_DATA = 'USER_DATA';

const storeUserData = user => {
  if (!isEmpty(user)) {
    localStorage.setItem(USER_DATA, JSON.stringify(user));
  }
};

export const logUserIn = async googleLoginUserData => {
  const { credential: googleToken } = googleLoginUserData;

  // eslint-disable-next-line no-return-await
  return await fetch(`/auth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ googleToken })
  })
    .then(response => response.status === 200 && response.json())
    .then(user => {
      // eslint-disable-next-line camelcase
      const userData = jwt_decode(googleToken);

      // eslint-disable-next-line no-param-reassign
      user.imageUrl = userData.picture;

      return user;
    })
    .then(user => {
      storeUserData(user);
      return user;
    })
    .then(user => ({ success: true, user }))
    .catch(() => ({ success: false }));
};

export const getUserData = async () => {
  const userData = localStorage.getItem(USER_DATA);
  if (isEmpty(userData)) {
    const response = await fetch(`/auth`, {
      method: 'GET'
    });
    const user = response.status === 200 ? await response.json() : null;
    storeUserData(user);
    return user;
  }
  const user = JSON.parse(userData);
  const now = dayjs();
  const expiration = dayjs(user.exp * 1000);
  if (now.isAfter(expiration)) {
    localStorage.removeItem(USER_DATA);
    return null;
  }
  return user;
};

export const isUserLogged = async () => {
  const userData = await getUserData();
  return !isEmpty(userData);
};

export const logout = async callback => {
  localStorage.removeItem(USER_DATA);
  await fetch(`/auth`, {
    method: 'DELETE'
  });
  if (callback) {
    callback();
  }
};

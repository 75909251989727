import React, { Fragment, useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardFooter,
  CardHeader as CardHead,
  useModal,
  Body
} from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { isEmpty } from 'lodash/fp';
import { WarningYellow as WarningYellowIcon } from 'assets/icons';
import Loading from 'components/Loading';
import UserData from 'contexts/UserData';
import ResetHistoryEmpty from './components/ResetHistoryEmpty';
import ResetResponseModal from './components/ResetResponseModal';
import ResetsTable from './components/ResetsTable';
import ResetRequestForm from './components/ResetRequestForm';
import { getResets, requestReset } from './SimCardResetsService';

const SimCardResetsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.white};
  `};
`;

const CardHeader = styled.div`
  ${({ theme }) => css`
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    svg {
      margin-right: ${theme.spacings.kilo};
    }
  `}
`;

const ButtonPanel = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-top: 1px solid ${theme.colors.n300};
  `};
`;

const FormModal = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.giga};
  `};
`;

const CardPanel = styled(Card)`
  ${({ theme }) => css`
    padding: ${theme.spacings.giga} ${theme.spacings.peta};
  `};
`;

const SimCardResets = ({ iccid, t: translate, ...props }) => {
  const { token, email: userEmail } = useContext(UserData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resets, setResets] = useState([]);
  const { setModal, removeModal } = useModal();

  const { supplierName, applicationType } = props;

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    getResets(iccid, token)
      .then(response => response.json())
      .then(data => setResets(data))
      .catch(() => setError(true))
      .finally(() => setLoading(false));

    return () => controller.abort();
  }, [iccid]);

  const removePreviousModal = modalId => {
    const elem = document.getElementById(modalId);
    elem.parentNode.removeChild(elem);
  };

  const responseModal = status => (
    <Fragment>
      <FormModal id="response-modal">
        <ResetResponseModal
          status={status}
          onCancel={() => removeModal(responseModal())}
          action={() => removeModal(responseModal())}
          translate={translate}
        />
      </FormModal>
    </Fragment>
  );

  const resetAction = params => requestReset(iccid, params, token);

  const afterRequestCallback = status => {
    removePreviousModal('request-modal');

    return setModal({
      children: responseModal(status),
      variant: 'immersive',
      closeButtonLabel: 'Close modal'
    });
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    Sentry.captureException(error);
  }

  const requestModal = () =>
    setModal({
      children: (
        <Fragment>
          <FormModal id="request-modal">
            <ResetRequestForm
              userEmail={userEmail}
              onSubmit={resetAction}
              onCancel={() => removeModal(requestModal())}
              supplierName={supplierName}
              applicationType={applicationType}
              afterRequest={afterRequestCallback}
              translate={translate}
            />
          </FormModal>
        </Fragment>
      ),
      variant: 'immersive',
      closeButtonLabel: 'Close modal'
    });

  const ResetCallToAction = () => (
    <Button
      variant="primary"
      data-testid="reset-cta-button"
      onClick={() => requestModal()}
    >
      {translate('resultsInfo.simcardReset.button')}
    </Button>
  );

  return (
    <SimCardResetsContainer {...props}>
      <Fragment>
        {isEmpty(resets) ? (
          <Fragment>
            <ResetHistoryEmpty t={translate} />
            <ResetCallToAction />
          </Fragment>
        ) : (
          <Fragment>
            <ResetsTable simCardResets={resets} />
            <ButtonPanel>
              <CardPanel>
                <CardHead>
                  <CardHeader>
                    <WarningYellowIcon />{' '}
                    {translate('resultsInfo.simcardReset.resetWarning.title')}
                  </CardHeader>
                </CardHead>
                <Body noMargin>
                  {translate('resultsInfo.simcardReset.text')}
                </Body>
                <CardFooter>
                  <ResetCallToAction />
                </CardFooter>
              </CardPanel>
            </ButtonPanel>
          </Fragment>
        )}
      </Fragment>
    </SimCardResetsContainer>
  );
};

SimCardResets.propTypes = {
  iccid: PropTypes.string.isRequired,
  supplierName: PropTypes.string,
  applicationType: PropTypes.string,
  t: PropTypes.func.isRequired
};

export default SimCardResets;

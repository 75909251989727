import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useLocation, Redirect } from '@reach/router';
import { getUserData, logout } from 'services/AuthenticationService';
import { ROUTES_PATH } from 'constants/routes';
import UserData from 'contexts/UserData';
import Loading from 'components/Loading';

const ProtectedRoute = ({ role, component: Component, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    async function checkUserLoggedIn() {
      const user = await getUserData();
      if (user && user.roles && user.roles.includes(role)) {
        setUser(user);
      } else {
        await logout();
      }
      setLoading(false);
    }
    checkUserLoggedIn();
  }, []);

  return loading ? (
    <Loading />
  ) : !isEmpty(user) ? (
    <UserData.Provider value={user}>
      <Component {...props} />
    </UserData.Provider>
  ) : (
    <Redirect noThrow from={location.pathname} to={ROUTES_PATH.LOGIN} />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default ProtectedRoute;

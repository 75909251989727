import { compose } from 'lodash/fp';
import { graphql } from '@apollo/react-hoc';
import { withTranslation } from 'react-i18next';
import { GOOGLE_CLIENT_QUERY } from './LoginService';
import Login from './Login';

export default compose(
  withTranslation(),
  graphql(GOOGLE_CLIENT_QUERY, {
    name: 'googleClientQuery'
  })
)(Login);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, useModal } from '@sumup/circuit-ui';
import { EditIcon, Error as ErrorIcon } from 'assets/icons';
import BlackButton from 'components/BlackButton';
import DefaultSection from 'components/DefaultSection';
import MainContent from 'components/MainContent';
import AddTextFileRelease from '../AddTextFileRelease';
import AddResourceModal from '../AddResourceModal';
import ReaderImageCard from '../../../ReaderImageCard';
import TextFilesResources from '../TextFilesResources';
import TogglePublishRelease from '../TogglePublishRelease';
import {
  getReleaseLocales,
  getResourceTypes,
  updateRelease
} from './ReleasePageService';

const Container = styled.div`
  display: flex;
  margin-top: 1.5em;
`;

const ContentContainer = styled.div`
  flex-grow: 2;
`;

const ErrorSectionContainer = styled.div`
  width: 100%;
  margin: auto;

  & > div {
    max-width: 100%;
  }
  & div > svg {
    width: 66px;
    height: 68px;
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 2em;
`;

const SquareButton = styled(BlackButton)`
  border-radius: 0;
  border-color: #0f131a;
  cursor: auto;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > *:nth-of-type(n) {
    margin-left: 0.75em;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
`;

const GoBackButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    max-width: 160px;
    margin: 0 0 ${theme.spacings.peta} 0;
  `};
`;

const ReleasePage = ({
  t: translate,
  readerPath,
  release,
  location,
  goBack
}) => {
  const [loadingError, setLoadingError] = useState(false);
  const [listResources, setListResources] = useState(true);
  const [resourceTypesData, setResourceTypesData] = useState([]);
  const [localesData, setLocalesData] = useState([]);
  const { setModal, removeModal } = useModal();

  const { version: releaseVersion, published: isPublished } = release;

  useEffect(() => {
    getReleaseLocales(
      readerPath,
      releaseVersion,
      setLoadingError,
      setLocalesData
    );
    getResourceTypes(readerPath, setLoadingError, setResourceTypesData);
  }, []);

  const addResourceModal = () => (
    <AddResourceModal
      data-testid="add-resource-modal"
      action={() => true}
      reader={readerPath}
      version={releaseVersion}
      resourceTypes={resourceTypesData}
      locales={localesData}
      close={() => removeModal(addResourceModal())}
      translate={translate}
    />
  );

  const showAddResourceModal = () =>
    setModal({
      children: addResourceModal,
      variant: 'immersive',
      closeButtonLabel: 'Close modal'
    });

  const togglePublishModal = () => (
    <TogglePublishRelease
      data-testid="toggle-publish-modal"
      isPublished={isPublished}
      data={release}
      reader={readerPath}
      close={() => removeModal(togglePublishModal())}
      goBack={() => removeModal(togglePublishModal())}
      translate={translate}
    />
  );

  const renderTogglePublishModal = () =>
    setModal({
      children: togglePublishModal,
      variant: 'immersive',
      closeButtonLabel: 'Close modal'
    });

  const renderListResources = () =>
    loadingError ? (
      <ErrorSectionContainer>
        <DefaultSection
          sectionImage={<ErrorIcon />}
          sectionTitle={translate('genericError.title')}
          sectionText={translate('genericError.text')}
          titleSize="one"
        />
      </ErrorSectionContainer>
    ) : (
      <TextFilesResources
        t={translate}
        reader={readerPath}
        version={releaseVersion}
        unmountAction={() => goBack()}
      />
    );

  const EditRelease = props => <AddTextFileRelease {...props} />;

  return (
    <>
      <MainContent>
        <ActionButtonsContainer>
          <div>
            <SquareButton isPrimary>Resources</SquareButton>
            <SquareButton disabled>Filters</SquareButton>
          </div>

          <ButtonsContainer>
            <BlackButton
              onClick={() => renderTogglePublishModal()}
              data-testid="unpublish-release-button"
              buttonWidth="225px"
              disabled={loadingError || !listResources}
            >
              {isPublished ? 'Unpublish release' : 'Publish release'}
            </BlackButton>

            <BlackButton
              onClick={() => showAddResourceModal()}
              data-testid="add-resource-button"
              buttonWidth="225px"
              isPrimary
              disabled={loadingError || !listResources}
            >
              Add resource
            </BlackButton>

            <Button
              onClick={() => setListResources(false)}
              disabled={loadingError || !listResources}
              data-testid="edit-button"
              style={{
                background: 'none',
                border: 'none',
                marginLeft: '-1em'
              }}
            >
              <EditIcon
                style={{
                  alignSelf: 'center',
                  maxHeight: '48px',
                  opacity: '.7'
                }}
              />
            </Button>
          </ButtonsContainer>
        </ActionButtonsContainer>

        <Container>
          <ReaderImageCard location={location} />

          <ContentContainer>
            {listResources ? (
              renderListResources()
            ) : (
              <EditRelease
                data-testid="edit-release-form"
                updateData={release}
                translate={translate}
                action={data => updateRelease(readerPath, releaseVersion, data)}
                goBack={() => setListResources(true)}
              />
            )}
          </ContentContainer>
        </Container>
      </MainContent>

      <NavigationContainer>
        <GoBackButton onClick={() => goBack()}>
          {translate('cardReadersConfiguration.goBack')}
        </GoBackButton>
      </NavigationContainer>
    </>
  );
};

ReleasePage.propTypes = {
  t: PropTypes.func.isRequired,
  readerPath: PropTypes.string.isRequired,
  release: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired
};

export default ReleasePage;

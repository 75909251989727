import React from 'react';
import copy from 'copy-text-to-clipboard';
import { IconCopy } from 'assets/icons';

export const copyData = (data, translate) => {
  const STATUS_MESSAGE = {
    sucess: translate(`statusMessages.copyData.sucess`),
    error: translate(`statusMessages.copyData.error`)
  };
  const MESSAGE_OBJECT = {
    icon: <IconCopy />,
    animationDuration: 5000,
    message: ''
  };
  // eslint-disable-next-line no-unused-expressions
  copy(data)
    ? (MESSAGE_OBJECT.message = STATUS_MESSAGE.sucess)
    : (MESSAGE_OBJECT.message = STATUS_MESSAGE.error);

  return MESSAGE_OBJECT;
};

export const copyContent = (dataToCopy, setStatusMessage, translate) =>
  setStatusMessage(copyData(dataToCopy, translate));

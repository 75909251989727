import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Error as ErrorIcon } from 'assets/icons';
import BlackButton from 'components/BlackButton';
import DefaultSection from 'components/DefaultSection';

const ErrorsContainer = styled.div`
  text-align: left;
  margin-top: 1.5em;
  padding-bottom: 1em;

  & p {
    font-size: 0.85em;
  }
  & span {
    font-weight: bold;
  }
`;

const UploadReleaseError = ({ translate, errors, close }) => {
  const fieldLabelMap = {
    version: 'Version',
    internal_name: 'Internal name',
    notes: 'Release notes',
    devide_update: 'Device update',
    enabled_filters: 'Availability filters'
  };

  return (
    <DefaultSection
      titleSize="one"
      sectionImage={<ErrorIcon />}
      sectionTitle={translate('genericError.title')}
      sectionText={
        errors
          ? 'The following fields contain errors: '
          : translate('genericError.text')
      }
    >
      <ErrorsContainer>
        {Object.keys(errors).map(item =>
          errors[item].map((error, itemKey) => (
            <p key={itemKey}>
              <span>{fieldLabelMap[item]}: </span> {error}
            </p>
          ))
        )}
      </ErrorsContainer>

      <BlackButton onClick={close} data-testid="cancel-button" size="kilo">
        Cancel
      </BlackButton>
    </DefaultSection>
  );
};

UploadReleaseError.propTypes = {
  translate: PropTypes.func.isRequired,
  errors: PropTypes.object,
  close: PropTypes.func.isRequired
};

UploadReleaseError.defaultProps = {
  errors: []
};

export default UploadReleaseError;

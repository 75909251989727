import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from '@sumup/circuit-ui';

const colorStyles = isPrimary => css`
  background-color: ${isPrimary ? '#0f131a' : '#fff'};
  color: ${isPrimary ? '#fff' : '#0f131a'};
`;

const baseStyles = (buttonWidth, isPrimary) => css`
  width: ${buttonWidth};
  max-width: 100%;
  ${colorStyles(isPrimary)}
  transition: 0.35s ease-out 100ms;

  &:hover {
    ${colorStyles(isPrimary)}
    opacity: .85;
    transition: 0.35s ease-out 100ms;
  }
`;
const StyledButton = styled(Button)`
  ${({ buttonWidth, isPrimary }) => css`
    ${baseStyles(buttonWidth, isPrimary)}
  `}
`;

const BlackButton = ({ buttonWidth, isPrimary, ...props }) => (
  <StyledButton buttonWidth={buttonWidth} isPrimary={isPrimary} {...props} />
);

BlackButton.propTypes = {
  buttonWidth: PropTypes.string,
  isPrimary: PropTypes.bool
};

BlackButton.defaultProps = {
  buttonWidth: 'auto',
  isPrimary: false
};

export default BlackButton;

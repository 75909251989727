import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline, Body } from '@sumup/circuit-ui';
import SearchResult from 'contexts/SearchResult';
import Tooltip from 'components/Tooltip';
import LineState from 'features/LineState';
import { getEmptyValues } from './ResultHeadingService';

const ResultHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CarrierLabel = styled(Body)`
  ${({ theme }) => css`
    color: ${theme.colors.n500};
  `};
`;

const CarrierHeading = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: ${theme.spacings.byte};
    align-items: center;
    & > span:nth-of-type(odd) {
      margin-left: ${theme.spacings.kilo};
    }
  `};
`;

const IccidHeading = styled(Body)`
  display: flex;
  text-transform: none;
  margin-bottom: 0.5em;
`;

const IccidHeadingData = styled(Body)`
  margin-right: 0.25em;
  margin-left: 0.25em;
`;

const SupplierInfo = styled.div`
  display: flex;
`;

const ResultHeading = ({ getStateColor, translate }) => {
  const searchResult = useContext(SearchResult);

  const { iccid, imsi, carrier, supplier, lineSupplier } = searchResult;

  const hasIccid = Boolean(iccid);

  const hasLineSupplier = lineSupplier && lineSupplier !== supplier;

  const { emptyIccid, emptyImsi, emptyCarrier, emptySupplier } = getEmptyValues(
    hasIccid
  );

  const renderTooltip = info => <Tooltip>{translate(info)}</Tooltip>;

  const supplierEmpty = !hasIccid ? (
    ''
  ) : (
    <Fragment>
      <span>{`( ${translate(emptySupplier.label)}`}</span>
      {renderTooltip(emptySupplier.tooltip)}
      <span>{` )`}</span>
    </Fragment>
  );

  const supplierValue = supplier
    ? `(${translate('helpers.from')} ${supplier})`
    : supplierEmpty;

  const renderLineState = () => (
    <LineState
      data-testid="line-state"
      iccid={iccid}
      getStateColor={getStateColor}
      translate={translate}
    />
  );

  return (
    <ResultHeadingContainer>
      <CarrierLabel noMargin as="span" data-testid="field-carrier-label">
        {translate(`resultsInfo.carrier.label`)}:
      </CarrierLabel>

      <CarrierHeading data-testid="field-carrier-value">
        <Headline noMargin as="h1" size="three">
          {carrier || translate(emptyCarrier.label)}
          {!carrier && renderTooltip(emptyCarrier.tooltip)}
        </Headline>

        {renderLineState()}
      </CarrierHeading>

      <IccidHeading as="h3" size="two" data-testid="field-iccid" noMargin>
        {hasIccid && (
          <Body variant="highlight" as="span" size="two" noMargin>
            {translate('resultsInfo.iccid.label')}:
          </Body>
        )}

        <IccidHeadingData as="span" size="two" noMargin>
          {iccid || emptyIccid.label}
        </IccidHeadingData>

        <SupplierInfo data-testid="field-supplier">
          {supplierValue}
        </SupplierInfo>
      </IccidHeading>

      <IccidHeading as="h3" size="two" data-testid="field-imsi" noMargin>
        {hasIccid && (
          <Body variant="highlight" as="span" size="two" noMargin>
            IMSI:
          </Body>
        )}

        <IccidHeadingData as="span" size="two" noMargin>
          {imsi || emptyImsi.label}
        </IccidHeadingData>

        {hasLineSupplier && (
          <SupplierInfo data-testid="field-line-supplier">
            {`(${translate('helpers.from')} ${lineSupplier})`}
          </SupplierInfo>
        )}
      </IccidHeading>
    </ResultHeadingContainer>
  );
};
ResultHeading.propTypes = {
  getStateColor: PropTypes.func,
  translate: PropTypes.func.isRequired
};

export default ResultHeading;

import React, { useState, useEffect, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { navigate } from '@reach/router';
import { ROUTES_PATH } from 'constants/routes';
import {
  Button,
  Headline,
  Pagination,
  Selector,
  Spinner,
  useModal
} from '@sumup/circuit-ui';
import ErrorMessage from 'components/ErrorMessage';
import UserData from 'contexts/UserData';
import FileEntriesTable from '../FileEntriesTable';
import ConfirmationModal from '../ConfirmationModal';
import SuccessfulRequestCard from '../SuccessfulRequestCard';
import { requestUploadNewRules, flatMap } from './CheckInputtedDataService';

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const BackButton = styled(Button)`
  ${({ theme }) => css`
    max-width: 220px;
    position: relative;
    margin-top: ${theme.spacings.exa};
    margin-bottom: ${theme.spacings.exa};
  `};
`;

const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

const SelectorGroup = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: ${theme.spacings.giga};
    & > label {
      margin-right: 0.5em;
    }
  `};
`;

const FormModal = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.giga};
  `};
`;

const CheckInputtedDataPage = ({
  validEntries,
  invalidEntries,
  goBackAction,
  t: translate
}) => {
  const { token } = useContext(UserData);
  const [showValidEntries, setShowValidEntries] = useState(true);
  const [paginationContent, setPaginationContent] = useState({
    valid: [],
    invalid: []
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalValidItems, setTotalValidItems] = useState(0);
  const [totalInvalidItems, setTotalInvalidItems] = useState(0);
  const [validItems, setValidItems] = useState([]);
  const [requestError, setRequestError] = useState(false);
  const [requestSuccessful, setRequestSuccessful] = useState(false);
  const { setModal, removeModal } = useModal();

  const ITEMS_PER_PAGE = 10;

  const calculatePaginationItens = (amount, isValid = true) => {
    const content = [[]];
    let initialIndex = 0;
    let finalIndex = 9;
    const sortedContent = sortBy(amount, ['line']);

    const setContentValues = () => {
      const lastIndex = sortedContent[finalIndex + 1]
        ? finalIndex + 1
        : sortedContent.length;

      content.push(sortedContent.slice(initialIndex, lastIndex));
      initialIndex += ITEMS_PER_PAGE;
      finalIndex += ITEMS_PER_PAGE;
    };

    const loop = count => {
      if (count <= Math.ceil(sortedContent.length / ITEMS_PER_PAGE)) {
        setContentValues();
        return loop(count + 1);
      }
      return true;
    };

    if (loop(1)) {
      isValid
        ? setTotalValidItems(sortedContent.length)
        : setTotalInvalidItems(sortedContent.length);

      return content;
    }
  };

  const shouldShowPagination =
    (showValidEntries && paginationContent.valid.length > 1) ||
    (!showValidEntries && paginationContent.invalid.length > 1);

  useEffect(() => {
    const valid = calculatePaginationItens(validEntries);
    const invalid = calculatePaginationItens(invalidEntries, false);

    setValidItems(flatMap(valid, item => item));

    if (valid.length <= 1) {
      setShowValidEntries(false);
    }
    return setPaginationContent({ valid, invalid });
  }, [validEntries, invalidEntries]);

  const handleUploadRequest = () =>
    requestUploadNewRules(validItems, token)
      .then(response => {
        if (!response.ok && response.status !== 201) {
          return setRequestError(true);
        }
        setRequestSuccessful(true);
        return response;
      })
      .catch(() => setRequestError(true))
      .finally(() => removeModal(confirmationModal()));

  const renderFileEntriesTable = () =>
    totalValidItems < 1 && totalInvalidItems < 1 ? (
      <Spinner />
    ) : (
      <FileEntriesTable
        data-testId="file-entries-table"
        areValidEntries={showValidEntries}
        entriesRows={
          showValidEntries
            ? paginationContent.valid[currentPage]
            : paginationContent.invalid[currentPage]
        }
      />
    );

  const confirmationModal = () => (
    <FormModal id="response-modal">
      <ConfirmationModal
        action={handleUploadRequest}
        close={() => removeModal(confirmationModal())}
        translate={translate}
      />
    </FormModal>
  );

  const showConfirmationModal = () =>
    setModal({
      children: confirmationModal(),
      variant: 'immersive',
      closeButtonLabel: 'Close modal'
    });

  return (
    <PageContainer>
      <BackButton
        size="kilo"
        data-testid="back-button"
        onClick={
          requestSuccessful
            ? () =>
                navigate(ROUTES_PATH.ADMIN.CARRIER_SUGGESTION, {
                  state: {
                    currentPageKey: 'carrier-suggestion'
                  }
                })
            : goBackAction
        }
      >
        {requestSuccessful
          ? translate('uploadCarrierSuggestion.backButton')
          : translate('checkInputtedDataPage.backButton')}
      </BackButton>

      {requestSuccessful ? (
        <SuccessfulRequestCard />
      ) : (
        <Fragment>
          <HeadingContainer>
            <Headline size="three" noMargin>
              {translate('checkInputtedDataPage.title')}
            </Headline>

            <Button
              variant="primary"
              disabled={totalValidItems < 1}
              data-testid="publish-changes-button"
              onClick={showConfirmationModal}
            >
              {translate('checkInputtedDataPage.publishCTA.label')}
            </Button>
          </HeadingContainer>

          <SelectorGroup>
            <Selector
              noMargin
              size="kilo"
              checked={showValidEntries}
              disabled={validEntries.length === 0}
              onClick={() => {
                setShowValidEntries(true);
                setCurrentPage(1);
              }}
            >
              {`${validEntries.length} ${translate(
                'checkInputtedDataPage.validEntriesTitle'
              )}`}
            </Selector>

            <Selector
              noMargin
              size="kilo"
              checked={!showValidEntries}
              disabled={invalidEntries.length === 0}
              onClick={() => {
                setShowValidEntries(false);
                setCurrentPage(1);
              }}
            >
              {`${invalidEntries.length} ${translate(
                'checkInputtedDataPage.invalidEntriesTitle'
              )}`}
            </Selector>
          </SelectorGroup>

          {renderFileEntriesTable()}

          {shouldShowPagination && (
            <Pagination
              label="File entries"
              previousLabel="Previous page"
              nextLabel="Next page"
              data-testid="pagination"
              currentPage={currentPage}
              totalPages={
                showValidEntries
                  ? paginationContent.valid.length - 1
                  : paginationContent.invalid.length - 1
              }
              onChange={setCurrentPage}
            />
          )}

          {requestError && (
            <ErrorMessage
              action={() => {
                setRequestError(false);
                return showConfirmationModal();
              }}
              translate={translate}
            />
          )}
        </Fragment>
      )}
    </PageContainer>
  );
};

CheckInputtedDataPage.propTypes = {
  validEntries: PropTypes.array.isRequired,
  invalidEntries: PropTypes.array.isRequired,
  goBackAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default CheckInputtedDataPage;

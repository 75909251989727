import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Card,
  Grid,
  Row,
  Col,
  Headline,
  Input,
  Label,
  RadioButtonGroup,
  SelectorGroup
} from '@sumup/circuit-ui';
import { AddIcon, EditPencil, Success as SuccessIcon } from 'assets/icons';
import BlackButton from 'components/BlackButton';
import DefaultSection from 'components/DefaultSection';
import UploadReleaseError from '../UploadReleaseError';
import {
  availabilityFiltersOptions,
  toggleAvailabilityFiltersValue,
  handleSubmit
} from './AddTextFileReleaseService';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  border-width: 1px;
  border-radius: 2px;
  padding: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin-top: 2.5em;

  & > button {
    margin-left: 0.75em;
    padding: 0.5em 2.5em;
  }
`;

const StyledInput = styled(Input)`
  box-shadow: 0 0 0 1px #ccc;
`;

const InputLabel = styled.div`
  & h3 {
    font-weight: bold;
    font-size: 1.5em;
  }
  & h4 {
    font-weight: bold;
    font-size: 1.25em;
    margin-top: 3em;
  }
  & p {
    margin-top: 0.5em;
    font-size: 13px;
    color: #666;
  }
  margin: 2em 0 1.15em;
`;

const AvailabilityFiltersButtons = styled(SelectorGroup)`
  flex-direction: column;
  & > div {
    width: 100%;
    margin-bottom: 0.85em;
  }
  & input ~ label > div > div > svg {
    visibility: hidden;
    margin-right: 0.5em;
  }
  & input:checked ~ label > div > div > svg {
    visibility: visible;
  }
`;

const ResponseMessageContainer = styled.div`
  width: 100%;
  margin: auto;

  & > div {
    max-width: 100%;
  }
  & div > svg {
    width: 66px;
    height: 68px;
  }
`;

const AddTextFileRelease = ({
  translate,
  action,
  goBack,
  updateData,
  ...props
}) => {
  const isUpdate =
    Object.keys(updateData).length !== 0 && updateData.constructor === Object;

  const [submitting, setSubmitting] = useState(false);
  const [versionValue, setVersionValue] = useState({
    value: isUpdate ? updateData.version : '',
    hasError: false
  });
  const [internalNameValue, setInternalNameValue] = useState({
    value: isUpdate ? updateData.internal_name : '',
    hasError: false
  });
  const [releaseNotesValue, setReleaseNotesValue] = useState({
    value: isUpdate ? updateData.notes : '',
    hasError: false
  });
  const [deviceUpdateValue, setDeviceUpdateValue] = useState({
    value: isUpdate ? updateData.mode : '',
    hasError: false
  });
  const [availabilityFiltersValue, setAvailabilityFiltersValue] = useState({
    value: isUpdate ? updateData.enabled_filters : ['allow'],
    hasError: false
  });
  const [failedUpload, setFailedUpload] = useState(false);
  const [uploadErrors, setUploadErrors] = useState('');
  const [successfulUpload, setSuccessfulUpload] = useState(false);

  const setUploadData = () => {
    return {
      version: versionValue.value,
      internal_name: internalNameValue.value,
      notes: releaseNotesValue.value,
      mode: deviceUpdateValue.value,
      enabled_filters: availabilityFiltersValue.value
    };
  };

  const submit = () =>
    handleSubmit(
      action,
      versionValue,
      setVersionValue,
      releaseNotesValue,
      setReleaseNotesValue,
      setSubmitting,
      setUploadData,
      setUploadErrors,
      setSuccessfulUpload,
      setFailedUpload,
      isUpdate
    );

  return (
    <>
      {failedUpload ? (
        <ResponseMessageContainer>
          <UploadReleaseError
            translate={translate}
            errors={uploadErrors}
            close={goBack}
          />
        </ResponseMessageContainer>
      ) : successfulUpload ? (
        <ResponseMessageContainer>
          <DefaultSection
            sectionImage={<SuccessIcon />}
            sectionTitle="Success"
            sectionText={
              isUpdate
                ? 'Resource updated successfully.'
                : 'New resource created successfully.'
            }
            titleSize="one"
          />
        </ResponseMessageContainer>
      ) : (
        <div {...props}>
          <Headline noMargin as="h1">
            {isUpdate ? <EditPencil /> : <AddIcon />}
            {isUpdate ? ` Edit release` : ` Add new release`}
          </Headline>
          <StyledCard style={{ marginTop: '1.5em' }}>
            <Grid>
              <Row span={{ default: 12 }}>
                <Col span={{ default: 6 }}>
                  <Label htmlFor="version">
                    <InputLabel>
                      <h3>Version</h3>
                    </InputLabel>

                    <StyledInput
                      noMargin
                      showValid
                      hideLabel
                      required
                      label="version"
                      id="version"
                      value={versionValue.value}
                      as="input"
                      name="version"
                      data-testid="version-input"
                      placeholder={versionValue.value}
                      invalid={versionValue.hasError}
                      onChange={event =>
                        setVersionValue({
                          value: event.target.value,
                          hasError: false
                        })
                      }
                      validationHint={
                        versionValue.hasError ? 'This field is mandatory' : ''
                      }
                    />
                  </Label>

                  <Label htmlFor="internalName">
                    <InputLabel>
                      <h3>Internal name</h3>
                    </InputLabel>

                    <StyledInput
                      noMargin
                      hideLabel
                      label="internalName"
                      id="internalName"
                      as="input"
                      name="internalName"
                      value={internalNameValue.value}
                      data-testid="internal-name-input"
                      placeholder={internalNameValue.value}
                      invalid={internalNameValue.hasError}
                      onChange={event =>
                        setInternalNameValue({
                          value: event.target.value,
                          hasError: false
                        })
                      }
                    />
                  </Label>

                  <Label htmlFor="releaseNotes">
                    <InputLabel>
                      <h3>Release notes</h3>
                      <p>
                        Describe new features, fixes, and improvements this
                        release contains.
                      </p>
                    </InputLabel>

                    <StyledInput
                      noMargin
                      showValid
                      hideLabel
                      required
                      label="releaseNotes"
                      id="releaseNotes"
                      as="input"
                      name="releaseNotes"
                      value={releaseNotesValue.value}
                      data-testid="release-notes-input"
                      maxLength="140"
                      placeholder={releaseNotesValue.value}
                      invalid={releaseNotesValue.hasError}
                      onChange={event =>
                        setReleaseNotesValue({
                          value: event.target.value,
                          hasError: false
                        })
                      }
                      validationHint={
                        releaseNotesValue.hasError
                          ? 'This field is mandatory'
                          : ''
                      }
                    />
                  </Label>

                  <Label htmlFor="deviceUpdate">
                    <InputLabel>
                      <h3>Device update</h3>
                      <p>
                        Configure how the device will apply this release
                        version. Update runs in foreground (blocking) or
                        background (non-blocking).
                      </p>
                    </InputLabel>

                    <RadioButtonGroup
                      hideLabel
                      id="deviceUpdate"
                      data-testid="device-update-select"
                      name="deviceUpdate"
                      label="Device update"
                      value={deviceUpdateValue.value || 'background'}
                      defaultValue="background"
                      options={[
                        {
                          label: 'Background',
                          value: 'background'
                        },
                        {
                          label: 'Foreground',
                          value: 'foreground'
                        }
                      ]}
                      onChange={event =>
                        setDeviceUpdateValue({
                          value: event.target.value,
                          hasError: false
                        })
                      }
                    />
                  </Label>
                </Col>

                <Col span={{ default: 6 }}>
                  <Label htmlFor="availabilityFilters">
                    <InputLabel>
                      <h3>Version Delivery</h3>
                      <p>
                        Configure how the backend will serve this release
                        version to devices.
                      </p>
                    </InputLabel>

                    <InputLabel>
                      <h4>Availability filters</h4>
                    </InputLabel>

                    <AvailabilityFiltersButtons
                      hideLabel
                      id="availabilityFilters"
                      name="availabilityFilters"
                      label="Availability filters"
                      value={availabilityFiltersValue.value}
                      defaultValue={availabilityFiltersValue.value}
                      stretch={true}
                      options={availabilityFiltersOptions}
                      onChange={event =>
                        setAvailabilityFiltersValue({
                          value: toggleAvailabilityFiltersValue(
                            availabilityFiltersValue.value,
                            event.target.value
                          ),
                          hasError: false
                        })
                      }
                      onSubmit={event =>
                        setAvailabilityFiltersValue({
                          value: toggleAvailabilityFiltersValue(
                            availabilityFiltersValue.value,
                            event.target.value
                          ),
                          hasError: false
                        })
                      }
                      multiple
                    />
                  </Label>
                </Col>
              </Row>
            </Grid>
          </StyledCard>

          <ButtonsContainer>
            <BlackButton
              onClick={goBack}
              data-testid="cancel-button"
              size="kilo"
            >
              Cancel
            </BlackButton>

            <BlackButton
              onClick={() => submit()}
              data-testid="save-button"
              isLoading={submitting}
              loadingLabel="Submitting"
              size="kilo"
              isPrimary
            >
              Save
            </BlackButton>
          </ButtonsContainer>
        </div>
      )}
    </>
  );
};

AddTextFileRelease.propTypes = {
  translate: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  updateData: PropTypes.object
};

AddTextFileRelease.defaultProps = {
  updateData: {}
};

export default AddTextFileRelease;

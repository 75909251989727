import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, getOr } from 'lodash/fp';
import styled from '@emotion/styled';
import { NoHistory, ApiUnavailable } from 'assets/icons';
import DefaultSection from 'components/DefaultSection';
import StatusHistoryTable from './components/StatusHistoryTable';

const SessionsMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SESSIONS_QUERY_RESPONSES = {
  API_UNAVAILABLE: 503,
  NOT_FOUND: 404,
  SUCCESS: 200
};

const SimcardStatusHistory = ({
  sessions,
  supplierName,
  translate,
  ...props
}) =>
  getOr(SESSIONS_QUERY_RESPONSES.NOT_FOUND, 'status', sessions) !==
    SESSIONS_QUERY_RESPONSES.NOT_FOUND && (
    <Fragment>
      {sessions.status === SESSIONS_QUERY_RESPONSES.API_UNAVAILABLE && (
        <SessionsMessageContainer>
          <DefaultSection
            {...props}
            titleSize="one"
            data-testid="apiUnavailable"
            sectionImage={<ApiUnavailable />}
            sectionTitle={`
                ${translate(
                  'resultsInfo.statusHistory.apiUnavailable.title'
                )}, ${supplierName}?`}
            sectionText={translate(
              'resultsInfo.statusHistory.apiUnavailable.text'
            )}
          />
        </SessionsMessageContainer>
      )}

      {sessions.status === SESSIONS_QUERY_RESPONSES.SUCCESS &&
        (isEmpty(sessions.data) ? (
          <SessionsMessageContainer>
            <DefaultSection
              {...props}
              titleSize="one"
              sectionImage={<NoHistory />}
              sectionTitle={translate(
                'resultsInfo.statusHistory.noHistory.title'
              )}
              sectionText={translate(
                'resultsInfo.statusHistory.noHistory.text'
              )}
            />
          </SessionsMessageContainer>
        ) : (
          <StatusHistoryTable {...props} rows={sessions.data} />
        ))}
    </Fragment>
  );

SimcardStatusHistory.propTypes = {
  sessions: PropTypes.shape({
    data: PropTypes.array,
    status: PropTypes.number
  }),
  supplierName: PropTypes.string,
  translate: PropTypes.func.isRequired
};

export default SimcardStatusHistory;

const ITEMS_PER_PAGE = 10;

export const calculatePaginationItens = (amount, setItems) => {
  const content = [[]];
  let initialIndex = 0;
  let finalIndex = 9;

  const setContentValues = () => {
    const lastIndex = amount[finalIndex + 1] ? finalIndex + 1 : amount.length;

    content.push(amount.slice(initialIndex, lastIndex));
    initialIndex += ITEMS_PER_PAGE;
    finalIndex += ITEMS_PER_PAGE;
  };

  const loop = count => {
    if (count <= Math.ceil(amount.length / ITEMS_PER_PAGE)) {
      setContentValues();
      return loop(count + 1);
    }
    return true;
  };

  if (loop(1)) {
    setItems(content);
  }
  return content;
};

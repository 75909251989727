import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from '@sumup/circuit-ui';
import DefaultSection from 'components/DefaultSection';
import { SimcardResetSuccess, SimcardResetFail } from 'assets/icons';
import PropTypes from 'prop-types';

const SECTION_MAX_WIDTH = '600px';

const ModalContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacings.tera};
    background-color: ${theme.colors.giga};
  `};
`;

const StyledButton = styled(Button)`
  margin: 2em 0 0;
`;

const ResetResponseModal = ({ status, action, translate, ...props }) => {
  const statusOk = status === 'success';
  const image = statusOk ? <SimcardResetSuccess /> : <SimcardResetFail />;
  const translateKey = statusOk ? 'requestSuccess' : 'requestFail';

  return (
    <div id={translateKey} {...props}>
      <ModalContainer>
        <Fragment>
          <DefaultSection
            data-testid="simCardReset"
            sectionImage={image}
            sectionMaxWidth={SECTION_MAX_WIDTH}
            sectionTitle={translate(
              `resultsInfo.simcardReset.${translateKey}.title`
            )}
            sectionText={translate(
              `resultsInfo.simcardReset.${translateKey}.text`
            )}
          />
          <StyledButton variant="primary" onClick={action} data-testid="button">
            {translate(`resultsInfo.simcardReset.${translateKey}.button`)}
          </StyledButton>
        </Fragment>
      </ModalContainer>
    </div>
  );
};

ResetResponseModal.propTypes = {
  status: PropTypes.oneOf(['success', 'error']).isRequired,
  action: PropTypes.func,
  translate: PropTypes.func.isRequired
};

export default ResetResponseModal;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Spinner } from '@sumup/circuit-ui';

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: ${theme.spacings.zetta};
    margin-bottom: ${theme.spacings.zetta};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const StyledSpinner = styled(Spinner)`
  width: 3em;
  height: 3em;
  opacity: 0.5;
`;

const Loading = ({ size }) => (
  <Container>
    <StyledSpinner size={size} />
  </Container>
);

Loading.propTypes = {
  size: PropTypes.oneOf(['kilo', 'mega', 'giga'])
};

Loading.defaultProps = {
  size: 'mega'
};

export default Loading;

import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { navigate } from '@reach/router';
import { Button, Card, Body } from '@sumup/circuit-ui';
import { ROUTES_PATH } from 'constants/routes';
import { UploadCarrierSuggestionRules } from 'assets/icons';
import PageContainer from 'components/PageContainer';
import UploadFileButton from 'components/UploadFileButton';
import ErrorMessage from 'components/ErrorMessage';
import CheckInputtedDataPage from './components/CheckInputtedDataPage';
import {
  parseInputFile,
  validateFileStructure,
  validateData
} from './UploadCarrierSuggestionService';

const MainContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 ${theme.spacings.exa} ${theme.spacings.peta}
      ${theme.spacings.exa};
  `};
`;

const BackButton = styled(Button)`
  ${({ theme }) => css`
    width: 190px;
    max-width: auto;
    position: relative;
    margin-top: ${theme.spacings.exa};
    margin-bottom: ${theme.spacings.exa};
  `};
`;

const StyledCard = styled(Card)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 720px;
    margin: 0 ${theme.spacings.peta} ${theme.spacings.peta} 0;
    padding: ${theme.spacings.zetta} 0 ${theme.spacings.tera} 0;
  `};
`;

const CardContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 625px;
    text-align: center;
    margin-top: ${theme.spacings.mega};
  `};
`;

const StyledUploadButton = styled(UploadFileButton)`
  margin-top: 0.75em;
  width: 155px;
  max-width: auto;
`;

const UploadCarrierSuggestion = ({ t: translate }) => {
  const [loadingInput, setLoadingInput] = useState(false);
  const [parsedFileData, setParsedFileData] = useState([]);
  const [validEntries, setValidEntries] = useState([]);
  const [invalidEntries, setInvalidEntries] = useState([]);
  const [invalidFileStructure, setInvalidFileStructure] = useState(false);

  const parseFile = file =>
    parseInputFile(file, setLoadingInput, data => {
      setParsedFileData(data);
    });

  const setValidatedData = () => {
    const { valid, invalid } = validateData(parsedFileData);
    setValidEntries(valid);
    setInvalidEntries(invalid);
  };

  useEffect(() => {
    if (!isEmpty(parsedFileData)) {
      return validateFileStructure(
        parsedFileData,
        setValidatedData,
        setInvalidFileStructure
      );
    }
  }, [parsedFileData]);

  return (
    <PageContainer>
      {isEmpty(validEntries) && isEmpty(invalidEntries) ? (
        <Fragment>
          <MainContainer>
            <BackButton
              size="kilo"
              onClick={() =>
                navigate(ROUTES_PATH.CARRIER_SUGGESTION, {
                  state: {
                    currentPageKey: 'carrier-suggestion'
                  }
                })
              }
            >
              {translate('uploadCarrierSuggestion.backButton')}
            </BackButton>

            <StyledCard>
              <UploadCarrierSuggestionRules />

              <CardContent>
                <Body noMargin variant="highlight" size="one">
                  {translate(`uploadCarrierSuggestion.uploadCard.title`)}
                </Body>

                <Body noMargin size="one">
                  {translate(`uploadCarrierSuggestion.uploadCard.text`)}
                </Body>

                <StyledUploadButton
                  action={parseFile}
                  inputId="upload-new-rules"
                  inputAccept=".csv"
                  buttonTestId="upload-card-button"
                  buttonLabel={
                    'uploadCarrierSuggestion.uploadCard.button.label'
                  }
                  buttonWidth="258px"
                  isLoading={loadingInput}
                  isPrimary
                />
              </CardContent>
            </StyledCard>
          </MainContainer>

          {invalidFileStructure && (
            <ErrorMessage
              message={translate(
                'uploadCarrierSuggestion.invalidFileError.title'
              )}
              aditionalText={translate(
                'uploadCarrierSuggestion.invalidFileError.text'
              )}
              buttonLabel="Ok"
              action={() => setInvalidFileStructure(false)}
            />
          )}
        </Fragment>
      ) : (
        <CheckInputtedDataPage
          validEntries={validEntries}
          invalidEntries={invalidEntries}
          goBackAction={() => {
            setValidEntries([]);
            setInvalidEntries([]);
          }}
          t={translate}
        />
      )}
    </PageContainer>
  );
};

UploadCarrierSuggestion.propTypes = {
  t: PropTypes.func.isRequired
};

export default UploadCarrierSuggestion;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useLocation } from '@reach/router';
import { getUserData } from 'services/AuthenticationService';
import PageFooter from 'components/PageFooter';
import SidebarComponent from 'components/SidebarComponent';
import { SIDEBAR_WIDTH } from 'components/SidebarComponent/SidebarService';

const QUANTITY_OF_DEFAULT_ROLES = 1;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const Page = styled.div`
  ${({ hasSidebar, theme }) => css`
    width: ${hasSidebar ? `calc(100% - ${SIDEBAR_WIDTH})` : '100%'};
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacings.tera} ${theme.spacings.zetta}
      ${theme.spacings.exa} ${theme.spacings.zetta};

    @media (min-width: 960px) {
      margin-left: ${hasSidebar ? SIDEBAR_WIDTH : 'initial'};
    }
  `};
`;

const PageContainer = ({ children, withFooter, ...props }) => {
  const [userRoles, setUserRoles] = useState([]);
  const location = useLocation();

  const getUserRoles = async () =>
    getUserData().then(response =>
      response && response.roles
        ? setUserRoles(response.roles)
        : setUserRoles(userRoles)
    );

  const hasSidebar = userRoles.length > QUANTITY_OF_DEFAULT_ROLES;

  useEffect(() => {
    const controller = new AbortController();
    getUserRoles();

    return () => controller.abort();
  }, []);

  return (
    <Container>
      {hasSidebar && (
        <SidebarComponent
          selectedPage={
            location.state
              ? location.state.currentPageKey
              : 'reader-connectivity'
          }
        />
      )}

      <Page hasSidebar={hasSidebar} {...props}>
        {children}

        {withFooter && <PageFooter />}
      </Page>
    </Container>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node,
  withFooter: PropTypes.bool
};

PageContainer.defaultProps = {
  withFooter: true
};

export default PageContainer;

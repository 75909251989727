import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/react-common';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@emotion/react';
import { light as theme } from '@sumup/design-tokens';
import { ModalProvider } from '@sumup/circuit-ui';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Home from 'pages/Home';
import client from './apolloClient';
import i18n from './i18n';
import './config/sentry';
import google from './config/google.json';

const App = () => (
  <GoogleOAuthProvider clientId={google.clientId}>
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <Suspense fallback="loading">
            <ModalProvider>
              <Home />
            </ModalProvider>
          </Suspense>
        </ThemeProvider>
      </I18nextProvider>
    </ApolloProvider>
  </GoogleOAuthProvider>
);

export default App;

import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Body } from '@sumup/circuit-ui';
import CollapsibleContent from 'components/CollapsibleContent';
import {
  IconArrowDown,
  ThumbsUpGreen,
  ThumbsUpYellow,
  InvalidSign,
  ValidSign
} from 'assets/icons';
import ReportCard from '../ReportCard';

export const iconBaseStyles = `
  display: block;
  width: '20px';
  height: '20px';
`;

export const ThumbsUpGreenIcon = styled(ThumbsUpGreen)(iconBaseStyles);
export const ThumbsUpYellowIcon = styled(ThumbsUpYellow)(iconBaseStyles);
export const InvalidSignIcon = styled(InvalidSign)(iconBaseStyles);
export const ValidSignIcon = styled(ValidSign)(iconBaseStyles);

const PageContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 ${theme.spacings.exa} ${theme.spacings.peta}
      ${theme.spacings.exa};
  `};
`;

const BackButton = styled(Button)`
  ${({ theme }) => css`
    max-width: 220px;
    position: relative;
    margin-top: ${theme.spacings.exa};
    margin-bottom: ${theme.spacings.exa};
  `};
`;

const DownloadCard = styled(ReportCard)`
  ${({ theme }) => css`
    max-width: 410px;
    padding: ${theme.spacings.zetta} ${theme.spacings.tera}
      ${theme.spacings.tera} ${theme.spacings.tera};
  `};
`;

const TextWithIconInline = styled.span`
  display: flex;
  align-items: center;
  margin: 0.5em 0;
  & > *:nth-of-type(odd) {
    margin-right: 1em;
  }
`;

const ProcessedDataContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.byte} ${theme.spacings.kilo};
    padding-left: ${theme.spacings.tera};
  `};
`;

const ErrorListContainer = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.n200};
    padding: 1em 0;
  `};
`;

const ArrowUp = styled(IconArrowDown)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacings.byte};
    transform: rotate(180deg);
  `};
`;

const ArrowDown = styled(IconArrowDown)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacings.byte};
  `};
`;

const DownloadRLDeactivationReport = ({
  responseData,
  goBackAction,
  downloadAction,
  t: translate
}) => {
  const [errorsOpen, setErrorsOpen] = useState(false);

  const hasUploadResultErrors = !isEmpty(responseData.errors);

  const errorCountText = () => (
    <TextWithIconInline onClick={() => setErrorsOpen(!errorsOpen)}>
      <InvalidSignIcon />
      {` ${responseData.total_errors} 
        ${translate(
          'simDeactivationReports.downloadCard.entriesText.incorrectFormatting'
        )}`}
      {errorsOpen ? <ArrowUp /> : <ArrowDown />}
    </TextWithIconInline>
  );

  const shouldShowErrorList = responseData.total_errors <= 20;

  const errorsData = () => (
    <ErrorListContainer>
      {shouldShowErrorList ? (
        responseData.errors.map(error => (
          <Body noMargin size="two">{`- ${error}`}</Body>
        ))
      ) : (
        <Body noMargin size="two">
          {translate(
            'simDeactivationReports.downloadCard.entriesText.tooManyErrors'
          )}
        </Body>
      )}
    </ErrorListContainer>
  );

  return (
    <PageContainer>
      <BackButton size="kilo" onClick={() => goBackAction(false)}>
        {translate('simDeactivationReports.button.goBack.label')}
      </BackButton>

      <DownloadCard
        cardTitle={
          <TextWithIconInline>
            {hasUploadResultErrors ? (
              <ThumbsUpYellowIcon />
            ) : (
              <ThumbsUpGreenIcon />
            )}

            <span>
              {hasUploadResultErrors
                ? translate(
                    'simDeactivationReports.downloadCard.title.hasErrors'
                  )
                : translate(
                    'simDeactivationReports.downloadCard.title.noErrors'
                  )}
            </span>
          </TextWithIconInline>
        }
        action={() => downloadAction()}
        hasEntriesCount={false}
      >
        <Fragment>
          <ProcessedDataContainer>
            <TextWithIconInline>
              <ValidSignIcon />
              {` ${responseData.total_processed} 
            ${translate(
              'simDeactivationReports.downloadCard.entriesText.successfullyProcessed'
            )}`}
            </TextWithIconInline>

            {hasUploadResultErrors && (
              <CollapsibleContent
                style={{ borderTop: 0 }}
                data-testid="more-details-option"
                triggerToOpen={errorCountText()}
                triggerToClose={errorCountText()}
              >
                {errorsData()}
              </CollapsibleContent>
            )}
          </ProcessedDataContainer>

          <Body
            noMargin
            size="two"
            style={{
              maxWidth: '300px',
              margin: '0.5em auto 0 auto',
              textAlign: 'center'
            }}
          >
            {translate('simDeactivationReports.downloadCard.subtitle')}
          </Body>
        </Fragment>
      </DownloadCard>
    </PageContainer>
  );
};

DownloadRLDeactivationReport.propTypes = {
  responseData: PropTypes.object.isRequired,
  goBackAction: PropTypes.func.isRequired,
  downloadAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default DownloadRLDeactivationReport;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SearchInput } from '@sumup/circuit-ui';
import BlackButton from 'components/BlackButton';

const FormStyled = styled.form`
  display: flex;

  input[data-testid='search-input'] {
    width: 300px;
    max-width: 100%;
  }
`;

const SearchForm = ({
  searching,
  onSubmit,
  onReset,
  t: translate,
  initialSerialNumber
}) => {
  const [serialNumber, setSerialNumber] = useState(initialSerialNumber);

  const validateValue = value => {
    const inputValue = value.replace(/[^A-Za-z0-9]/g, '');
    setSerialNumber(inputValue);
  };

  const searchInputChanged = event => {
    validateValue(event.target.value);

    if (onReset && !event.target.value) {
      onReset();
    }
  };

  const submit = () => {
    const values = { serialNumber };

    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleClear = () => {
    setSerialNumber('');
    onReset();
  };

  return (
    <FormStyled onSubmit={submit}>
      <SearchInput
        inline
        noMargin
        required
        hideLabel={true}
        label={translate('pageSections.searchForm.inputPlaceholder')}
        data-testid="search-input"
        name="serial_number"
        value={serialNumber}
        placeholder={translate('pageSections.searchForm.inputPlaceholder')}
        onClear={handleClear}
        disabled={searching}
        clearLabel={translate('pageSections.searchForm.inputPlaceholder')}
        onKeyDown={event => event.key === 'Enter' && submit()}
        onChange={searchInputChanged}
        autoComplete="off"
      />
      <BlackButton
        data-testid="search-button"
        variant="primary"
        buttonWidth="200px"
        style={{
          height: '3em',
          alignSelf: 'end',
          maxWidth: '105px',
          width: 'auto'
        }}
        isLoading={searching}
        loadingLabel="Loading..."
        disabled={searching || !serialNumber}
      >
        {translate('pageSections.searchForm.button')}
      </BlackButton>
    </FormStyled>
  );
};

SearchForm.propTypes = {
  searching: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  initialSerialNumber: PropTypes.string
};

export default SearchForm;

import styled from '@emotion/styled';

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: 100%;
  height: 100%;
`;

export default MainContent;

import React from 'react';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';
import BlackButton from 'components/BlackButton';
import DefaultSection from 'components/DefaultSection';
import { Error as ErrorIcon, Success as SuccessIcon } from 'assets/icons';

export const FileChackMark = styled.div`
  position: relative;
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  margin-right: 0.8em;

  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    width: 3px;
    background-color: #888;
    content: '';
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
  }

  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background-color: #888;
    content: '';
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
  }
`;

const ResponseMessageContainer = styled.div`
  width: 100%;
  margin: auto;

  & > div {
    max-width: 100%;
  }
  & div > svg {
    width: 66px;
    height: 68px;
  }
`;

const ErrorsContainer = styled.div`
  text-align: left;
  margin-top: 1.5em;
  padding-bottom: 1em;

  & p {
    font-size: 0.85em;
  }
  & span {
    font-weight: bold;
  }
`;

const mapSelectOptions = list =>
  list.map(item => ({ label: item, value: item }));

export const getResourceTypesConfig = (options, value) => ({
  id: 'resource-types',
  options: mapSelectOptions(options),
  value,
  placeholder: 'Select a resource type'
});

export const getLocalesConfig = (options, value) => ({
  id: 'locales',
  options: mapSelectOptions(options),
  value,
  placeholder: 'Select a locale'
});

export const uploadNewResource = (
  reader,
  version,
  type,
  locale,
  file,
  signature,
  setLoading,
  setErrors,
  setFailedUpload,
  setSuccessfulUpload
) => {
  setLoading(true);

  const formData = new FormData();

  formData.append('type', type);
  formData.append('locale', locale);
  formData.append('file_upload', file);
  formData.append('signature_file_upload', signature);

  const options = {
    method: 'POST',
    body: formData
  };

  return fetch(
    `/assets_management_api/devices/${reader}/specs/text_file/releases/${version}/resources`,
    options
  )
    .then(response => response.json())
    .then(response => {
      response.errors && setErrors(response.errors);
      return response;
    })
    .then(response =>
      response.errors ? setFailedUpload(true) : setSuccessfulUpload(true)
    )
    .catch(() => setFailedUpload(true))
    .finally(() => setLoading(false));
};

export const handleSubmit = (
  validationErrors,
  reader,
  version,
  type,
  locale,
  file,
  signature,
  setLoading,
  setErrors,
  setFailedUpload,
  setSuccessfulUpload
) => {
  const errors = validationErrors();

  !isEmpty(errors)
    ? errors.map(item =>
        item.action({ value: item.field.value, hasError: true })
      )
    : uploadNewResource(
        reader,
        version,
        type,
        locale,
        file,
        signature,
        setLoading,
        setErrors,
        setFailedUpload,
        setSuccessfulUpload
      );
};

const errorValue = { value: '', hasError: true };

export const validateFile = (file, field, setValue) =>
  field.hasError === false && !file
    ? setValue(errorValue)
    : setValue({ value: file, hasError: false });

export const validateSelect = (e, field, setValue) =>
  field.hasError === false && e.target.value === ''
    ? setValue(errorValue)
    : setValue({ value: e.target.value, hasError: false });

export const renderCloseButton = (close, label) => (
  <BlackButton onClick={close} buttonTestId="cancel-button" size="kilo">
    {label}
  </BlackButton>
);

const fieldLabelMap = {
  file_upload: 'File',
  locale: 'Locale',
  signature_file_upload: 'Signature file',
  type: 'Type'
};

export const renderErrorMessage = (translate, errors, close) => (
  <ResponseMessageContainer>
    <DefaultSection
      titleSize="one"
      sectionImage={<ErrorIcon />}
      sectionTitle={translate('genericError.title')}
      sectionText={
        errors
          ? 'The following fields contain errors: '
          : translate('genericError.text')
      }
    >
      <ErrorsContainer>
        {Object.keys(errors).map((item, key) =>
          errors[item].map((error, itemKey) => (
            <p key={itemKey}>
              <span>{fieldLabelMap[item]}: </span> {error}
            </p>
          ))
        )}
      </ErrorsContainer>

      {renderCloseButton(close, 'Close')}
    </DefaultSection>
  </ResponseMessageContainer>
);

export const renderSuccessMessage = () => (
  <ResponseMessageContainer>
    <DefaultSection
      sectionImage={<SuccessIcon />}
      sectionTitle="Success"
      sectionText="New resource created successfully."
      titleSize="one"
    />
  </ResponseMessageContainer>
);

import React, { Fragment, useState } from 'react';
import { isEmpty, toLower } from 'lodash';
import PropTypes from 'prop-types';
import {
  Grid,
  Row,
  Col,
  Headline,
  Body,
  Label,
  Input,
  Select,
  ButtonGroup
} from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  getCountryOrStates,
  validateField,
  getErrorCodes
} from './ResetRequestFormService';

const StyledButtonGroup = styled(ButtonGroup)`
  ${({ theme }) => css`
    padding-top: ${theme.spacings.peta};
  `};
`;

const InputStyles = css`
  margin: 1em 0.5em 0.5em 0;
`;
const StyledInput = styled(Input)(InputStyles);

const StyledSelect = styled(Select)(InputStyles);

const StyledBody = styled(Body)`
  margin: 0 0 1.5em 0;
`;

const StyledHeading = styled(Headline)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.byte};
  `};
`;

const BRAZILLIAN_SUPPLIERS = ['linksolutions', 'arqia', 'telefonica'];

const APPLICATION_WITH_ERROR_CODES = ['setis', 'cloudwalk'];

const ResetRequestForm = ({
  userEmail,
  onSubmit,
  onCancel,
  afterRequest,
  translate,
  ...props
}) => {
  const [resetting, setResetting] = useState(false);
  const [cityValue, setCityValue] = useState({ value: '', hasError: false });
  const [commentValue, setCommentValue] = useState({
    value: '',
    hasError: false
  });
  const [countryOrStateValue, setCountryOrStateValue] = useState({
    value: '',
    hasError: false
  });
  const [errorCodeValue, setErrorCodeValue] = useState({
    value: '',
    hasError: false
  });

  const { supplierName, applicationType } = props;

  const translateKey = key =>
    translate(`resultsInfo.simcardReset.requestForm.${key}`);

  const isBrazillianSupplier = BRAZILLIAN_SUPPLIERS.includes(
    toLower(supplierName)
  );

  const errorCodeOptions = getErrorCodes(applicationType);

  const shouldShowErrorCodes =
    errorCodeOptions &&
    APPLICATION_WITH_ERROR_CODES.includes(toLower(applicationType));

  const countryOrState = getCountryOrStates(isBrazillianSupplier);

  const formValues = {
    city: cityValue.value,
    country: isBrazillianSupplier ? 'BR' : countryOrStateValue.value,
    region_name: isBrazillianSupplier ? countryOrStateValue.value : '',
    reason_code: errorCodeValue.value,
    comment: commentValue.value,
    requester_email: userEmail
  };

  const formValidationErrors = () => {
    const fields = [
      { field: cityValue, action: setCityValue },
      { field: commentValue, action: setCommentValue },
      { field: countryOrStateValue, action: setCountryOrStateValue }
    ];

    const formFields = shouldShowErrorCodes
      ? [{ field: errorCodeValue, action: setErrorCodeValue }, ...fields]
      : fields;

    return formFields.filter(item => item.field.value === '');
  };

  const CREATED_STATUS = 201;
  let responseStatus = '';

  const resetSimCard = () => {
    setResetting(true);
    onSubmit(formValues)
      .then(response =>
        response.status === CREATED_STATUS
          ? (responseStatus = 'success')
          : (responseStatus = 'error')
      )
      .catch(() => {
        responseStatus = 'error';
      })
      .finally(() => {
        setResetting(false);
        if (afterRequest) {
          afterRequest(responseStatus);
        }
      });
  };

  const handleSubmit = () =>
    !isEmpty(formValidationErrors())
      ? formValidationErrors().map(item =>
          item.action({ value: item.field.value, hasError: true })
        )
      : resetSimCard();

  return (
    <Fragment>
      <StyledHeading as="h1" size="three" noMargin>
        {translateKey('heading')}
      </StyledHeading>

      <StyledBody noMargin as="h2" size="two">
        {translateKey('subHeading')}
      </StyledBody>

      <Label htmlFor="city">
        {translateKey('readerLocation.label')}
        <Grid style={{ padding: 0 }}>
          <Row span={{ default: 12 }}>
            <Col span={{ default: 6 }}>
              <StyledInput
                inline
                noMargin
                showValid
                hideLabel
                label="city"
                id="city"
                as="input"
                name="city"
                data-testid="city-input"
                invalid={cityValue.hasError}
                placeholder={translateKey('readerLocation.placeholder')}
                onBlur={e => validateField(e, cityValue, setCityValue)}
                validationHint={
                  cityValue.hasError ? translateKey('validationHint') : ''
                }
              />
            </Col>

            <Col span={{ default: 4 }}>
              <Label htmlFor={countryOrState.id}>
                <StyledSelect
                  inline
                  noMargin
                  hideLabel
                  id={countryOrState.id}
                  name={countryOrState.id}
                  options={countryOrState.options}
                  label={countryOrState.placeholder}
                  data-testid="country-or-state-select"
                  placeholder={translateKey(countryOrState.placeholder)}
                  value={countryOrStateValue.value}
                  invalid={countryOrStateValue.hasError}
                  validationHint={
                    countryOrStateValue.hasError
                      ? translateKey('validationHint')
                      : ''
                  }
                  onChange={e =>
                    validateField(
                      e,
                      countryOrStateValue,
                      setCountryOrStateValue
                    )
                  }
                />
              </Label>
            </Col>
          </Row>
        </Grid>
      </Label>

      {shouldShowErrorCodes && (
        <Label htmlFor="city">
          {translateKey('errorCode.label')}

          <Grid style={{ padding: 0 }}>
            <Row span={{ default: 12 }}>
              <Col span={{ default: 6 }}>
                <StyledSelect
                  noMargin
                  hideLabel
                  name="errorCode"
                  id="errorCode"
                  label="error-code-select"
                  data-testid="error-code-select"
                  value={errorCodeValue.value}
                  options={errorCodeOptions}
                  invalid={errorCodeValue.hasError}
                  placeholder={translateKey('errorCode.placeholder')}
                  onChange={e =>
                    validateField(e, errorCodeValue, setErrorCodeValue)
                  }
                  validationHint={
                    errorCodeValue.hasError
                      ? translateKey('validationHint')
                      : ''
                  }
                />
              </Col>
            </Row>
          </Grid>
        </Label>
      )}

      <Label
        style={{ display: 'flex', flexDirection: 'column' }}
        htmlFor="comment"
      >
        {translateKey('comment.label')}
        <StyledInput
          inline
          noMargin
          showValid
          hideLabel
          label="comment"
          id="comment"
          as="input"
          name="comment"
          data-testid="comment-input"
          maxLength="140"
          invalid={commentValue.hasError}
          placeholder={translateKey('comment.placeholder')}
          onBlur={e => validateField(e, commentValue, setCommentValue)}
          validationHint={
            commentValue.hasError ? translateKey('validationHint') : ''
          }
        />
      </Label>

      <StyledButtonGroup
        align="right"
        actions={{
          secondary: {
            children: translateKey('button.cancel'),
            onClick: onCancel,
            disabled: resetting,
            'data-testid': 'cancel-button'
          },
          primary: {
            children: translateKey('button.confirm'),
            onClick: handleSubmit,
            isLoading: resetting,
            loadingLabel: 'Loading...',
            disabled: resetting,
            'data-testid': 'reset-button'
          }
        }}
      />
    </Fragment>
  );
};

ResetRequestForm.propTypes = {
  userEmail: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  afterRequest: PropTypes.func,
  translate: PropTypes.func.isRequired,
  supplierName: PropTypes.string,
  applicationType: PropTypes.string
};

export default ResetRequestForm;

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { toLower } from 'lodash/fp';
import styled from '@emotion/styled';
import StatusComponent from 'components/StatusComponent';
import Tooltip from 'components/Tooltip';
import { Spinner, Button, Tooltip as CircuitTooltip } from '@sumup/circuit-ui';
import { Reload } from 'assets/icons';
import SearchResult from 'contexts/SearchResult';
import UserData from 'contexts/UserData';
import StateChangeButton from './components/StateChangeButton';
import {
  getLineState,
  getStateData,
  shouldRenderStateChange
} from './LineStateService';

const TooltipText = styled.span`
  white-space: inherit;
`;

const StyledSpinner = styled(Spinner)`
  width: 16px;
  height: 16px;
`;

const ReloadButton = styled(Button)`
  margin-left: 0.25em;
  background-color: transparent;
  padding: 0.15em;
  border: 0;

  &:hover {
    background-color: transparent;
    & svg {
      fill: #000;
    }
  }

  & svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: #9da7b1;
    transition: 0.35s ease-out 100ms;
  }
`;

const LineState = ({ iccid, getStateColor, translate, ...props }) => {
  const searchResult = useContext(SearchResult);
  const [lineState, setLineState] = useState('');
  const [loading, setLoading] = useState(false);
  const [stateData, setStateData] = useState({});
  const { email } = useContext(UserData);
  const { supplier, lineSupplier } = searchResult;

  const isNotAvailable = stateData.label === 'notAvailable';

  const stateUnavailable = {
    state: 'Unavailable',
    state_changed_at: null
  };

  const renderStatusComponent = () => (
    <StatusComponent
      colorName={stateData.color}
      label={translate(
        `resultsInfo.lineState.state.${toLower(stateData.label)}`
      )}
      isLighter={isNotAvailable}
      style={
        !lineState.state_changed_at || isNotAvailable
          ? { cursor: 'help' }
          : { cursor: 'inherit' }
      }
      {...props}
    />
  );

  const renderState = () => {
    return shouldRenderStateChange(lineState, lineSupplier, supplier) &&
      lineState &&
      stateData ? (
      <StateChangeButton
        iccid={iccid}
        lineState={lineState}
        userEmail={email}
        stateData={stateData}
        translate={translate}
      >
        {renderStatusComponent()}
      </StateChangeButton>
    ) : (
      renderStatusComponent()
    );
  };

  const renderStateAndReloadButton = () => (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {(lineState && lineState.state_changed_at) || isNotAvailable
        ? renderTooltipOrState(renderState())
        : renderState()}
      <ReloadButton onClick={() => getCurrentLineState(iccid)}>
        <Reload />
      </ReloadButton>
    </span>
  );

  const getCurrentLineState = iccid => {
    setLoading(true);

    getLineState(iccid)
      .then(response => (response.ok ? response.json() : stateUnavailable))
      .then(response => {
        setLineState(response);
        setStateData(getStateData(response.state));
        setLoading(false);
      })
      .catch(() => {
        return setLineState(stateUnavailable);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getCurrentLineState(iccid);
    return setLoading(true);
  }, [iccid]);

  useEffect(() => getStateColor && getStateColor(stateData.cardColor), [
    stateData
  ]);

  const renderTooltipOrState = children => (
    <Tooltip
      trigger={children}
      data-testid="line-state-tooltip"
      align={CircuitTooltip.LEFT}
      position={CircuitTooltip.BOTTOM}
    >
      <TooltipText lighterText={isNotAvailable}>
        {isNotAvailable
          ? translate('resultsInfo.lineState.tooltip.notAvailable')
          : `${translate('resultsInfo.lineState.lastUpdate')} ${
              lineState.state_changed_at
            }`}
      </TooltipText>
    </Tooltip>
  );

  return loading ? <StyledSpinner /> : renderStateAndReloadButton();
};

LineState.propTypes = {
  iccid: PropTypes.string.isRequired,
  getStateColor: PropTypes.func,
  translate: PropTypes.func.isRequired
};

export default LineState;

import React from 'react';
import { hideVisually } from 'polished';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from '@reach/router';
import { Button } from '@sumup/circuit-ui';

const BUTTON_WIDTH = '37px';

const ICON_MAX_WIDTH = '15px';

const ButtonLinkContainer = styled.div`
  display: inline-block;
  max-width: ${BUTTON_WIDTH};
`;

const IconButtonStyled = styled(Button)`
  ${({ theme }) => css`
    display: inline-block;
    width: 100%;
    max-width: ${BUTTON_WIDTH};
    height: auto;
    line-height: 0;

    &,
    &:hover,
    &:focus,
    &:active {
      padding: ${theme.spacings.byte} ${theme.spacings.kilo};
    }
    svg {
      max-height: ${theme.iconSizes.byte};
      max-width: ${ICON_MAX_WIDTH};
    }
  `}
`;

const LabelButton = styled.span`
  ${hideVisually()};
`;

const withLink = (link, isExternalLink = false, content) => {
  if (isExternalLink) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {content}
      </a>
    );
  }
  return <Link to={link}>{content}</Link>;
};

const IconButton = ({ icon, href, onClick, buttonLabel, isExternalLink }) => {
  const performAction = () => onClick();

  const iconButtonStyled = (
    <IconButtonStyled
      size="kilo"
      data-testid="icon-button"
      onClick={onClick && performAction}
    >
      {icon}
      <LabelButton>{buttonLabel}</LabelButton>
    </IconButtonStyled>
  );

  if (href) {
    return (
      <ButtonLinkContainer>
        {withLink(href, isExternalLink, iconButtonStyled)}
      </ButtonLinkContainer>
    );
  }
  return iconButtonStyled;
};

IconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  isExternalLink: PropTypes.bool
};

IconButton.defaultProps = {
  isExternalLink: false
};

export default IconButton;

import React from 'react';
import PropTypes from 'prop-types';
import { SimcardReset } from 'assets/icons';
import DefaultSection from 'components/DefaultSection';

const ResetHistoryEmpty = ({ t: translate, ...props }) => (
  <DefaultSection
    {...props}
    data-testid="simCardReset"
    sectionImage={<SimcardReset />}
    sectionTitle={translate('resultsInfo.simcardReset.title')}
    sectionText={translate('resultsInfo.simcardReset.text')}
    headingMaxWidth="400px"
  />
);

ResetHistoryEmpty.propTypes = {
  t: PropTypes.func.isRequired
};

export default ResetHistoryEmpty;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NotificationInline } from '@sumup/circuit-ui';
import { SIDEBAR_WIDTH } from 'components/SidebarComponent/SidebarService';

const MessageContainer = styled.div`
  ${({ theme }) => css`
    position: fixed;
    bottom: ${theme.spacings.peta};
    display: flex;
    flex-direction: column;
    align-items: start;
    width: calc(100% - (${SIDEBAR_WIDTH} + calc(${theme.spacings.exa} * 2)));
    max-width: 90%;
  `};
`;

const NotificationWide = styled(NotificationInline)`
  width: 100%;

  & button {
    position: absolute;
    top: 0;
    right: 1em;
    bottom: 0;
    color: #D23F47;
    transition: 0.35s ease-out 100ms;
  }
  & button:hover {
    color: #D23F47;
    opacity: .7;
`;

const ErrorMessage = ({
  message,
  aditionalText,
  buttonLabel,
  action,
  t: translate,
  ...props
}) => {
  const defaultMessage = translate('pageSections.errorMessage.defaultMessage');
  const defaultLabel = translate('pageSections.errorMessage.button');

  return (
    <MessageContainer>
      <NotificationWide
        {...props}
        variant="alert"
        action={{
          onClick: () => action(),
          children: buttonLabel || defaultLabel
        }}
        headline={{
          as: 'h3',
          label: message || defaultMessage
        }}
        body={aditionalText && aditionalText}
      />
    </MessageContainer>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  aditionalText: PropTypes.string,
  buttonLabel: PropTypes.string,
  action: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default ErrorMessage;

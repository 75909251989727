import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IconButton from 'components/IconButton';
import StatusMessageContext from 'components/StatusMessageProvider';
import { Button, Body } from '@sumup/circuit-ui';
import { IconCopy } from 'assets/icons';
import { copyContent } from './CopyButtonService';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StyledLabel = styled(Body)`
  text-transform: uppercase;
`;

const CopyButton = ({
  data,
  action,
  label,
  icon,
  bold,
  t: translate,
  ...props
}) => {
  const { setStatusMessage } = useContext(StatusMessageContext);

  const copyAction = action
    ? () => action(data)
    : () => copyContent(data, setStatusMessage, translate);
  return label ? (
    <StyledButton
      noMargin
      data-testid="styled-button"
      onClick={copyAction}
      size="kilo"
      {...props}
    >
      {icon}
      <StyledLabel noMargin as="span" bold={bold} size="two">
        {label}
      </StyledLabel>
    </StyledButton>
  ) : (
    <IconButton
      noMargin
      icon={icon}
      buttonLabel={translate(`statusMessages.copyData.label.default`)}
      onClick={copyAction}
      {...props}
    />
  );
};

CopyButton.propTypes = {
  data: PropTypes.string.isRequired,
  action: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.node,
  bold: PropTypes.bool,
  t: PropTypes.func.isRequired
};

CopyButton.defaultProps = {
  icon: <IconCopy role="presentation" />,
  bold: false
};

export default CopyButton;

import { DEFAULT_EMPTY_INFO } from '../SearchResultCard/SearchResultCardService';

export const getEmptyValues = hasIccid => ({
  emptyIccid: {
    label: DEFAULT_EMPTY_INFO,
    tooltip: ''
  },
  emptyImsi: {
    label: DEFAULT_EMPTY_INFO
  },
  emptyCarrier: {
    label: !hasIccid
      ? 'resultsInfo.carrier.empty.label'
      : 'resultsInfo.carrier.noCurrentCarrier.label',
    tooltip: !hasIccid
      ? 'resultsInfo.carrier.empty.tooltip'
      : 'resultsInfo.carrier.noCurrentCarrier.tooltip'
  },
  emptySupplier: {
    label: 'resultsInfo.supplier.empty.label',
    tooltip: 'resultsInfo.supplier.empty.tooltip'
  }
});

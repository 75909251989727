export const ERROR_CODES = {
  setis: [
    { label: 'C001', value: 'C001' },
    { label: 'C002', value: 'C002' },
    { label: 'C003', value: 'C003' },
    { label: 'C004', value: 'C004' },
    { label: 'C006', value: 'C006' },
    { label: 'C007', value: 'C007' },
    { label: 'C008', value: 'C008' },
    { label: 'C009', value: 'C009' },
    { label: 'C017', value: 'C017' },
    { label: 'C019', value: 'C019' },
    { label: 'C020', value: 'C020' },
    { label: '6004', value: '6004' },
    { label: 'Others', value: 'others' }
  ],
  cloudwalk: [
    { label: '1012', value: '1012' },
    { label: '3301', value: '3301' },
    { label: '3302', value: '3302' },
    { label: '3305', value: '3305' },
    { label: '3306', value: '3306' },
    { label: '3352', value: '3352' },
    { label: '3503', value: '3503' },
    { label: '3507', value: '3507' },
    { label: 'Others', value: 'others' }
  ]
};

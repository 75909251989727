import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { getOr } from 'lodash/fp';
import { createUploadLink } from 'apollo-upload-client';
// import { getUserData, clearUserData } from './services/AuthenticationService';

const graphQLServerUrl = process.env.TOAP_GRAPHQL_SERVER_URL;

const authLink = setContext((_, { headers }) => {
  const token = getOr('', 'token');

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ extensions }) => {
      if (!!extensions && extensions.code === 'UNAUTHENTICATED') {
        //        clearUserData();
        window.location = '/login';
      }
      return null;
    });
});

const link = ApolloLink.from([
  authLink,
  errorLink,
  createUploadLink({ uri: graphQLServerUrl })
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

export default client;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';

const COLOR_MAP = {
  success: {
    default: 'g500'
  },
  warning: {
    default: 'y500'
  },
  danger: {
    default: 'r500'
  },
  primary: {
    default: 'b500'
  },
  neutral: {
    default: 'n500'
  },
  orange: {
    default: 'o300'
  },
  light: {
    default: 'n300'
  }
};

const colorStyles = ({ theme, colorName }) => {
  const currentColor = COLOR_MAP[colorName];

  if (!currentColor) {
    return null;
  }
  return css`
    background-color: ${theme.colors[currentColor.default]};
  `;
};

const StatusContainer = styled.span`
  display: flex;
  align-items: baseline;
`;

const StatusBullet = styled.div`
  ${colorStyles};
  ${({ theme }) => css`
    display: inline-block;
    margin: ${`0 ${theme.spacings.bit} 0 0`};
    width: ${theme.spacings.byte};
    height: ${theme.spacings.byte};
    border-radius: ${theme.spacings.bit};
  `};
`;

const StatusLabel = styled(Body)`
  ${({ theme, lighterText }) => css`
    color: ${lighterText ? theme.colors.n500 : 'inherit'};
    text-transform: uppercase;
  `};
`;

const StatusComponent = ({ colorName, label, isLighter, ...props }) => {
  return (
    <StatusContainer {...props}>
      <StatusBullet colorName={colorName} />
      <StatusLabel as="span" noMargin size="two" lighterText={isLighter}>
        {label}
      </StatusLabel>
    </StatusContainer>
  );
};

StatusComponent.propTypes = {
  colorName: PropTypes.oneOf([
    'success',
    'warning',
    'danger',
    'primary',
    'neutral',
    'orange',
    'light'
  ]).isRequired,
  label: PropTypes.string.isRequired,
  isLighter: PropTypes.bool
};

StatusComponent.defaultProps = {
  isLighter: false
};

export default StatusComponent;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, List } from '@sumup/circuit-ui';
import SearchResult from 'contexts/SearchResult';
import { getCardReaderName } from '../../SerialNumberSearchService';

const InfoList = styled(List)`
  margin: 0;
  padding: 0;
`;

const DataObjectItem = styled(Body)`
  ${({ theme }) => css`
    display: inline-block;
    margin-right: ${theme.spacings.mega};

    &:first-of-type {
      margin-left: 0;
    }
  `};
`;

const ItemContent = styled.span`
  display: inline-block;
`;

const InfoItemsList = ({ translate }) => {
  const searchResult = useContext(SearchResult);

  const {
    model,
    serialNumber,
    applicationType,
    applicationVersion
  } = searchResult;

  const SORTED_ITEMS = {
    model: {
      id: 'model',
      field: translate('resultsInfo.model.label'),
      value: translate(getCardReaderName(model))
    },
    serialNumber: {
      id: 'serialnumber',
      field: translate('resultsInfo.serialNumber.label'),
      value: serialNumber
    },
    applicationType: {
      id: 'applicationtype',
      field: translate('resultsInfo.applicationType.label'),
      value:
        applicationType || translate('resultsInfo.applicationType.empty.label')
    },
    applicationVersion: {
      id: 'applicationversion',
      field: translate('resultsInfo.applicationVersion.label'),
      value:
        applicationVersion ||
        translate('resultsInfo.applicationVersion.empty.label')
    }
  };

  return (
    <InfoList noMargin size="two" variant="unordered">
      {Object.keys(SORTED_ITEMS).map(item => (
        <DataObjectItem
          as="li"
          key={SORTED_ITEMS[item].id}
          size="two"
          noMargin
          data-testid={`field-${SORTED_ITEMS[item].id}`}
        >
          <Body noMargin as="b" size="two" variant="highlight">
            {SORTED_ITEMS[item].field}
          </Body>
          : <ItemContent>{SORTED_ITEMS[item].value}</ItemContent>
        </DataObjectItem>
      ))}
    </InfoList>
  );
};
InfoItemsList.propTypes = {
  translate: PropTypes.func.isRequired
};

export default InfoItemsList;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';

const FeedMessageContainer = styled.div`
  ${({ theme }) => css`
    position: fixed;
    bottom: ${theme.spacings.giga};
    display: flex;
    justify-content: center;
    width: 100%;
  `};
`;

const fadeAnimation = keyframes`
	from 0 to {
		opacity: 0;
	}
	25%, 75% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
`;

const FeedbackMessage = styled.div`
  ${({ theme, transition, animationDuration }) => css`
    display: flex;
    align-items: center;
    max-width: fit-content;
    border-radius: ${theme.spacings.bit};
    background-color: ${theme.colors.n900};
    padding: ${theme.spacings.kilo} ${theme.spacings.giga};
    color: ${theme.colors.n200};
    opacity: 0;
    animation-name: ${transition ? fadeAnimation : 'none'};
    animation-duration: ${animationDuration}ms;
    animation-timing-function: ease-in-out;
  `};
`;

const IconWrapper = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacings.kilo};
    & * {
      stroke: ${theme.colors.n200};
    }
  `};
`;

export const StatusMessageContext = React.createContext();

const IN = 'in';
const OUT = 'out';
const INITIAL_STATE = null;

const DEFAULT_MESSAGE = {
  animationDuration: 3000
};

const StatusMessageProvider = ({ children }) => {
  const [statusMessage, setMessage] = useState(INITIAL_STATE);
  const [isTransitioning, setTransitioning] = useState(false);

  const animate = (callback, type = IN) => {
    if (type === IN) {
      callback();
    }
    setTimeout(() => {
      if (type === OUT) {
        callback();
      }
      setTransitioning(true);
    }, 100);

    setTransitioning(false);
  };
  const setStatusMessage = (message = {}) =>
    animate(() => setMessage({ ...DEFAULT_MESSAGE, ...message }));

  useEffect(() => {
    if (!statusMessage) {
      return;
    }
    const timerId = setTimeout(
      () => animate(() => setMessage(INITIAL_STATE), OUT),
      statusMessage.animationDuration
    );
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timerId);
  }, [statusMessage]);

  return (
    <StatusMessageContext.Provider value={{ setStatusMessage }}>
      {children}
      {statusMessage && statusMessage.message && (
        <FeedMessageContainer>
          <FeedbackMessage
            transition={isTransitioning}
            animationDuration={statusMessage.animationDuration}
          >
            {statusMessage.icon && (
              <IconWrapper>{statusMessage.icon}</IconWrapper>
            )}
            <Body noMargin id="status-message">
              {statusMessage.message}
            </Body>
          </FeedbackMessage>
        </FeedMessageContainer>
      )}
    </StatusMessageContext.Provider>
  );
};

StatusMessageProvider.propTypes = {
  children: PropTypes.any
};

export default StatusMessageProvider;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getOr, isEmpty } from 'lodash/fp';
import { navigate } from '@reach/router';
import { ROUTES_PATH } from 'constants/routes';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Pagination } from '@sumup/circuit-ui';
import { Error as ErrorIcon, EditIcon, WarningGrey } from 'assets/icons';
import BlackButton from 'components/BlackButton';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import DefaultSection from 'components/DefaultSection';
import Loading from 'components/Loading';
import MainContent from 'components/MainContent';
import UploadFileButton from 'components/UploadFileButton';
import ReleaseCard from '../ReleaseCard';
import ReaderImageCard from '../ReaderImageCard';
import FirmwareResources from './components/FirmwareResources';
import { calculatePaginationItens } from '../../services/PaginationService';

const Container = styled.div`
  display: flex;
  margin-top: 1.5em;
`;

const ContentContainer = styled.div`
  flex-grow: 2;
`;

const GoBackButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    max-width: 160px;
    margin: 0 0 ${theme.spacings.peta} 0;
  `};
`;

const StyledPagination = styled(Pagination)`
  justify-content: end;

  & button,
  & button:hover {
    color: black;
    background-color: transparent;
    border-color: transparent;
  }
  & button:focus {
    box-shadow: 0 0 0 4px #ddd;
  }
  & select:focus {
    box-shadow: 0 0 0 2px #ddd;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
`;

const ErrorSectionContainer = styled.div`
  width: 100%;
  margin: auto;

  & > div {
    max-width: 100%;
  }
  & div > svg {
    width: 66px;
    height: 68px;
  }
`;

const HorizontalContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2em;
`;

const SpaceBetweenHorizontalContainer = styled(HorizontalContainer)`
  justify-content: space-between;
`;

const SquareButton = styled(BlackButton)`
  ${({ isActive }) => css`
    border-radius: 0;
    border-color: #0f131a;
    cursor: ${isActive ? 'auto' : 'pointer'};
  `};
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:nth-child(n) {
    margin-left: 0.75em;
  }
`;

const Firmware = ({ t: translate, location, active }) => {
  const [loadingReleases, setLoadingReleases] = useState(false);
  const [showReleases, setShowReleases] = useState(true);
  const [releaseVersion, setReleaseVersion] = useState('');
  const [loadingError, setLoadingError] = useState(false);
  const [paginationContent, setPaginationContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadError, setDownloadError] = useState(false);

  const [uploadFiles, setUploadFiles] = useState([]);
  const [listReleases, setListReleases] = useState(true);

  const readerName = getOr(false, 'name', location.state);
  const readerPath = getOr(false, 'path', location.state);

  const getReleasesList = () => {
    setLoadingReleases(true);

    fetch(
      `/assets_management_api/devices/${readerPath}/specs/firmware/releases`
    )
      .then(response => (response.ok ? response.json() : setLoadingError(true)))
      .then(response =>
        calculatePaginationItens(response.data, setPaginationContent)
      )
      .catch(() => setLoadingError(true))
      .finally(() => setLoadingReleases(false));
  };

  const shouldShowPagination = paginationContent.length > 1;

  useEffect(() => {
    const controller = new AbortController();
    getReleasesList();

    return () => controller.abort();
  }, []);

  useEffect(() => setShowReleases(true), [active]);

  useEffect(() => {
    if (!readerName && !readerPath) {
      navigate(ROUTES_PATH.CARD_READERS_CONFIGURATION);
    }
  }, []);

  const releasesNavigation = () =>
    isEmpty(uploadFiles) && (
      <HorizontalContainer>
        <UploadFileButton
          action={items => {
            setUploadFiles([...items]);
            return setListReleases(false);
          }}
          buttonTestId="upload-files-button"
          buttonLabel="Upload new release"
          buttonWidth="245px"
          disabled
          isPrimary
          multipleFiles
          t={translate}
        />
      </HorizontalContainer>
    );

  const resourcesNavigation = () => (
    <SpaceBetweenHorizontalContainer>
      <div>
        <SquareButton isActive={!showReleases} isPrimary>
          Resources
        </SquareButton>
        <SquareButton disabled>Filters</SquareButton>
      </div>

      <ButtonsContainer>
        <BlackButton
          action={() => false}
          buttonTestId="delete-release-button"
          buttonWidth="225px"
          disabled
        >
          <WarningGrey style={{ marginRight: '.5em' }} /> Delete release
        </BlackButton>

        <BlackButton
          action={() => false}
          buttonTestId="unpublish-release-button"
          buttonWidth="225px"
          disabled
        >
          Unpublish release
        </BlackButton>

        <BlackButton
          action={() => false}
          buttonTestId="add-resource-button"
          buttonWidth="225px"
          disabled
          isPrimary
        >
          Add resource
        </BlackButton>

        <EditIcon
          style={{
            alignSelf: 'center',
            maxHeight: '48px',
            opacity: '.7'
          }}
        />
      </ButtonsContainer>
    </SpaceBetweenHorizontalContainer>
  );

  const toggleShowReleases = () => setShowReleases(!showReleases);

  const releases = paginationContent[currentPage];

  const shouldRenderReleases = () =>
    loadingError ? (
      <ErrorSectionContainer>
        <DefaultSection
          sectionImage={<ErrorIcon />}
          sectionTitle={translate('genericError.title')}
          sectionText={translate('genericError.text')}
          titleSize="one"
        />
      </ErrorSectionContainer>
    ) : (
      <>
        {releases &&
          releases.map((release, index) => (
            <ReleaseCard
              key={index}
              type="firmware"
              release={release}
              action={() => {
                setReleaseVersion(release.version);
                return toggleShowReleases();
              }}
            />
          ))}
      </>
    );

  return loadingReleases ? (
    <Loading />
  ) : (
    <>
      <MainContent>
        {showReleases ? releasesNavigation() : resourcesNavigation()}

        <Container>
          <ReaderImageCard location={location} />

          <ContentContainer>
            {listReleases && showReleases ? (
              shouldRenderReleases()
            ) : (
              <FirmwareResources
                t={translate}
                reader={readerPath}
                version={releaseVersion}
                unmountAction={() => toggleShowReleases()}
              />
            )}
          </ContentContainer>
        </Container>
      </MainContent>

      <NavigationContainer>
        {!isEmpty(uploadFiles) ? (
          <GoBackButton
            onClick={() => {
              setListReleases(true);
              setPaginationContent([]);
              setUploadFiles([]);
              return getReleasesList();
            }}
          >
            {translate('cardReadersConfiguration.goBack')}
          </GoBackButton>
        ) : (
          <GoBackButton
            onClick={() =>
              showReleases
                ? navigate(ROUTES_PATH.CARD_READERS_CONFIGURATION)
                : toggleShowReleases()
            }
          >
            {translate('cardReadersConfiguration.goBack')}
          </GoBackButton>
        )}

        {listReleases && showReleases && shouldShowPagination && (
          <StyledPagination
            data-testid="pagination"
            label="Pagination"
            previousLabel="Previous page"
            nextLabel="Next page"
            onChange={page => setCurrentPage(page)}
            currentPage={currentPage}
            totalPages={paginationContent.length - 1}
          />
        )}

        {downloadError && (
          <ErrorMessage
            t={translate}
            buttonLabel="Ok"
            action={() => setDownloadError(false)}
          />
        )}
      </NavigationContainer>
    </>
  );
};

Firmware.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  active: PropTypes.bool
};

Firmware.defaultProps = {
  active: true
};

export default Firmware;

import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const FooterContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 0 ${theme.spacings.mega};
    padding-bottom: ${theme.spacings.exa};
  `};
`;

const PageFooter = ({ ...props }) => <FooterContainer {...props} />;

export default PageFooter;

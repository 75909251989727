export const getReleaseLocales = (reader, version, setError, setLocales) => {
  fetch(
    `/assets_management_api/devices/${reader}/specs/text_file/releases/${version}/locales`
  )
    .then(response => (response.ok ? response.json() : setError(true)))
    .then(response => setLocales(response.locales))
    .catch(() => setError(true));
};

export const getResourceTypes = (reader, setError, setResourceTypes) => {
  fetch(
    `/assets_management_api/devices/${reader}/specs/text_file/resource_types`
  )
    .then(response => (response.ok ? response.json() : setError(true)))
    .then(response => setResourceTypes(response.types))
    .catch(() => setError(true));
};

export const updateRelease = (reader, version, data) => {
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return fetch(
    `/assets_management_api/devices/${reader}/specs/text_file/releases/${version}`,
    options
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NotificationInline } from '@sumup/circuit-ui';

const MessageContainer = styled.div`
  ${({ theme }) => css`
    position: fixed;
    bottom: ${theme.spacings.peta};
    display: flex;
    flex-direction: column;
    align-items: start;
    width: calc(100vw - (${theme.spacings.zetta} + ${theme.spacings.zetta}));
    max-width: 90%;
  `};
`;

const NotificationWide = styled(NotificationInline)`
  width: 100%;
`;

const SuccessfulMessage = ({
  message,
  aditionalText,
  buttonLabel,
  action,
  t: translate,
  ...props
}) => (
  <MessageContainer>
    <NotificationWide
      {...props}
      variant="confirm"
      action={{
        onClick: () => action(),
        children: buttonLabel || 'Ok'
      }}
      headline={{
        as: 'h3',
        label: message
      }}
      body={aditionalText && aditionalText}
    />
  </MessageContainer>
);

SuccessfulMessage.propTypes = {
  message: PropTypes.string.isRequired,
  aditionalText: PropTypes.string,
  buttonLabel: PropTypes.string,
  action: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default SuccessfulMessage;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { capitalize, isEmpty } from 'lodash';
import { AttentionBlack, Error, Success } from 'assets/icons';
import BlackButton from 'components/BlackButton';
import { updateRelease } from '../ReleasePage/ReleasePageService';

const BUTTONS_WIDTH = '180px';

const Container = styled.div`
  padding: 38px 0;

  & section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2.25em;
  }
`;

const AttentionIcon = styled(AttentionBlack)`
  width: 28px;
  height: 30px;
`;

const ResponseIconStyles = css`
  width: 48px;
  height: 48px;
`;

const StyledButton = styled(BlackButton)`
  margin: 1.5em 0;
`;

const ErrorIcon = styled(Error)(ResponseIconStyles);
const SuccessIcon = styled(Success)(ResponseIconStyles);

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.25em;
  margin: 0.5em 0 0.15em;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  & > button {
    margin: 0 0.25em;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5em 0 1em;
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5em;
  text-align: center;

  & p {
    margin: 3px 0;
    max-width: 80%;
    border: 1px solid hsl(0deg 100% 50% / 28%);
    border-radius: 4px;
    background: hsl(0deg 100% 50% / 20%);
    padding: 0.25em;
    font-size: 0.85em;
  }
`;

const TogglePublishRelease = ({
  isPublished,
  data,
  reader,
  close,
  goBack,
  translate,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [successfulRequest, setSuccessfulRequest] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleTogglePublish = () => {
    setLoading(true);
    const updatedData = { ...data };
    updatedData.published = !isPublished;

    updateRelease(reader, data.version, updatedData)
      .then(response => response.json())
      .then(response =>
        response.errors && response.errors.published
          ? setErrors(response.errors.published)
          : setSuccessfulRequest(true)
      )
      .catch(errors => errors.published && setErrors(errors.published))
      .finally(() => setLoading(false));
  };

  const renderErrorMessage = errors => (
    <MessageContainer data-testid="error-message">
      <ErrorIcon />
      <Title>Something went wrong</Title>

      <p>
        {errors
          ? `The request returned the following error${errors.length > 1 ? 's' : ''}: `
          : translate('genericError.text')
        }
      </p>

      <ErrorsContainer>
        {errors.map((errorMessage, key) => (
          <p key={key}>{capitalize(errorMessage)}</p>
        ))}
      </ErrorsContainer>

      <StyledButton
        onClick={close}
        data-testid="close-button"
        buttonWidth={BUTTONS_WIDTH}
      >
        Close
      </StyledButton>
    </MessageContainer>
  );

  const renderSuccessMessage = () => (
    <MessageContainer data-testid="successful-message">
      <SuccessIcon />

      <Title>Release successfully published!</Title>

      <StyledButton
        onClick={goBack}
        data-testid="ok-button"
        buttonWidth={BUTTONS_WIDTH}
      >
        Ok
      </StyledButton>
    </MessageContainer>
  );

  return !isEmpty(errors) ? (
    renderErrorMessage(errors)
  ) : (
    <>
      {successfulRequest ? (
        renderSuccessMessage()
      ) : (
        <Container {...props} close={close}>
          <section>
            <AttentionIcon />

            <Title>{isPublished ? 'Unpublish' : 'Publish'} release</Title>

            <p>Are you sure you want to {isPublished ? 'unpublish' : 'publish'} this release?</p>
          </section>

          <ButtonsContainer>
            <BlackButton
              onClick={close}
              data-testid="cancel-toggle-publish-button"
              buttonWidth={BUTTONS_WIDTH}
            >
              Cancel
            </BlackButton>

            <BlackButton
              onClick={() => handleTogglePublish()}
              data-testid="confirm-toggle-publish-button"
              buttonWidth={BUTTONS_WIDTH}
              loadingLabel="Loading..."
              isLoading={loading}
              isPrimary
            >
              Yes, {isPublished ? 'unpublish' : 'publish'}
            </BlackButton>
          </ButtonsContainer>
        </Container>
      )}
    </>
  );
};

TogglePublishRelease.propTypes = {
  isPublished: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  reader: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default TogglePublishRelease;

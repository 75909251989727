import styled from '@emotion/styled';
import { css } from '@emotion/react';

const CardsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin: ${theme.spacings.mega} 0 ${theme.spacings.tera} 0;
  `};
`;

export default CardsContainer;

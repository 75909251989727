import React from 'react';
import PropTypes from 'prop-types';
import { Table, Tooltip as CuiTooltip } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Tooltip from 'components/Tooltip';
import { getTimezone } from 'services/DateTimeFormatService';
import { formatRows } from './StatusHistoryTableService';

const StatusHistoryContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    border-bottom: 1px solid ${theme.colors.n300};
    width: 100%;
    color: ${theme.colors.n500};

    th,
    td {
      padding: ${theme.spacings.giga} ${theme.spacings.peta};
    }
    tbody tr:first-of-type {
      color: ${theme.colors.n900};
    }
  `};
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FIRST_ITEM = 0;

const StatusHistoryTable = ({ rows, t: translate }) => {
  const headers = [
    translate(`resultsInfo.statusHistory.headers.day`),
    `${translate(`resultsInfo.statusHistory.headers.time`)} (${getTimezone(
      rows[FIRST_ITEM].timestamp
    )})`,
    translate(`resultsInfo.statusHistory.headers.carrier`),
    {
      children: (
        <StatusContainer data-testid="statusHeader-connectionTable">
          {translate(`resultsInfo.statusHistory.headers.status`)}
          <Tooltip
            data-testid="statusTooltipHeader-connectionTable"
            align={CuiTooltip.LEFT}
            position={CuiTooltip.BOTTOM}
          >
            {translate(`resultsInfo.statusHistory.tootltips.status`)}
          </Tooltip>
        </StatusContainer>
      )
    }
  ];

  const formatedRows = formatRows(rows);
  return (
    <StatusHistoryContainer>
      <Table noShadow headers={headers} rows={formatedRows} />
    </StatusHistoryContainer>
  );
};

StatusHistoryTable.propTypes = {
  t: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired
};

export default StatusHistoryTable;

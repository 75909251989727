import { parse, unparse } from 'papaparse';
import { toLower } from 'lodash';
import { getCurrentDate } from 'services/DateTimeFormatService';

const fetchOptions = token => ({
  headers: {
    Authorization: token
  }
});

export const getCarrierSuggestionRules = token =>
  fetch(`/api/carrier-swap-rules`, fetchOptions(token))
    .then(response => (!response.ok ? response.status : response))
    .then(response => response.json())
    .then(result => result)
    .catch(err => err);

export const formatSpreadsheet = rows =>
  rows.map(
    row =>
      !!row && {
        UF: row.region_name,
        City: row.city,
        'Carrier 1': row.carrier_1,
        'Carrier 2': row.carrier_2,
        'Carrier 3': row.carrier_3
      }
  );

export const generateCSV = data => {
  const formatedData = formatSpreadsheet(data);

  const currentRulesCSV = `data:text/csv;charset=utf-8,${encodeURI(
    unparse(formatedData)
  )}`;

  const { dd, mm, yyyy } = getCurrentDate();
  const link = document.createElement('a');
  link.setAttribute('href', currentRulesCSV);
  const fileName = `carrier-suggestion-rules-${dd}-${mm}-${yyyy}.csv`;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);

  return {
    data: currentRulesCSV,
    download: link.click()
  };
};

export const parseInputFile = (file, loading, callback) => {
  loading(true);

  parse(file, {
    header: true,
    complete: results => {
      callback(results.data);
      loading(false);
    }
  });
};

export const validateFileStructure = (file, proceed, setInvalid) => {
  const expectedHeaders = [
    'region_name',
    'city',
    'claro_2g',
    'claro_3g',
    'claro_4g',
    'tim_2g',
    'tim_3g',
    'tim_4g',
    'vivo_2g',
    'vivo_3g',
    'vivo_4g'
  ];
  const headersIndex = 0;

  const inputHeaders = Object.keys(file[headersIndex]);

  const validHeaders = expectedHeaders.filter(item =>
    inputHeaders.includes(toLower(item))
  );

  return validHeaders.length === expectedHeaders.length
    ? proceed()
    : setInvalid(true);
};

export const isTruthy = value =>
  ['yes', 'sim', 'true'].includes(toLower(value));

export const formatDataToSend = data =>
  data.map(entry => ({
    region_name: entry.region_name,
    city: entry.city,
    country: 'BR',
    coverages: [
      {
        carrier: 'Claro',
        '2G': isTruthy(entry.claro_2g),
        '3G': isTruthy(entry.claro_3g),
        '4G': isTruthy(entry.claro_4g)
      },
      {
        carrier: 'TIM',
        '2G': isTruthy(entry.tim_2g),
        '3G': isTruthy(entry.tim_3g),
        '4G': isTruthy(entry.tim_4g)
      },
      {
        carrier: 'Vivo',
        '2G': isTruthy(entry.vivo_2g),
        '3G': isTruthy(entry.vivo_3g),
        '4G': isTruthy(entry.vivo_4g)
      }
    ]
  }));

export const requestUploadCoverage = async (data, token) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ data }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  };

  return fetch(`/api/carrier-coverages`, options);
};

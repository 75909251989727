import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { toLower } from 'lodash';
import { Redirect } from '@reach/router';
import { ROUTES_PATH } from 'constants/routes';
import { Card, Headline, Body } from '@sumup/circuit-ui';
import {
  SettingsEmpty,
  SettingsFull,
  Logout,
  SwitchLanguage
} from 'assets/icons';
import { logout } from 'services/AuthenticationService';
import LanguageSwitch from 'components/LanguageSwitch';
import Popover from 'components/Popover';

const Arrow = styled.div`
  ${({ theme }) => css`
    width: 0px;
    height: 0px;
    border-left: ${theme.spacings.bit} solid transparent;
    border-right: ${theme.spacings.bit} solid transparent;
  `};
`;

const ArrowUp = styled(Arrow)`
  ${({ theme }) => css`
    border-bottom: ${theme.spacings.bit} solid ${theme.colors.n500};
  `};
`;

const ArrowDown = styled(Arrow)`
  ${({ theme }) => css`
    border-top: ${theme.spacings.bit} solid ${theme.colors.n500};
  `};
`;

const IconsStyles = `
  ${({ theme }) => css`
    display: block;
    width: ${theme.spacings.giga};
    height: ${theme.spacings.giga};
  `};
`;

const ButtonResetStyles = `
  outline: none;
  border: 0;
  cursor: pointer;
  background: transparent;
`;

const LogoutButton = styled.button(ButtonResetStyles);

const SettingsButton = styled.button`
  ${({ theme }) => css`
    ${ButtonResetStyles}
    display: flex;
    align-items: center;

    & svg {
      margin-left: -${theme.spacings.byte};
    }
  `};
`;

const CardHeading = styled.div`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacings.kilo};
  `};
`;

const CardContent = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const ContentItem = styled.li`
  display: flex;
  align-items: center;
`;

const SettingsFullIcon = styled(SettingsFull)(IconsStyles);

const SettingsEmptyIcon = styled(SettingsEmpty)(IconsStyles);

const SwitchLanguageIcon = styled(SwitchLanguage)(IconsStyles);

const LogoutIcon = styled(Logout)(IconsStyles);

const LANGUAGES = ['en', 'pt-br'];

const BROWSER_LANGUAGE =
  toLower(localStorage.getItem('i18nextLng')) || LANGUAGES[0];

const SettingsComponent = ({ t: translate, ...props }) => {
  const [shouldLogout, setShouldLogout] = useState(false);
  const [hasOptionsOpen, setHasOptionsOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(BROWSER_LANGUAGE);

  const toggleOptions = () => setHasOptionsOpen(!hasOptionsOpen);

  const handleLogout = () => {
    logout(() => {
        setShouldLogout(!shouldLogout);
    });
  };

  const renderSettings = () => (
    <SettingsButton onClick={toggleOptions} data-testid="settings-button">
      {hasOptionsOpen ? (
        <Fragment>
          <SettingsFullIcon /> <ArrowUp />
        </Fragment>
      ) : (
        <Fragment>
          <SettingsEmptyIcon /> <ArrowDown />
        </Fragment>
      )}
    </SettingsButton>
  );

  const renderOptions = () => (
    <Card>
      <CardHeading>
        <Headline noMargin as="h4" size="four">
          {translate('pageSections.settings.popoverTitle')}
        </Headline>
      </CardHeading>
      <CardContent>
        <ContentItem>
          <SwitchLanguageIcon />
          <LanguageSwitch
            languages={LANGUAGES}
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
            callback={toggleOptions}
          />
        </ContentItem>
        <ContentItem>
          <LogoutIcon />
          <LogoutButton
            onClick={() => handleLogout()}
            data-testid="logout-button"
          >
            <Body noMargin>{translate('pageSections.settings.logout')}</Body>
          </LogoutButton>
        </ContentItem>
      </CardContent>
    </Card>
  );

  return shouldLogout ? (
    <Redirect to={ROUTES_PATH.LOGIN} noThrow />
  ) : (
    <Popover
      isOpen={hasOptionsOpen}
      position={Popover.BOTTOM}
      align={Popover.START}
      renderReference={renderSettings}
      renderPopover={renderOptions}
      onReferenceClickClose={() => setHasOptionsOpen(false)}
      onOutsideClickClose={() => setHasOptionsOpen(false)}
      {...props}
    />
  );
};

SettingsComponent.propTypes = {
  t: PropTypes.func.isRequired
};

export default SettingsComponent;

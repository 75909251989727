import React from 'react';
import PropTypes from 'prop-types';
import { getOr } from 'lodash/fp';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ImageCardReader } from 'assets/icons';
import { Card, Headline } from '@sumup/circuit-ui';

const StyledCard = styled(Card)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-width: 200px;
    width: 200px;
    height: 200px;
    margin: 0 ${theme.spacings.peta} ${theme.spacings.peta} 0;
    padding: ${theme.spacings.zetta} 2em ${theme.spacings.tera} 2em;
  `};
`;

const ReaderImage = styled.img`
  width: auto;
  height: auto;
  max-height: 180px;
  max-width: 145px;
`;

const ReaderImageCard = ({ location, ...props }) => {
  const readerImage = getOr(false, 'image', location.state);
  const readerName = getOr(false, 'name', location.state);

  return (
    <StyledCard {...props}>
      {readerImage ? (
        <ReaderImage
          data-testid="reader_image"
          src={readerImage}
          alt={readerName}
        />
      ) : (
        <ImageCardReader data-testid="reader_image" alt={readerName} />
      )}
      <Headline as="h3" size="four" noMargin>
        {readerName}
      </Headline>
    </StyledCard>
  );
};

ReaderImageCard.propTypes = {
  location: PropTypes.object.isRequired
};

export default ReaderImageCard;

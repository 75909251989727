import { unparse } from 'papaparse';
import { getCurrentDate } from 'services/DateTimeFormatService';

export const formatRLReportSpreadsheet = rows =>
  rows.map(
    row =>
      !!row && {
        'Serial Number': row.serial_number,
        'LR Date': row.reversed_at,
        ICCID: row.iccid,
        'Assembly At': row.assembled_at,
        'Reader Last Connection Date': row.last_connection_at,
        'SIM Line State': row.line_state,
        'SIM Supplier Name': row.supplier,
        Carrier: row.carrier,
        Status: row.status
      }
  );

export const generateRLReportCSV = data => {
  const reportInfo = formatRLReportSpreadsheet(data);
  const reportCSV = `data:text/csv;charset=utf-8,${encodeURI(
    unparse(reportInfo)
  )}`;
  const { dd, mm, yyyy } = getCurrentDate();
  const link = document.createElement('a');
  link.setAttribute('href', reportCSV);
  const fileName = `reversal_logistics_sim_cards_deactivation_report-${dd}-${mm}-${yyyy}.csv`;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);

  return {
    data: reportCSV,
    download: link.click()
  };
};

export const uploadReverseLogisticsFile = async (file, token) => {
  const formData = new FormData();
  formData.append('reversal_logistics_sheet', file[0]);

  const options = {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: token
    }
  };

  return fetch(`/api/sim-cards/rl-deactivations`, options);
};

export const tryAgainAction = (setErrorFalse, callback) => {
  setErrorFalse(false);
  return callback();
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import * as Sentry from '@sentry/browser';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { toLower } from 'lodash';
import { Body } from '@sumup/circuit-ui';

const LanguageItem = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    margin: ${theme.spacings.bit};
    outline: none;
    border: none;
    padding: 0;
    background: none;
  `};
`;

const LanguageSwitch = ({
  languages,
  currentLanguage,
  setCurrentLanguage,
  callback,
  t: translate
}) => {
  const switchLanguage = language => {
    // eslint-disable-next-line consistent-return
    i18n.changeLanguage(String(language).substring(0, 2), error => {
      if (error) {
        return Sentry.captureException(error);
      }
    });
    if (callback) {
      callback();
    }
    return setCurrentLanguage(language);
  };

  return (
    <Fragment>
      {languages
        .filter(
          item =>
            toLower(item.substring(0, 2)) !== currentLanguage.substring(0, 2)
        )
        .map((item, key) => (
          <LanguageItem key={key} onClick={() => switchLanguage(toLower(item))}>
            <Body noMargin size="one">
              {`${translate(
                'pageSections.settings.languageSwitchPrefix'
              )} ${String(item).toUpperCase()}`}
            </Body>
          </LanguageItem>
        ))}
    </Fragment>
  );
};

LanguageSwitch.propTypes = {
  languages: PropTypes.array.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  setCurrentLanguage: PropTypes.func.isRequired,
  callback: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default LanguageSwitch;

import React, { Fragment, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import UserData from 'contexts/UserData';
import ErrorMessage from 'components/ErrorMessage';
import PageHeader from 'components/PageHeader';
import PageContainer from 'components/PageContainer';
import UploadCard from './components/UploadCard';
import DownloadRLDeactivationReport from './components/DownloadRlDeactivationReport';
import {
  generateRLReportCSV,
  uploadReverseLogisticsFile,
  tryAgainAction
} from './ReverseLogisticsFileService';

const CardsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    padding: ${theme.spacings.giga} ${theme.spacings.exa} ${theme.spacings.peta}
      ${theme.spacings.exa};
  `};
`;

const ReverseLogisticsFileConverter = ({ t: translate }) => {
  const { token } = useContext(UserData);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [invalidFileError, setInvalidFileError] = useState(false);
  const [uploadingError, setUploadingError] = useState(false);
  const [uploadResult, setUploadResult] = useState(false);

  const handleUpload = file => {
    setUploadingFile(!uploadingFile);

    uploadReverseLogisticsFile(file, token)
      .then(response => {
        if (!response.ok) {
          return response.status === 422
            ? setInvalidFileError(true)
            : setUploadingError(true);
        }
        return response;
      })
      .then(response => !!response && response.json())
      .then(response => setUploadResult(response))
      .catch(() => setUploadingError(true))
      .finally(() => setUploadingFile(false));
  };

  return (
    <PageContainer>
      {uploadResult ? (
        <DownloadRLDeactivationReport
          responseData={uploadResult}
          goBackAction={setUploadResult}
          downloadAction={() => generateRLReportCSV(uploadResult.data)}
          t={translate}
        />
      ) : (
        <Fragment>
          <PageHeader
            noPadding
            titleKey={'simDeactivationReports.fileConverter.title'}
          />

          <CardsContainer>
            <UploadCard
              isLoading={uploadingFile}
              cardTitle={translate(
                'simDeactivationReports.fileConverter.card.title'
              )}
              cardSubtitle={translate(
                'simDeactivationReports.fileConverter.card.subtitle'
              )}
              action={handleUpload}
              t={translate}
            />
          </CardsContainer>

          {(uploadingError || invalidFileError) && (
            <ErrorMessage
              message={
                invalidFileError
                  ? translate(
                      'simDeactivationReports.fileConverter.invalidFileError.title'
                    )
                  : translate('simDeactivationReports.error.message')
              }
              aditionalText={
                invalidFileError &&
                translate(
                  'simDeactivationReports.fileConverter.invalidFileError.text'
                )
              }
              buttonLabel="Ok"
              action={() => tryAgainAction(setInvalidFileError, () => null)}
            />
          )}
        </Fragment>
      )}
    </PageContainer>
  );
};

ReverseLogisticsFileConverter.propTypes = {
  t: PropTypes.func.isRequired
};

export default ReverseLogisticsFileConverter;

const formatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
  hourCycle: 'h24',
  timeZoneName: 'short'
};

export const datetimeToGMTTimestamp = datetime =>
  datetime.match(/.*\d{2}$/gi)
    ? new Date(`${datetime}Z`).getTime()
    : new Date(datetime).getTime();

export const formatDateTimeToBrowserTimezone = timestamp =>
  new Intl.DateTimeFormat('default', formatOptions)
    .formatToParts(new Date(parseInt(timestamp, 10)))
    .reduce((accumulator, item) => {
      accumulator[item.type] = item.value;
      return accumulator;
    }, []);

export const getDate = timestamp => {
  const date = formatDateTimeToBrowserTimezone(timestamp);
  return `${date.day}/${date.month}/${date.year}`;
};

export const getTime = timestamp => {
  const time = formatDateTimeToBrowserTimezone(timestamp);
  return `${time.hour}:${time.minute}:${time.second}`;
};

export const getTimezone = timestamp =>
  formatDateTimeToBrowserTimezone(timestamp).timeZoneName;

export const formatDateTimeToShow = datetime => {
  const timestamp = datetimeToGMTTimestamp(datetime);
  return `${getDate(timestamp)} - ${getTime(timestamp)}`;
};

export const getCurrentDate = () => {
  const date = new Date();
  return {
    dd: date.getDate(),
    mm: date.getMonth() + 1,
    yyyy: date.getFullYear()
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline } from '@sumup/circuit-ui';
import SettingsComponent from 'components/SettingsComponent';

const PageHeaderContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacings.tera} ${theme.spacings.exa} ${theme.spacings.peta}
      0;
    width: 100%;
  `}
`;

const HeadingContainer = styled.div`
  display: flex;
`;

const PageHeader = ({ titleKey, children, t: translate, ...props }) => (
  <PageHeaderContainer {...props}>
    <HeadingContainer>
      <SettingsComponent />
      <Headline as="h3" noMargin size="three">
        {translate(`${titleKey}`)}
      </Headline>
    </HeadingContainer>

    {children}
  </PageHeaderContainer>
);

PageHeader.propTypes = {
  titleKey: PropTypes.string,
  children: PropTypes.node,
  t: PropTypes.func.isRequired
};

PageHeader.defaultProps = {
  titleKey: 'pageSections.pageInfo.pageTitle'
};

export default PageHeader;

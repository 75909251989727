export const formatDataToSend = data => {
  const result = data.map(entry => ({
    region_name: entry.uf,
    city: entry.city,
    carrier_1: entry.carrier1,
    carrier_2: entry.carrier2,
    carrier_3: entry.carrier3
  }));

  return { data: result };
};

export const requestUploadNewRules = (data, token) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(formatDataToSend(data)),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  };
  return fetch(`/api/carrier-swap-rules`, options);
};

export const flatMap = (arr, f) => arr.map(f).reduce((x, y) => x.concat(y), []);

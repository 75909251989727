import React from 'react';
import { get, getOr } from 'lodash/fp';
import { ImageCardReader } from 'assets/icons';
import ImageSumUpTotal from 'assets/imgs/sumup-total.png';
import ImageSumUpSuper from 'assets/imgs/sumup-super.png';
import ImageSumUpOn from 'assets/imgs/sumup-on.png';
import ImageSumUp3g from 'assets/imgs/sumup-3g.png';
import ImageSumUpSolo from 'assets/imgs/sumup-solo.png';

const CARD_READER_MAP = {
  TOTAL: {
    image: <img src={ImageSumUpTotal} alt="sumup-total" />,
    name: 'resultsInfo.model.values.total'
  },
  SUPER: {
    image: <img src={ImageSumUpSuper} alt="sumup-super" />,
    name: 'resultsInfo.model.values.super'
  },
  SUPER_3G: {
    image: <img src={ImageSumUpOn} alt="sumup-on" />,
    name: 'resultsInfo.model.values.on'
  },
  SUMUP_3G: {
    image: <img src={ImageSumUp3g} alt="sumup-3g" />,
    name: 'resultsInfo.model.values.3g'
  },
  SOLO: {
    image: <img src={ImageSumUpSolo} alt="sumup-solo" />,
    name: 'resultsInfo.model.values.solo'
  },
  DEFAULT: {
    image: <ImageCardReader />,
    name: 'resultsInfo.model.empty.label'
  }
};

export const mainImageSelector = searchedData =>
  searchedData.model && CARD_READER_MAP[searchedData.model]
    ? CARD_READER_MAP[searchedData.model].image
    : CARD_READER_MAP.DEFAULT.image;

export const getCardReaderName = cardReaderModel =>
  CARD_READER_MAP[cardReaderModel]
    ? CARD_READER_MAP[cardReaderModel].name
    : CARD_READER_MAP.DEFAULT.name;

export const getCardReaderBySerialNumber = (serialNumber, email, filter) =>
  fetch(`/api/card-readers/${serialNumber}?email=${email}&filter=${filter}`);

export const getCardReader = (serialNumber, email, filter) =>
  getCardReaderBySerialNumber(serialNumber, email, filter)
    .then(res => (res.status === 200 ? res.json() : res))
    .then(data => {
      const hasLineSupplier =
        data.sim_card && data.sim_card.line && data.sim_card.line.supplier;
      const reader = {
        serialNumber: data.serial_number,
        applicationType: data.application_type,
        applicationVersion: data.application_version,
        lastReactivationRequest: {
          email: getOr(null, 'last_reactivation_request.email', data),
          datetime: getOr(null, 'last_reactivation_request.datetime', data)
        },
        lastBlockRequest: {
          email: getOr(null, 'last_block_request.email', data),
          datetime: getOr(null, 'last_block_request.datetime', data)
        },
        lastUnblockRequest: {
          email: getOr(null, 'last_unblock_request.email', data),
          datetime: getOr(null, 'last_unblock_request.datetime', data)
        },
        model: data.model,
        imei: data.imei,
        lastConnection: data.last_connection,
        lastTransaction: {
          chipDate: getOr(null, 'last_transaction.chip_date', data),
          wifiDate: getOr(null, 'last_transaction.wifi_date', data)
        },
        simCard: {
          iccid: getOr(null, 'sim_card.iccid', data),
          line: {
            msisdn: getOr(null, 'sim_card.line.msisdn', data),
            imsi: getOr(null, 'sim_card.line.imsi', data),
            carrier: {
              name: getOr(null, 'sim_card.line.carrier', data)
            },
            supplier: {
              name: getOr(
                hasLineSupplier ? data.sim_card.line.supplier : null,
                'sim_card.supplier',
                data
              )
            },
            lineSupplier: {
              name: getOr(null, 'sim_card.line.supplier', data)
            }
          }
        }
      };

      return reader;
    })
    .catch(error => error);

export const getSessions = (serialNumber, token) =>
  fetch(`/api/card-readers/${serialNumber}/sessions`, {
    headers: {
      Authorization: token
    }
  });

export const cardReaderToSearchResult = cardReader => {
  const {
    model,
    serialNumber,
    applicationType,
    applicationVersion
  } = cardReader;
  const simCard = get('simCard', cardReader);
  const lastConnection = get('lastConnection', cardReader);
  const lastTransaction = get('lastTransaction', cardReader);
  const iccid = get('simCard.iccid', cardReader);
  const carrier = get('simCard.line.carrier.name', cardReader);
  const lineSupplier = get('simCard.line.lineSupplier.name', cardReader);
  const supplier = get('simCard.line.supplier.name', cardReader);
  const imsi = get('simCard.line.imsi', cardReader);
  const lastReactivationRequest = get('lastReactivationRequest', cardReader);

  return {
    serialNumber,
    model,
    applicationType,
    applicationVersion,
    lastReactivationRequest,
    lastConnection,
    lastTransaction,
    iccid,
    carrier,
    lineSupplier,
    supplier,
    simCard,
    imsi
  };
};

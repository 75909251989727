import { parse } from 'papaparse';
import { toLower } from 'lodash';

export const parseInputFile = (file, loading, callback) => {
  loading(true);

  parse(file[0], {
    header: true,
    complete: results => {
      loading(false);
      return callback(results.data);
    }
  });
};

export const uploadSimCards = (data, token) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ data }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  };

  return fetch(`/api/sim-cards`, options);
};

export const fileExpectedFields = [
  'iccid',
  'msisdn',
  'status',
  'supplier',
  'carrier'
];

export const validateFileStructure = file => {
  const inputHeaders = Object.keys(file[0]);
  const validHeaders = fileExpectedFields.filter(item =>
    inputHeaders.includes(toLower(item))
  );

  return validHeaders.length === fileExpectedFields.length ? file : false;
};

export const formatDataToSend = data =>
  data.map(entry => ({
    file_row: data.indexOf(entry) + 1,
    iccid: entry.iccid,
    imsi: entry.imsi,
    msisdn: entry.msisdn,
    status: entry.status,
    supplier: entry.supplier,
    carrier: entry.carrier
  }));

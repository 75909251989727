import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { isEmpty } from 'lodash/fp';
import { Link } from '@reach/router';
import { Button, Card, Headline } from '@sumup/circuit-ui';
import { ImageCardReader, Error as ErrorIcon } from 'assets/icons';
import UserData from 'contexts/UserData';
import CardsContainer from 'components/CardsContainer';
import DefaultSection from 'components/DefaultSection';
import Loading from 'components/Loading';
import { getCardReaders, CARD_READERS_MAP } from '../../CardReadersListService';

const StyledCard = styled(Card)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 255px;
    max-width: 100%;
    height: 255px;
    max-height: 100%;
    margin: 0 ${theme.spacings.peta} ${theme.spacings.peta} 0;
    padding: ${theme.spacings.zetta} 0 ${theme.spacings.tera} 0;
    transition: 0.35s ease-out 100ms;
    > h3 {
      transition: 0.35s ease-out 100ms;
    }
    &:hover {
      box-shadow: 0px 6px 10px 1px rgb(204 204 204 / 35%);
      > h3 {
        opacity: 0.7;
      }
    }
  `};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  width: 232px;
  max-width: 400px;
  margin-right: 2em;
  margin-bottom: 2em;
  height: 232px;
`;

const ReaderImage = styled.img`
  width: auto;
  height: 100%;
  max-height: 180px;
  max-width: 145px;
`;

const CardContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    text-align: center;
    margin-top: ${theme.spacings.mega};
  `};
`;

const ErrorSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div {
    margin: 0 auto;
  }
  & div > svg {
    width: 66px;
    height: 68px;
  }
`;

const TryAgainButton = styled(Button)`
  max-width: 180px;
  margin: 2em auto;
`;

const ReadersList = ({ t: translate, ...props }) => {
  const { token } = useContext(UserData);
  const [loadingReaders, setLoadingReaders] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [cardReaders, setCardReaders] = useState([]);

  const getReaders = () => {
    setLoadingReaders(true);

    getCardReaders()
      .then(response => (response.ok ? response.json() : setLoadingError(true)))
      .then(response =>
        setCardReaders(
          response.filter(reader => {
            return !isEmpty(CARD_READERS_MAP[reader.permalink]);
          })
        )
      )
      .catch(() => setLoadingError(true))
      .finally(() => setLoadingReaders(false));
  };

  const renderReaders = () => {
    return loadingReaders ? (
      <Loading />
    ) : (
      !isEmpty(cardReaders) &&
        cardReaders.map((reader, key) =>
          CARD_READERS_MAP[reader.permalink] ? (
            <StyledLink
              to={`/readers-configuration/${
                CARD_READERS_MAP[reader.permalink].url
              }`}
              key={key}
              state={{
                image: CARD_READERS_MAP[reader.permalink].image,
                name: `${CARD_READERS_MAP[reader.permalink].name}`,
                path: reader.permalink
              }}
            >
              <StyledCard>
                <ReaderImage
                  src={CARD_READERS_MAP[reader.permalink].image}
                  alt={CARD_READERS_MAP[reader.permalink].name}
                />
                <Headline as="h3" size="four" noMargin>
                  {CARD_READERS_MAP[reader.permalink].name}
                </Headline>
                <CardContent></CardContent>
              </StyledCard>
            </StyledLink>
          ) : (
            <StyledLink
              to={`/readers-configuration/${reader.permalink}`}
              key={key}
              state={{
                image: '',
                name: reader.name,
                path: reader.permalink
              }}
            >
              <StyledCard>
                <ImageCardReader alt={reader.name} />
                <Headline as="h3" size="four" noMargin>
                  {reader.name}
                </Headline>
                <CardContent></CardContent>
              </StyledCard>
            </StyledLink>
          )
        )
    );
  };

  useEffect(() => {
    const controller = new AbortController();
    getReaders();

    return () => controller.abort();
  }, [token]);

  return (
    <CardsContainer {...props}>
      {loadingError ? (
        <ErrorSectionContainer>
          <DefaultSection
            sectionImage={<ErrorIcon />}
            sectionTitle={translate('genericError.title')}
            sectionText={translate('genericError.text')}
            titleSize="one"
          />
          <TryAgainButton action={() => getReaders()}>
            {translate('genericError.tryAgainLabel')}
          </TryAgainButton>
        </ErrorSectionContainer>
      ) : (
        renderReaders()
      )}
    </CardsContainer>
  );
};

ReadersList.propTypes = {
  t: PropTypes.func.isRequired
};

export default ReadersList;

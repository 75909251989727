export const getResets = (iccid, token) =>
  fetch(`/api/sim-cards/${iccid}/resets`, {
    headers: {
      Authorization: token
    }
  });

export const requestReset = async (iccid, reset, token) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ reset }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  };

  return fetch(`/api/sim-cards/${iccid}/resets`, options);
};

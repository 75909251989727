import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Table } from '@sumup/circuit-ui';

const EMPTY_CELL_LABEL = '-';

const TableContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;

    th,
    td {
      padding: ${theme.spacings.giga};
      vertical-align: middle;
    }
    td {
      color: ${theme.colors.n700};
      white-space: normal;
    }
  `};
`;

const FileEntriesTable = ({ entriesRows, areValidEntries, props }) => {
  const tableHeaders = {
    validEntries: [
      { children: 'City' },
      { children: 'UF' },
      { children: 'Carrier 1' },
      { children: 'Carrier 2' },
      { children: 'Carrier 3' }
    ],
    errors: [{ children: 'Line' }, { children: 'Issue' }]
  };

  const formatUF = uf => uf && uf.substr(uf.length - 2, uf.length - 1);

  const toEntryRow = emptyCellLabel => row => [
    { children: row.city },
    { children: formatUF(row.uf) },
    { children: row.carrier1 },
    { children: row.carrier2 || emptyCellLabel },
    { children: row.carrier3 || emptyCellLabel }
  ];

  const toErrorRow = row => [{ children: row.line }, { children: row.errors }];

  return (
    <TableContainer>
      <Table
        {...props}
        noShadow
        headers={
          areValidEntries ? tableHeaders.validEntries : tableHeaders.errors
        }
        rows={
          areValidEntries
            ? entriesRows.map(toEntryRow(EMPTY_CELL_LABEL))
            : entriesRows.map(toErrorRow)
        }
      />
    </TableContainer>
  );
};

FileEntriesTable.propTypes = {
  entriesRows: PropTypes.array.isRequired,
  areValidEntries: PropTypes.bool
};

FileEntriesTable.defaultProps = {
  areValidEntries: true
};

export default FileEntriesTable;

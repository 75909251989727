import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { isEmpty } from 'lodash';
import { Button, Table } from '@sumup/circuit-ui';
import { MissingFeature } from 'assets/icons';
import UserData from 'contexts/UserData';
import CopyButton from 'components/CopyButton';
import Loading from 'components/Loading';
import DefaultSection from 'components/DefaultSection';
import PageHeader from 'components/PageHeader';
import PageContainer from 'components/PageContainer';
import {
  getReactivationRequests,
  tableHeaders,
  toEntryRow,
  formatDataToCopy
} from './SimReactivationRequestsService';

const MainContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 ${theme.spacings.exa} ${theme.spacings.peta}
      ${theme.spacings.exa};
  `};
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PageHeading = styled(PageHeader)`
  width: auto;
`;

const CopyICCIDsButton = styled(CopyButton)`
  ${({ theme }) => css`
    background: #000;
    max-height: ${theme.spacings.peta};
    margin-right: ${theme.spacings.exa};
    padding: 0.5em 1em;
    color: #fff;
    transition: 0.35s ease-out 100ms;

    &:hover {
      opacity: 0.8;
      transition: 0.35s ease-out 100ms;
    }

    span {
      margin-left: ${theme.spacings.bit};
    }
    svg > * {
      stroke: #ffffff;
    }
  `};
`;

const TableContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;

    th,
    td {
      padding: ${theme.spacings.giga};
      vertical-align: middle;
    }
    td {
      color: ${theme.colors.n700};
      white-space: normal;
    }
  `};
`;

const DefaultSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
  text-align: center;
`;

const StyledTable = styled(Table)`
  th:nth-of-type(2),
  td:nth-of-type(2) {
    text-align: right;
  }
`;

const SimReactivationRequests = ({ t: translate }) => {
  const { token } = useContext(UserData);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [reactivationRequests, setReactivationRequests] = useState([]);

  const getRequests = () => {
    setLoading(true);

    getReactivationRequests('Arqia', 'Cancelled', token)
      .then(response => (response.ok ? response.json() : setLoadingError(true)))
      .then(data => setReactivationRequests(data))
      .catch(() => setLoadingError(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const controller = new AbortController();
    getRequests();

    return () => controller.abort();
  }, []);

  const renderTryAgainButton = () => (
    <Button
      size="kilo"
      style={{ marginTop: '1em' }}
      onClick={() => {
        setLoadingError(false);
        return getRequests();
      }}
    >
      {translate('simReactivationRequest.tryAgainLabel')}
    </Button>
  );

  return (
    <PageContainer>
      <HeaderContainer>
        <PageHeading
          noPadding
          titleKey={translate('simReactivationRequest.pageTitle')}
        />

        <CopyICCIDsButton
          data={formatDataToCopy(reactivationRequests)}
          label={translate('simReactivationRequest.buttonLabel')}
          disabled={isEmpty(reactivationRequests)}
          variant="highlight"
          size="mega"
          id="copy-button"
        />
      </HeaderContainer>

      <MainContainer>
        {loading ? (
          <Loading />
        ) : loadingError ? (
          <DefaultSectionContainer>
            <DefaultSection
              dataTestid="loading-error"
              sectionImage={<MissingFeature />}
              sectionTitle={translate(
                'simReactivationRequest.loadingError.title'
              )}
              sectionText={translate(
                'simReactivationRequest.loadingError.text'
              )}
            />

            {renderTryAgainButton()}
          </DefaultSectionContainer>
        ) : isEmpty(reactivationRequests) ? (
          <DefaultSection
            sectionMaxWidth="380px"
            sectionImage={<MissingFeature />}
            sectionTitle={translate(
              'simReactivationRequest.noRequestsFound.title'
            )}
            headingMaxWidth="400px"
          />
        ) : (
          <TableContainer>
            <StyledTable
              noShadow
              headers={tableHeaders.headers}
              rows={reactivationRequests.map(item =>
                toEntryRow(item.iccid, item.datetime)
              )}
            />
          </TableContainer>
        )}
      </MainContainer>
    </PageContainer>
  );
};

SimReactivationRequests.propTypes = {
  t: PropTypes.func.isRequired
};

SimReactivationRequests.defaultProps = {};

export default SimReactivationRequests;

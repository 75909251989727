import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Sidebar, Body } from '@sumup/circuit-ui';
import UserData from 'contexts/UserData';
import { SIDEBAR_WIDTH, SumupLogo, navigationItems } from './SidebarService';

const StyledSidebar = styled(Sidebar)`
  position: fixed;
  height: 100vh;
  min-height: 100vh;
  width: ${SIDEBAR_WIDTH};
  max-width: 100%;

  @media (min-width: 960px) {
    position: fixed;
  }
`;

const UserHeader = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.byte};
    width: 100%;
    display: flex;
  `};
`;

const UserAvatarIcon = styled.div`
  ${({ theme, imageUrl }) => css`
    margin-right: ${theme.spacings.mega};
    border-radius: 100%;
    width: ${theme.iconSizes.giga};
    height: ${theme.iconSizes.giga};
    background-image: url(${imageUrl});
    background-size: contain;
  `};
`;

const BlockText = styled(Body)`
  display: block;
`;

const SidebarComponent = ({ selectedPage, ...props }) => {
  const { name: userName, imageUrl: userImage, roles: userRoles } = useContext(
    UserData
  );

  return (
    <StyledSidebar {...props} closeButtonLabel="close">
      <Sidebar.Header>
        <UserHeader>
          <UserAvatarIcon imageUrl={userImage} />

          <BlockText noMargin size="one">
            {userName}
          </BlockText>
        </UserHeader>
      </Sidebar.Header>

      <Sidebar.NavList>
        {navigationItems.map(
          item =>
            [...userRoles, 'default'].includes(item.role) && (
              <Sidebar.NavItem
                key={item.key}
                id={item.key}
                label={item.label}
                selected={selectedPage === item.key}
                onClick={() => item.onClick()}
                defaultIcon={item.defaultIcon}
                selectedIcon={item.selectedIcon}
              />
            )
        )}
      </Sidebar.NavList>

      <Sidebar.Footer>
        <SumupLogo />
      </Sidebar.Footer>
    </StyledSidebar>
  );
};

SidebarComponent.propTypes = {
  selectedPage: PropTypes.string
};

SidebarComponent.defaultProps = {
  selectedPage: 'reader-connectivity'
};

export default SidebarComponent;
